import {ReactComponent as LetrusLogo} from 'assets/icons/letrusLogo.svg';
import React from 'react';
import styles from './ScreenLoader.module.scss';

function ScreenLoader(): JSX.Element {
  return (
    <div className={styles.screenLoader} data-testid="screen-loader">
      <LetrusLogo title="Letrus" />

      <svg viewBox="0 0 428.5036 130.8048">
        <g transform="matrix(1.33333 0 0 -1.33333 -183.456 941.453)">
          {/* Bottom bars */}
          <g className={styles.loadingBars}>
            <path
              d="m 177.782,607.986 h -20 v 8 h 20 z"
              id="bar2"
              fill="#fecb2e"
            />
            <path
              d="m 157.592,607.986 h -20 v 8 h 20 z"
              id="bar3"
              fill="#000"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default ScreenLoader;
