import {Utils} from '@letrustech/letrus-api-interfaces';
import {format} from 'date-fns';
import {fromJS, List, Map} from 'immutable';
import {RoundedButton} from 'letrus-ui';
import React from 'react';
import {Link} from 'react-router-dom';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createContractService,
  fetchContractByIdService,
  FetchContractsParams,
  fetchContractsService,
  generateDocumentService,
  updateContractService,
} from 'store/services/contractsService';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';

export interface ContractClause {
  id: number;
  contract: number;
  total_value?: number;
  school_grade: number;
  uses_ai_comp: boolean;
  annual_value?: number;
  student_number: number;
  monthly_value?: number;
  has_pre_review: boolean;
  school_grade_name: string;
  uses_learning_path: boolean;
  compositions_number: number;
  school_groups_number: number;
  uses_write_preparation: boolean;
  compositions_number_ai_review: number;
  compositions_number_elementary: number;
  compositions_number_high_school: number;
  ai_review_type: 'AI-Competences' | 'AI-Structure';
}

export interface ContractContact {
  id: number;
  name: string;
  email: string;
  signed?: string;
  contract: number;
  phone_number?: string;
  clicksign_key?: string;
  is_responsible?: boolean;
  is_financial_responsible?: boolean;
}

export interface ContractInstallment {
  id: number;
  order: number;
  value: number;
  contract: number;
  expire_date: string;
}

export type ContractType =
  | 'B2B'
  | 'B2C'
  | 'BBG'
  | 'PLG Externo'
  | 'PLG Comercial'
  | 'Híbrido'
  | 'Filantrópico'
  | 'Piloto PUB'
  | 'Piloto PVT'
  | 'Subsidiado'
  | 'Avaliação de Impacto - AI'
  | 'Avaliação de Impacto - HI'
  | 'MVP PLG - Fase 1';

export type ServiceLevels =
  | 'Tech Touch'
  | 'Low Touch'
  | 'High Touch'
  | 'Temporario'
  | 'ProA';

export type ContractOrigin =
  | 'Renovacao'
  | 'Nova_Escola_PVT'
  | 'Nova_Escola_PUB'
  | 'Upsell'
  | 'Cross_Sell';

export type ContractStatus =
  | 'signed'
  | 'number_students_sent'
  | 'verbal_confirmation';

export interface ContractsData {
  id: number;
  link?: string;
  school: number;
  user?: number;
  started: string;
  deal_id: number;
  finished: string;
  installments?: number;
  full_value?: number;
  school_name: string;
  observation?: string;
  contract_year: number;
  user_username: number;
  is_active: boolean;
  compositions_number: number;
  clicksign_key: string;
  signature_day?: string;
  status?: ContractStatus;
  origin?: ContractOrigin;
  school_network?: number;
  user_comercial?: number;
  student_number?: number;
  contract_type: ContractType;
  school_group_number?: number;
  service_level?: ServiceLevels;
  contract_renewal_day?: string;
  finish_date_renewal_year?: string;
  contract_clauses: ContractClause[];
  contract_contacts: ContractContact[];
  contract_installments: ContractInstallment[];
}

export interface CreateContractsData {
  contract_year: string | null;
  full_value: string | null;
  started: string;
  finished: string | null;
  contract_renewal_day: string | null;
  contract_type: string | null;
  service_level: string | null;
  origin: string | null;
  deal_id: string | null;
  school: string | null;
  user: string | null;
  user_comercial: string | null;
  finish_date_renewal_year: string | null;
  student_number: string | null;
  school_group_number: string | null;
}

// Actions types
export enum ContractsTypes {
  FETCH_REQUEST = '@contracts/FETCH_REQUEST',
  FETCH_SUCCESS = '@contracts/FETCH_SUCCESS',
  FETCH_FAILURE = '@contracts/FETCH_FAILURE',

  FETCH_BY_ID_REQUEST = '@contracts/FETCH_BY_ID_REQUEST',
  FETCH_BY_ID_SUCCESS = '@contracts/FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILURE = '@contracts/FETCH_BY_ID_FAILURE',

  UPDATE_REQUEST = '@contracts/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@contracts/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@contracts/UPDATE_FAILURE',

  CREATE_REQUEST = '@contracts/CREATE_REQUEST',
  CREATE_SUCCESS = '@contracts/CREATE_SUCCESS',
  CREATE_FAILURE = '@contracts/CREATE_FAILURE',

  GENERATE_DOCUMENT_REQUEST = '@contracts/GENERATE_DOCUMENT_REQUEST',
  GENERATE_DOCUMENT_SUCCESS = '@contracts/GENERATE_DOCUMENT_SUCCESS',
  GENERATE_DOCUMENT_FAILURE = '@contracts/GENERATE_DOCUMENT_FAILURE',
  RESET_GENERATE_DOCUMENT = '@contracts/RESET_GENERATE_DOCUMENT',
}

// State type
export interface ContractsState extends Map<any, any> {
  readonly data: List<ImmutableMap<ContractsData>>;
  readonly loading: boolean;
  readonly isGeneratingDocument: boolean;
  readonly error: boolean;
  readonly updateContractRequestStatus: StoreStatus;
  readonly generateDocumentRequestStatus: StoreStatus;
}

export interface ContractChangebleData {
  contract_year: number;
  deal_id: number;
  id: number;
  school: number;
  service_level: string;
  started: string;
  user: number;
}

export type FetchContractsType = (params?: Utils.GetParams) => AnyAction;

// Fetch actions
export const fetchContractsRequest = (params?: FetchContractsParams) =>
  action(ContractsTypes.FETCH_REQUEST, params);

export const fetchContractsSuccess = (
  data: List<ImmutableMap<ContractsData>>,
) => action(ContractsTypes.FETCH_SUCCESS, {data});

export const fetchContractsFailure = () => action(ContractsTypes.FETCH_FAILURE);

export type FetchContractByIdRequestType = (id: number | string) => AnyAction;

// Fetch by ID actions

export const fetchContractByIdRequest: FetchContractByIdRequestType = (
  id: any,
) => action(ContractsTypes.FETCH_BY_ID_REQUEST, id);

export const fetchContractByIdSuccess = (data: List<ImmutableMap<any>>) =>
  action(ContractsTypes.FETCH_BY_ID_SUCCESS, {data});

export const fetchContractByIdFailure = () =>
  action(ContractsTypes.FETCH_BY_ID_FAILURE);

// Update actions

export const updateContractRequest = (data: ContractsData) => {
  return action(ContractsTypes.UPDATE_REQUEST, data);
};

export const updateContractSuccess = (data: List<ImmutableMap<any>>) =>
  action(ContractsTypes.UPDATE_SUCCESS, {data});

export const updateContractFailure = () =>
  action(ContractsTypes.UPDATE_FAILURE);

// Create actions
export const createContractRequest = (data: CreateContractsData) =>
  action(ContractsTypes.CREATE_REQUEST, data);

export const createContractSuccess = (data: CreateContractsData) =>
  action(ContractsTypes.CREATE_SUCCESS, data);

export const createContractFailure = () =>
  action(ContractsTypes.CREATE_FAILURE);

// Generate document actions
export const generateDocumentRequest = (id: number) =>
  action(ContractsTypes.GENERATE_DOCUMENT_REQUEST, id);

export const generateDocumentSuccess = () =>
  action(ContractsTypes.GENERATE_DOCUMENT_SUCCESS);

export const generateDocumentFailure = () =>
  action(ContractsTypes.GENERATE_DOCUMENT_FAILURE);

export const resetGenerateDocumentStatus = () =>
  action(ContractsTypes.RESET_GENERATE_DOCUMENT);

// Sagas
export function* fetchContracts(action: AnyAction): any {
  try {
    const response = yield call(fetchContractsService, action.payload);
    yield put(fetchContractsSuccess(response.data));
  } catch (err) {
    yield put(fetchContractsFailure());
  }
}

export function* fetchContractById(action: AnyAction): any {
  try {
    const response = yield call(fetchContractByIdService, action.payload);
    yield put(fetchContractByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchContractByIdFailure());
  }
}

export function* updateContract(action: AnyAction): any {
  try {
    const response = yield call(updateContractService, action.payload);
    yield put(updateContractSuccess(response.data));
  } catch (err) {
    yield put(updateContractFailure());
  }
}

export function* createContract(action: AnyAction): any {
  try {
    const response = yield call(createContractService, action.payload);
    yield put(createContractSuccess(response.data));
  } catch (err) {
    yield put(createContractFailure());
  }
}

export function* generateDocument(action: AnyAction) {
  try {
    yield call(generateDocumentService, action.payload);
    yield put(generateDocumentSuccess());
  } catch (err) {
    yield put(generateDocumentFailure());
  }
}

// Selectors
export const getContractsData = (state: ApplicationState) =>
  state.getIn(['contracts', 'data']);

export const contractsSelector = (state: ApplicationState) =>
  state.get('contracts');

export const isGeneratingDocument = createSelector(
  contractsSelector,
  (contracts) => contracts.get('isGeneratingDocument'),
);

export const getContractById = createSelector(contractsSelector, (contracts) =>
  contracts.get('contractById'),
);

export const getContractsDataTableSelector = createSelector(
  getContractsData,
  (contracts: List<ImmutableMap<ContractsData>>) => {
    const disableContract = async (contract: any) => {
      await updateContractService({
        ...contract.set('finished', format(new Date(), 'yyyy-MM-dd')).toJS(),
      });
      window.location.reload();
    };
    return contracts
      ? contracts
          .map((contract: ImmutableMap<ContractsData>) => {
            const isActive = contract.get('is_active');
            const studentAmount = contract
              .get('contract_clauses')
              .reduce((prevValue: number, clause: any): number => {
                return Number(clause.get('student_number')) + prevValue;
              }, 0);

            const compositionAmount = contract
              .get('contract_clauses')
              .reduce((prevValue: number, clause: any): number => {
                return Number(clause.get('compositions_number')) + prevValue;
              }, 0);

            return fromJS({
              school_id:
                process.env.NODE_ENV === 'test' ? (
                  contract.get('id')
                ) : (
                  <Link to={`/contratos/${contract.get('id')}/editar`}>
                    {contract.get('id')}
                  </Link>
                ),
              school_name: contract.get('school_name'),
              user_username: contract.get('user_username'),
              contract_year: contract.get('contract_year'),
              started:
                format(new Date(contract.get('started')), 'dd/MM/yyyy') ?? '-',
              finished:
                format(new Date(contract.get('finished')), 'dd/MM/yyyy') ?? '-',

              is_active: isActive ? 'SIM' : 'NÃO',
              contract_type: contract.get('contract_type'),
              student_number: studentAmount,
              compositions_number: compositionAmount,
              actions: isActive && (
                <RoundedButton
                  size="small"
                  text="Desativar"
                  icon={{icon: 'trash-alt'}}
                  kind="secondary"
                  onClick={() => disableContract(contract)}
                />
              ),
            });
          })
          .toJS()
      : fromJS([]);
  },
);

export const getContractsCount = (state: ApplicationState) =>
  state.getIn(['contracts', 'dataCount']);

export const getIsLoadingContracts = (state: ApplicationState) =>
  state.getIn(['contracts', 'loading']);

export const getUpdateContractRequestStatus = (state: ApplicationState) =>
  state.getIn(['contracts', 'updateContractRequestStatus']);

export const getGenerateDocumentRequestStatus = (state: ApplicationState) =>
  state.getIn(['contracts', 'generateDocumentRequestStatus']);

export const getContractsError = (state: ApplicationState) =>
  state.getIn(['contracts', 'error']);

// Initial reducer state
export const INITIAL_STATE: ContractsState = fromJS({
  data: fromJS([]),
  contractById: fromJS({}),
  error: false,
  loading: false,
  updateContractRequestStatus: {
    posting: false,
    error: false,
    loading: false,
    fulfilled: false,
  },
  generateDocumentRequestStatus: {
    posting: false,
    error: false,
    loading: false,
    fulfilled: false,
  },
  isGeneratingDocument: false,
});

// Reducer
const reducer: Reducer<ContractsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContractsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ContractsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case ContractsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    case ContractsTypes.FETCH_BY_ID_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ContractsTypes.FETCH_BY_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('contractById', fromJS(action.payload.data)),
      );

    case ContractsTypes.FETCH_BY_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('contractById', fromJS({})),
      );

    case ContractsTypes.CREATE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ContractsTypes.CREATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case ContractsTypes.CREATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ContractsTypes.GENERATE_DOCUMENT_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isGeneratingDocument', true)
          .set('generateDocumentRequestStatus', {
            posting: true,
            error: false,
            loading: false,
            fulfilled: false,
          }),
      );

    case ContractsTypes.GENERATE_DOCUMENT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('error', false)
          .set('loading', false)
          .set('generateDocumentRequestStatus', {
            posting: false,
            error: false,
            loading: false,
            fulfilled: true,
          }),
      );

    case ContractsTypes.GENERATE_DOCUMENT_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isGeneratingDocument', false)
          .set('generateDocumentRequestStatus', {
            posting: false,
            error: true,
            loading: false,
            fulfilled: false,
          }),
      );

    case ContractsTypes.RESET_GENERATE_DOCUMENT:
      return state.withMutations((prevState) =>
        prevState.set('generateDocumentRequestStatus', {
          posting: false,
          error: false,
          loading: false,
          fulfilled: false,
        }),
      );

    case ContractsTypes.UPDATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('updateContractRequestStatus', {
          posting: true,
          error: false,
          loading: false,
          fulfilled: false,
        }),
      );

    case ContractsTypes.UPDATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('updateContractRequestStatus', {
          posting: false,
          error: false,
          loading: false,
          fulfilled: true,
        }),
      );

    case ContractsTypes.UPDATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('updateContractRequestStatus', {
          posting: false,
          error: true,
          loading: false,
          fulfilled: false,
        }),
      );

    default:
      return state;
  }
};

export default reducer;
