import api from './api';

export interface FeatureFlagsRequest {
  flagKey: string;
  entityId: string;
  entityContext?: Record<string, unknown>;
}

export const featureFlagsService = (data: FeatureFlagsRequest) =>
  api('https://flags.letrus.com.br/api/v1/evaluation', {method: 'post', data});
