import {AnyAction, Reducer} from 'redux';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';

// Action types
export enum MassiveUploadSelectedFilesActionTypes {
  ADD_FILE = 'ADD_FILE',
  REMOVE_FILE = 'REMOVE_FILE',
  REMOVE_ALL_FILES = 'REMOVE_ALL_FILES',
  SET_FILES = 'SET_FILES',
}

// State type
export interface MassiveUploadSelectedFilesState {
  selectedFiles: File[];
}

// Actions
export const addFile = (selectedFile: File): AnyAction =>
  action(MassiveUploadSelectedFilesActionTypes.ADD_FILE, {selectedFile});

export const removeFile = (selectedFile: File): AnyAction =>
  action(MassiveUploadSelectedFilesActionTypes.REMOVE_FILE, {selectedFile});

export const removeAllFiles = (): AnyAction =>
  action(MassiveUploadSelectedFilesActionTypes.REMOVE_ALL_FILES);

export const setFiles = (selectedFiles: File[]): AnyAction =>
  action(MassiveUploadSelectedFilesActionTypes.SET_FILES, {selectedFiles});

// Selectors
const selectedFilesSelector = (state: ApplicationState) =>
  state.get('massiveUploadSelectedFiles');

export const getSelectedFiles = createSelector(
  selectedFilesSelector,
  (selectedFiles) => selectedFiles.selectedFiles as File[],
);

// Initial state
const INITIAL_STATE: MassiveUploadSelectedFilesState = {
  selectedFiles: [],
};

// Reducer
export const reducer: Reducer<MassiveUploadSelectedFilesState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case MassiveUploadSelectedFilesActionTypes.ADD_FILE:
      return {
        ...state,
        selectedFiles: [...state.selectedFiles, action.payload.selectedFile],
      };
    case MassiveUploadSelectedFilesActionTypes.REMOVE_FILE:
      return {
        ...state,
        selectedFiles: state.selectedFiles.filter(
          (file) => file !== action.payload.selectedFile,
        ),
      };
    case MassiveUploadSelectedFilesActionTypes.REMOVE_ALL_FILES:
      return {
        ...state,
        selectedFiles: [],
      };
    case MassiveUploadSelectedFilesActionTypes.SET_FILES:
      return {
        ...state,
        selectedFiles: action.payload.selectedFiles,
      };
    default:
      return state;
  }
};

export default reducer;
