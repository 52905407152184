import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchGradesService} from 'store/services/schoolGradesService';
import {action} from 'typesafe-actions';
import {schoolGroupsDataSelector} from '../schoolGroups';

/**
 * Types
 */
export enum SchoolsGradesTypes {
  FETCH_REQUEST = '@schoolGrades/FETCH_REQUEST',
  FETCH_SUCCESS = '@schoolGrades/FETCH_SUCCESS',
  FETCH_FAILURE = '@schoolGrades/FETCH_FAILURE',
}

export interface SchoolGradesState extends Map<string, any> {
  readonly data: List<AdminApi.SchoolGrades>;
  readonly loading: boolean;
  readonly error: boolean;
}

export type FetchGradesRequest = () => AnyAction;

export interface FetchGradesProps extends Utils.GetParams {
  school_ids?: (number | string)[];
  active?: number;
}

/**
 * Actions
 */
export const fetchGradesRequest = (params?: FetchGradesProps) => {
  return action(SchoolsGradesTypes.FETCH_REQUEST, params);
};

export const fetchGradesSuccess = (data: AdminApi.SchoolGradesGetResponse) =>
  action(SchoolsGradesTypes.FETCH_SUCCESS, {data});

export const fetchGradesFailure = () =>
  action(SchoolsGradesTypes.FETCH_FAILURE);

/**
 * Sagas
 */
export function* fetchGrades(action: AnyAction): any {
  try {
    const response = yield call(fetchGradesService, action.payload);
    yield put(fetchGradesSuccess(response.data.results));
  } catch (err) {
    yield put(fetchGradesFailure());
  }
}

/**
 * State
 */
export const INITIAL_STATE: SchoolGradesState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
});

/**
 * Selectors
 */

export function schoolGradesSelector(state: ApplicationState) {
  return state.getIn(['schoolGrades', 'data']);
}

export function schoolGroupsSelector(state: ApplicationState) {
  return state.getIn(['schoolGroups', 'data']);
}

export function selectedGradesSelector(state: ApplicationState) {
  const grades = state.getIn(['form', 'LearningPath', 'values', 'grades']);

  return grades?.map((grade: any) => grade.get('value'));
}

export const getIsLoadingSchoolGradeList = createSelector(
  (state: any) => {
    return state.get('isLoadingSchoolGradeList');
  },
  (isLoading) => isLoading,
);

export const getGradesList = createSelector(
  (state: any) => {
    return schoolGroupsDataSelector(state);
  },
  (groups) => {
    const grades = groups.map(
      (group: ImmutableMap<AdminApi.SchoolGroupsAdmin>) =>
        fromJS({
          label: group.get('school_grade_name'),
          value: group.get('school_grade'),
        }),
    );

    return grades.toSet();
  },
);

export interface SchoolGradeOptions {
  label: string;
  value: string;
}

export const getSchoolGradesOptions = createSelector(
  schoolGradesSelector,
  (schoolGrades) =>
    schoolGrades
      .map((schoolGrade: any) => ({
        label: schoolGrade.get('name'),
        value: schoolGrade.get('id'),
      }))
      .toJS()
      .sort((a: any, b: any) => b.value - a.value) as SchoolGradeOptions[],
);

/**
 * Reducer
 */
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SchoolsGradesTypes.FETCH_REQUEST:
      return state.withMutations((prevState: SchoolGradesState) =>
        prevState.set('loading', true),
      );
    case SchoolsGradesTypes.FETCH_SUCCESS:
      return state.withMutations((prevState: SchoolGradesState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data)),
      );
    case SchoolsGradesTypes.FETCH_FAILURE:
      return state.withMutations((prevState: SchoolGradesState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([])),
      );
    default:
      return state;
  }
};

export default reducer;
