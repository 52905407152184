import {Utils} from '@letrustech/letrus-api-interfaces';
import {
  MassiveLearningPathsInterface,
  UpdateLearningPathsRequestPayload,
} from 'store/reducers/MassiveLearningPaths';
import api, {PUBLIC_API_TOKEN, PUBLIC_API_URL} from './api';

const publicApiParams = {
  api_token: PUBLIC_API_TOKEN,
  baseURL: PUBLIC_API_URL,
  withCredentials: false,
};

interface MassiveLearningPathParams extends Utils.GetParams {
  template_id: number;
  school_group_id__in: number[];
}

export const massiveLearningPathsService = (
  data: MassiveLearningPathsInterface,
) =>
  api('/learning_paths/massive_create', {
    method: 'post',
    data,
    ...publicApiParams,
  });

export const updateLearningPathsService = (
  data: UpdateLearningPathsRequestPayload,
) =>
  api('/learning_paths/massive_update', {
    method: 'patch',
    data,
    ...publicApiParams,
  });

export const fetchLearningPathsByFilterService = (
  params?: MassiveLearningPathParams,
) =>
  api('/admin_api/v1/learning_path_instances/', {
    method: 'get',
    params: {
      template_id: params?.template_id,
      school_group_id__in: `${params?.school_group_id__in}`,
    },
  });
