import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {Schools} from '@letrustech/letrus-api-interfaces/dist/admin_api/schools';
import {fromJS, List, Map} from 'immutable';
import React from 'react';
import {Link} from 'react-router-dom';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createSchoolsService,
  fetchSchoolsService,
  updateSchoolsService,
} from 'store/services/schoolsService';
import {action} from 'typesafe-actions';
import toLocalDate from 'utils/date/toLocalDate';
import {StoreStatus} from 'utils/types/store';

// Actions type
export enum SchoolsTypes {
  FETCH_REQUEST = '@schools/FETCH_REQUEST',
  FETCH_SUCCESS = '@schools/FETCH_SUCCESS',
  FETCH_FAILURE = '@schools/FETCH_FAILURE',

  CREATE_REQUEST = '@schools/CREATE_REQUEST',
  CREATE_SUCCESS = '@schools/CREATE_SUCCESS',
  CREATE_FAILURE = '@schools/CREATE_FAILURE',

  UPDATE_REQUEST = '@schools/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@schools/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@schools/UPDATE_FAILURE',
}

export interface School extends Omit<Schools, 'category' | 'contract_type'> {
  trade_name?: string;
  legal_name?: string;
  cnpj?: string;
  category?: string;
  contract_type?: string;
  street_number?: string;
  street_address?: string;
  address_complement?: string;
  cep?: string;
  n_groups?: number;
  n_students?: number;
  n_tests?: number;
  n_users?: number;
  district?: string;
}

// State type
export interface SchoolsState extends Map<string, any> {
  readonly data: List<ImmutableMap<School>>;
  readonly loading: boolean;
  readonly error: boolean;
  readonly schoolUpdateRequestStatus: StoreStatus;
}

// Data types
export interface SchoolsDataObject {
  id: number;
  long_name: string;
  short_name: string;
  category: string;
  n_groups: number;
  n_students: number;
  created: string;
}

interface UpdateSchoolsDataObject {
  id: number;
  long_name?: string;
  short_name?: string;
  category?: string;
}

/**
 * Actions
 */
export type FetchSchoolsRequestType = (
  param?:
    | Utils.GetParams
    | {
        ids?: number | string;
        basic?: 1;
        networks?: (number | string)[];
        long_name?: string;
        active?: number;
      },
) => AnyAction;

export type FetchSchoolsSearchRequestType = (param?: {
  networks?: (number | string)[];
  search?: string;
  active: number;
  limit?: number;
}) => AnyAction;

export const fetchSchoolsRequest: FetchSchoolsRequestType = (params) =>
  action(SchoolsTypes.FETCH_REQUEST, params);

export const fetchSchoolsSearchRequest: FetchSchoolsSearchRequestType = (
  params,
) => action(SchoolsTypes.FETCH_REQUEST, params);

export const fetchSchoolsSuccess = (data: {
  results: List<AdminApi.SchoolsGetResponse>;
}) => action(SchoolsTypes.FETCH_SUCCESS, {data});

export const fetchSchoolsFailure = () => action(SchoolsTypes.FETCH_FAILURE);

export const createSchoolsRequest = (data?: any) =>
  action(SchoolsTypes.CREATE_REQUEST, data);

export const createSchoolsSuccess = (data: {
  results: List<AdminApi.SchoolsGetResponse>;
}) => action(SchoolsTypes.CREATE_SUCCESS, {data});

export const createSchoolsFailure = () => action(SchoolsTypes.CREATE_FAILURE);

export const updateSchoolsRequest = (data: Partial<School>) =>
  action(SchoolsTypes.UPDATE_REQUEST, data);

export const updateSchoolsSuccess = (data: UpdateSchoolsDataObject) =>
  action(SchoolsTypes.UPDATE_SUCCESS, data);

export const updateSchoolsFailure = () => action(SchoolsTypes.UPDATE_FAILURE);

// Sagas
export function* fetchSchools(action: AnyAction): any {
  try {
    const response = yield call(fetchSchoolsService, action.payload);
    yield put(fetchSchoolsSuccess(response.data));
  } catch (err) {
    yield put(fetchSchoolsFailure());
  }
}

export function* createSchools(action: AnyAction): any {
  try {
    const response = yield call(createSchoolsService, action.payload);
    yield put(createSchoolsSuccess(response.data));
  } catch (err) {
    yield put(createSchoolsFailure());
  }
}

export function* updateSchools(action: AnyAction): any {
  try {
    const response = yield call(updateSchoolsService, action.payload);
    yield put(updateSchoolsSuccess(response.data));
  } catch (err) {
    yield put(updateSchoolsFailure());
  }
}

// Initial state
export const INITIAL_STATE: SchoolsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  schoolUpdateRequestStatus: {
    fulfilled: false,
    error: false,
    posting: false,
    loading: false,
  },
});

/**
 * Selectors
 */
export const getSchoolsData = (state: ApplicationState) =>
  state.getIn(['schools', 'data']);

const schoolsSelector = (state: ApplicationState) => state.get('schools');

export const getSchoolsList = createSelector(
  getSchoolsData,
  (schools: List<ImmutableMap<SchoolsDataObject>>) => {
    return schools.size
      ? schools
          .map((school: ImmutableMap<SchoolsDataObject>) => {
            const schoolId = school.get('id');
            return fromJS({
              long_name: school.get('long_name'),
              id:
                process.env.NODE_ENV === 'test' ? (
                  schoolId
                ) : (
                  <Link to={`/editar/escola/${school.get('id')}`}>
                    {schoolId}
                  </Link>
                ),
              short_name: school.get('short_name'),
              category: school.get('category'),
              n_groups: school.get('n_groups'),
              n_students: school.get('n_students'),
              created: toLocalDate(school.get('created')),
            });
          })
          .toJS()
      : fromJS([]);
  },
);

export const getSchoolsListCount = createSelector(schoolsSelector, (schools) =>
  schools.get('dataCount'),
);

export const getIsLoadingSchools = createSelector(schoolsSelector, (schools) =>
  schools.get('loading'),
);

export const getSchoolUpdateRequestStatus = createSelector(
  schoolsSelector,
  (schools) => schools.get('schoolUpdateRequestStatus'),
);

export const getSchoolsError = createSelector(schoolsSelector, (schools) =>
  schools.get('error'),
);

export const getSchoolsOptions = createSelector(getSchoolsData, (schools) =>
  schools.size
    ? schools
        .map((school: any) => ({
          label: school.get('long_name'),
          value: school.get('id'),
        }))
        .toJS()
    : fromJS([]),
);

// Reducer
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SchoolsTypes.FETCH_REQUEST:
      return state.withMutations((prevState: SchoolsState) =>
        prevState.set('loading', true),
      );
    case SchoolsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState: SchoolsState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );
    case SchoolsTypes.FETCH_FAILURE:
      return state.withMutations((prevState: SchoolsState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([])),
      );
    case SchoolsTypes.CREATE_REQUEST:
      return state.withMutations((prevState: SchoolsState) =>
        prevState.set('loading', true),
      );
    case SchoolsTypes.CREATE_SUCCESS:
      return state.withMutations((prevState: SchoolsState) =>
        prevState.set('loading', false).set('error', false),
      );
    case SchoolsTypes.CREATE_FAILURE:
      return state.withMutations((prevState: SchoolsState) =>
        prevState.set('loading', false).set('error', true),
      );

    case SchoolsTypes.UPDATE_REQUEST:
      return state.withMutations((prevState: SchoolsState) =>
        prevState.set('loading', true).set('schoolUpdateRequestStatus', {
          posting: true,
          fulfilled: false,
          error: false,
          loading: false,
        }),
      );
    case SchoolsTypes.UPDATE_SUCCESS:
      return state.withMutations((prevState: SchoolsState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('schoolUpdateRequestStatus', {
            posting: false,
            fulfilled: true,
            error: false,
            loading: false,
          }),
      );
    case SchoolsTypes.UPDATE_FAILURE:
      return state.withMutations((prevState: SchoolsState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('schoolUpdateRequestStatus', {
            posting: false,
            fulfilled: false,
            error: true,
            loading: false,
          }),
      );

    default:
      return state;
  }
};

export default reducer;
