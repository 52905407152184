import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createTestTemplatesService,
  fetchTestTemplatesService,
} from 'store/services/testTemplatesService';
import {action} from 'typesafe-actions';
import formatToLocalDate from 'utils/date/toLocalDate';
// Actions types
export enum TestTemplatesTypes {
  FETCH_REQUEST = '@testTemplates/FETCH_REQUEST',
  FETCH_SUCCESS = '@testTemplates/FETCH_SUCCESS',
  FETCH_FAILURE = '@testTemplates/FETCH_FAILURE',

  CREATE_REQUEST = '@testTemplates/CREATE_REQUEST',
  CREATE_SUCCESS = '@testTemplates/CREATE_SUCCESS',
  CREATE_FAILURE = '@testTemplates/CREATE_FAILURE',
}

// State
export interface TestTemplatesState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.TestTemplatesGetRequest>>;
  readonly dataCount: number;
  readonly loading: boolean;
  readonly error: boolean;
}

export type FetchTestTemplatesRequestType = (
  params?: Utils.GetParams,
) => AnyAction;
export type CreateTestTemplatesRequestType = (data?: any) => AnyAction;

// Actions
export const createTestTemplatesRequest: CreateTestTemplatesRequestType = (
  data,
) => {
  return action(TestTemplatesTypes.CREATE_REQUEST, data);
};

export const createTestTemplatesSuccess = (
  data: List<ImmutableMap<AdminApi.TestTemplates>>,
) => action(TestTemplatesTypes.CREATE_SUCCESS, data);

export const createTestTemplatesFailure = ({payload: data}: any) => {
  return action(TestTemplatesTypes.CREATE_FAILURE, {
    schoolId: data.data.school_group_id,
  });
};

export const fetchTestTemplatesRequest: FetchTestTemplatesRequestType = (
  params,
) => action(TestTemplatesTypes.FETCH_REQUEST, {params});

export const fetchTestTemplatesSuccess = (
  data: List<ImmutableMap<AdminApi.TestTemplatesGetResponse>>,
) => action(TestTemplatesTypes.FETCH_SUCCESS, {data});

export const fetchTestTemplatesFailure = () =>
  action(TestTemplatesTypes.FETCH_FAILURE);

// Sagas
export function* fetchTestTemplates(action: AnyAction): any {
  try {
    const response = yield call(fetchTestTemplatesService, action.payload);
    yield put(fetchTestTemplatesSuccess(response.data));
  } catch (err) {
    yield put(fetchTestTemplatesFailure());
  }
}
export function* createTestTemplate(action: AnyAction): any {
  try {
    const response = yield call(createTestTemplatesService, action.payload);
    yield put(createTestTemplatesSuccess(response.data));
  } catch (err) {
    yield put(createTestTemplatesFailure(action));
  }
}

// Initial state
export const INITIAL_STATE: TestTemplatesState = fromJS({
  data: fromJS([]),
  error: fromJS([]),
  loading: false,
});

// Selectors
const testTemplatesSelector = (state: ApplicationState) => {
  return state.get('testTemplates');
};

const testTemplatesDataSelector = (state: ApplicationState) => {
  return state.getIn(['testTemplates', 'data']);
};

export const getTestTemplatesTableData = createSelector(
  testTemplatesDataSelector,
  (testTemplates) => {
    const testTemplatesList = testTemplates.map(
      (testTemplate: ImmutableMap<AdminApi.TestTemplates>) =>
        fromJS({
          id: testTemplate.get('id') ?? '-',
          name: testTemplate.get('name') ?? '-',
          theme_id: testTemplate.getIn(['theme', 'id']) ?? '-',
          theme_title: testTemplate.getIn(['theme', 'title']) ?? '-',
          creation_date: testTemplate.get('created')
            ? formatToLocalDate(testTemplate.get('created'))
            : '-',
          modification_date: testTemplate.get('modified')
            ? formatToLocalDate(testTemplate.get('modified'))
            : '-',
          manual_review: testTemplate.get('manual_review') ? 'Sim' : 'Não',
          timer: testTemplate.get('timer') ?? '-',
          type:
            testTemplate.get('rewriting_type') === 0 ? 'Reescrita' : 'Revisão',
          before_writing: testTemplate.get('before_writing') ?? '-',
        }),
    );

    return testTemplatesList;
  },
);

export const getTestTemplatesError = createSelector(
  testTemplatesSelector,
  (testTemplate) => {
    return testTemplate.get('error');
  },
);
export const getTestTemplatesCount = createSelector(
  testTemplatesSelector,
  (testTemplates) => testTemplates.get('dataCount'),
);
export const getIsTestTemplatesLoading = createSelector(
  testTemplatesSelector,
  (testTemplates) => {
    return testTemplates.get('loading');
  },
);
export const getTestTemplatesOptions = createSelector(
  testTemplatesDataSelector,
  (testTemplates) =>
    testTemplates.map((testTemplate: ImmutableMap<AdminApi.TestTemplates>) => ({
      label: testTemplate.get('name'),
      value: testTemplate.get('id'),
    })),
);

// Reducer
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TestTemplatesTypes.FETCH_REQUEST:
      return state.withMutations((prevState: TestTemplatesState) =>
        prevState.set('loading', true).set('error', fromJS([])),
      );
    case TestTemplatesTypes.FETCH_SUCCESS:
      return state.withMutations((prevState: TestTemplatesState) =>
        prevState
          .set('loading', false)
          .set('error', fromJS([]))
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );
    case TestTemplatesTypes.FETCH_FAILURE:
      return state.withMutations((prevState: TestTemplatesState) =>
        prevState
          .set('loading', false)
          .set('error', fromJS([]))
          .set('dataCount', 0)
          .set('data', []),
      );
    case TestTemplatesTypes.CREATE_REQUEST:
      return state.withMutations((prevState: TestTemplatesState) =>
        prevState.set('loading', true).set('error', fromJS([])),
      );
    case TestTemplatesTypes.CREATE_SUCCESS:
      return state.withMutations((prevState: TestTemplatesState) =>
        prevState.set('loading', false),
      );
    case TestTemplatesTypes.CREATE_FAILURE:
      return state.withMutations((prevState: TestTemplatesState) => {
        return prevState
          .set('loading', false)
          .set('error', prevState.get('error').merge(action.payload.schoolId));
      });
    default:
      return state;
  }
};

export default reducer;
