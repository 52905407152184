import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createPublicService,
  loginPublicService,
} from 'store/services/publicApi';
import {PublicApiRequest} from 'store/services/publicApiInterfaces';
import {action} from 'typesafe-actions';

// Actions type
export enum PublicApiTypes {
  CREATE_PUBLIC_REQUEST = '@publicApi/CREATE_PUBLIC_REQUEST',
  CREATE_PUBLIC_SUCCESS = '@publicApi/CREATE_PUBLIC_SUCCESS',
  CREATE_PUBLIC_FAILURE = '@publicApi/CREATE_PUBLIC_FAILURE',

  RESET_PUBLIC_REQUEST = '@publicApi/RESET_PUBLIC_REQUEST',

  LOGIN_PUBLIC_REQUEST = '@publicApi/LOGIN_PUBLIC_REQUEST',
  LOGIN_PUBLIC_SUCCESS = '@publicApi/LOGIN_PUBLIC_SUCCESS',
  LOGIN_PUBLIC_FAILURE = '@publicApi/LOGIN_PUBLIC_FAILURE',
}

// State type
export interface PublicApiState extends Map<string, any> {
  readonly requestId: string;
  readonly loading: boolean;
  readonly error: boolean;
}

// Data types

/**
 * Actions
 */

export const createPublicRequest = (data: PublicApiRequest) =>
  action(PublicApiTypes.CREATE_PUBLIC_REQUEST, data);

export const createPublicSuccess = (data: {request_id: number}) =>
  action(PublicApiTypes.CREATE_PUBLIC_SUCCESS, {data});

export const createPublicFailure = () =>
  action(PublicApiTypes.CREATE_PUBLIC_FAILURE);

export const loginPublicRequest = (param: any) =>
  action(PublicApiTypes.LOGIN_PUBLIC_REQUEST, param);

export const loginPublicSuccess = (data: {request_id: number}) =>
  action(PublicApiTypes.LOGIN_PUBLIC_SUCCESS, {data});

export const loginPublicFailure = () =>
  action(PublicApiTypes.LOGIN_PUBLIC_FAILURE);

export const resetPublicRequest = () =>
  action(PublicApiTypes.RESET_PUBLIC_REQUEST);

// Sagas
export function* createPublic(action: AnyAction): any {
  try {
    // TODO: CHANGE SERVICE DEPENDING ON ROUTE
    const response = yield call(createPublicService, action.payload);
    yield put(createPublicSuccess(response.data));
  } catch (err) {
    yield put(createPublicFailure());
  }
}

export function* loginPublic(action: AnyAction): any {
  try {
    const response = yield call(loginPublicService, action.payload);
    yield put(loginPublicSuccess(response.data));
  } catch (err) {
    yield put(loginPublicFailure());
  }
}

// Initial state
export const INITIAL_STATE: PublicApiState = fromJS({
  requestId: null,
  error: false,
  loading: false,
});

/**
 * Selectors
 */
const publicApiSelector = (state: ApplicationState) => state.get('publicApi');

export const getPublicApiRequestId = createSelector(
  publicApiSelector,
  (publicApi) => publicApi.get('requestId'),
);
export const getIsLoadingPublicApi = createSelector(
  publicApiSelector,
  (publicApi) => publicApi.get('loading'),
);

export const getPublicApiError = createSelector(
  publicApiSelector,
  (publicApi) => publicApi.get('error'),
);

// Reducer
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PublicApiTypes.CREATE_PUBLIC_REQUEST:
      return state.withMutations((prevState: PublicApiState) =>
        prevState
          .set('loading', true)
          .set('error', false)
          .set('requestId', null),
      );
    case PublicApiTypes.CREATE_PUBLIC_SUCCESS:
      return state.withMutations((prevState: PublicApiState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('requestId', action.payload.data.request_id),
      );
    case PublicApiTypes.CREATE_PUBLIC_FAILURE:
      return state.withMutations((prevState: PublicApiState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('requestId', null),
      );

    case PublicApiTypes.LOGIN_PUBLIC_REQUEST:
      return state.withMutations((prevState: PublicApiState) =>
        prevState.set('loading', true).set('error', false),
      );
    case PublicApiTypes.LOGIN_PUBLIC_SUCCESS:
      return state.withMutations((prevState: PublicApiState) =>
        prevState.set('loading', false).set('error', false),
      );
    case PublicApiTypes.LOGIN_PUBLIC_FAILURE:
      return state.withMutations((prevState: PublicApiState) =>
        prevState.set('loading', false).set('error', true),
      );

    case PublicApiTypes.RESET_PUBLIC_REQUEST:
      return (state = INITIAL_STATE);

    default:
      return state;
  }
};

export default reducer;
