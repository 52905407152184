import {MassiveRegistrationInterface} from 'store/services/massiveRegistrationInterface';
import {MassiveRegistrationTypes} from 'utils/types/massiveRegistration';
import api, {PUBLIC_API_TOKEN, PUBLIC_API_URL} from './api';

export type MassiveResgistrationServices =
  | 'students'
  | 'contract_clauses'
  | 'contracts'
  | 'schools'
  | 'teachers'
  | 'school_groups'
  | 'coordinators';

const publicApiParams = {
  api_token: PUBLIC_API_TOKEN,
  baseURL: PUBLIC_API_URL,
  withCredentials: false,
};

const urlDictionary: {[Key in MassiveRegistrationTypes]: string} = {
  alunos: '/students',
  clausulas: '/contract_clauses',
  completo: '/school_groups',
  contratos: '/contracts',
  escolas: '/schools',
  professores: '/teachers',
  turmas: '/school_groups',
  coordenadores: '/coordinators',
};

export const massiveRegistrationService = (
  data: MassiveRegistrationInterface,
) =>
  api(`${urlDictionary[data.registrationType]}/upload_file`, {
    method: 'post',
    data: {...data.bulk, email: data.email, file_name: data.fileName},
    ...publicApiParams,
  });

export const fetchMassiveRegistrationProgressService = (requestId: string) =>
  api(`/monitoring/${requestId}`, {
    method: 'get',
    ...publicApiParams,
  });

export const fetchMassiveRegistrationQueueProgressService = (
  service?: MassiveResgistrationServices,
  email?: string,
) =>
  api(`/monitoring/`, {
    method: 'get',
    params: {
      email,
      limit: 300,
      service,
    },
    ...publicApiParams,
  });
