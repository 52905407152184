import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchCompositionInstructionsService} from 'store/services/compositionInstructionsService';
import {action} from 'typesafe-actions';

// Actions types
export enum CompositionInstructionsTypes {
  FETCH_REQUEST = '@compositionInstructions/FETCH_REQUEST',
  FETCH_SUCCESS = '@compositionInstructions/FETCH_SUCCESS',
  FETCH_FAILURE = '@compositionInstructions/FETCH_FAILURE',
}

// State
export interface CompositionInstructionsState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.CompositionInstructions>>;
  readonly loading: boolean;
  readonly error: boolean;
}

export type FetchCompositionInstructionsRequestType = (
  params?: Utils.GetParams,
) => AnyAction;

// Actions
export const fetchCompositionInstructionsRequest: FetchCompositionInstructionsRequestType =
  (params) => action(CompositionInstructionsTypes.FETCH_REQUEST, params);

export const fetchCompositionInstructionsSuccess = (
  data: List<ImmutableMap<AdminApi.CompositionInstructionsGetResponse>>,
) =>
  action(CompositionInstructionsTypes.FETCH_SUCCESS, {
    data,
  });

export const fetchCompositionInstructionsFailure = () =>
  action(CompositionInstructionsTypes.FETCH_FAILURE);

// Sagas
export function* fetchCompositionInstructions(action: AnyAction): any {
  try {
    const response = yield call(
      fetchCompositionInstructionsService,
      action.payload,
    );
    yield put(fetchCompositionInstructionsSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionInstructionsFailure());
  }
}

// Initial state
export const INITIAL_STATE: CompositionInstructionsState = fromJS({
  data: fromJS([]),
  loading: false,
  error: false,
});

// Selectors
const compositionInstructionsDataSelector = (state: ApplicationState) =>
  state.getIn(['compositionInstructions', 'data']);

export const getThemeList = (state: ApplicationState) =>
  compositionInstructionsDataSelector(state);

export const getIsLoadingThemeList = (state: ApplicationState) =>
  state.getIn(['compositionInstructions', 'loading']);

export const getCompositionInstructionsOptions = createSelector(
  compositionInstructionsDataSelector,
  (compositionInstructions) =>
    compositionInstructions.map(
      (
        compositionInstruction: ImmutableMap<AdminApi.CompositionInstructions>,
      ) => ({
        value: compositionInstruction.get('id'),
        label: compositionInstruction.get('title'),
      }),
    ),
);
export const getCompositionInstructionsDataTable = createSelector(
  compositionInstructionsDataSelector,
  (compositionInstructions) => {
    const compositionInstructionsList = compositionInstructions.map(
      (
        compositionInstruction: ImmutableMap<AdminApi.CompositionInstructions>,
      ) =>
        fromJS({
          id: compositionInstruction.get('id'),
          genre: compositionInstruction.get('genre'),
          school: compositionInstruction.get('school'),
          title: compositionInstruction.get('title'),
          contentHtml: compositionInstruction.get('content_html'),
          image: compositionInstruction.get('image'),
          altImage: compositionInstruction.get('alt_image'),
          visible: compositionInstruction.get('visible'),
          advancedLetrus: compositionInstruction.get('advanced_letrus'),
          priorityOrder: compositionInstruction.get('priority_order'),
          aids: compositionInstruction.get('aids'),
        }),
    );

    return compositionInstructionsList;
  },
);

// Reducer
const reducer: Reducer<CompositionInstructionsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case CompositionInstructionsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('error', false).set('loading', true),
      );

    case CompositionInstructionsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data.results)),
      );

    case CompositionInstructionsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([])),
      );

    default:
      return state;
  }
};

export default reducer;
