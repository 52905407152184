import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import Qs from 'qs';

export function getAxiosInstance(
  requestConfig: AxiosRequestConfig,
): AxiosInstance {
  return axios.create({
    ...requestConfig,
    method: requestConfig.method || 'get',
    paramsSerializer: (params: Record<string, unknown>) =>
      Qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
  });
}
