/* eslint-disable react/function-component-definition */
import React, {useState} from 'react';
import SideBar, {Menu, Theme} from 'components/infra/SideBar';
import Navbar from 'components/infra/Navbar';
import Image from 'components/infra/Image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Logo from '../../../styles/images/letrus-logo.svg';
import styles from './PageWrapper.module.scss';
import {UserRole} from '../../../constants';

export interface PageWrapperProps {
  menu: Menu[];
  theme: Theme;
  rightComponent: React.ReactNode;
  children: React.ReactNode;
  userRole?: UserRole;
}

function PageWrapper({
  menu,
  theme,
  rightComponent,
  children,
  userRole = 'anonymous',
}: PageWrapperProps): React.ReactElement {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const navbarLeftComponent = (
    <button
      className={styles.menuButton}
      onClick={() => setIsSidebarOpen(isOpen => !isOpen)}
      data-testid="menuButton"
    >
      <FontAwesomeIcon icon="bars" size="lg" color="#3C405F" />
    </button>
  );

  return (
    <div className={styles.wrapper}>
      <Navbar
        leftComponent={navbarLeftComponent}
        centerComponent={<Image alt="Logo Letrus" imageSrc={Logo} />}
        rightComponent={rightComponent}
        userRole={userRole}
      />

      <div className={styles.body}>
        <SideBar
          menu={menu}
          closed={!isSidebarOpen}
          theme={theme}
          userRole={userRole}
        />

        <div
          className={`
            ${styles.content} 
            ${isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default PageWrapper;
