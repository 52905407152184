import {DropdownMenu, PageWrapper} from 'letrus-ui';
import {ItemProps} from 'letrus-ui/dist/components/infra/DropdownMenu/index';
import {Menu} from 'letrus-ui/dist/components/infra/SideBar/index';
import React from 'react';
import {useHistory} from 'react-router-dom';
import forceLogout from 'utils/functions/forceLogout';
import styles from './PageLayout.module.scss';

interface PageLayoutProps {
  children: JSX.Element;
}

function PageLayout({children}: PageLayoutProps): JSX.Element {
  const history = useHistory();

  const headerMenuOptions: Array<ItemProps> = [
    {
      id: 1,
      text: 'Django Admin',
      icon: 'external-link',
      onClick: () => window.open(process.env.REACT_APP_ADMIN_API_URL),
    },
    {
      id: 2,
      text: 'Sair',
      icon: 'sign-out',
      onClick: () => forceLogout(),
    },
  ];

  const sidebarOptions: Menu[] = [
    {
      label: 'Home',
      active: false,
      icon: {icon: 'home'},
      onClick: () => history.push('/'),
    },
  ];

  return (
    <PageWrapper
      theme="dark"
      menu={sidebarOptions}
      rightComponent={<DropdownMenu items={headerMenuOptions} title="Menu" />}
    >
      <main className={styles.container}>{children}</main>
    </PageWrapper>
  );
}

export default PageLayout;
