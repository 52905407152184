import {format} from 'date-fns';
/**
 * Convert date string format to `dd/mm/yyyy`
 * @param date is a string like 2019-02-28T17:34:43.124513-03:00
 * @param includeTime define if the return string includes hour and minutes on `HH:mm` format
 * */
function toLocalDate(date?: string, includeTime = false): string {
  return date
    ? format(new Date(date), `dd/MM/yyyy${includeTime ? ' HH:mm' : ''}`)
    : '-';
}

export default toLocalDate;
