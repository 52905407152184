import {library} from '@fortawesome/fontawesome-svg-core';
import AuthRoute from 'components/AuthRoute';
import React, {lazy, useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import store from 'store';
import {setupInterceptors} from 'store/services/api';
import ScrollToTop from 'utils/hooks/ScrollToTop';
import fontawesomeIcons from './assets/icons/fontawsome';

const Home = lazy(() => import('containers/Home'));
const Login = lazy(() => import('containers/Login'));
const NotFound = lazy(() => import('containers/NotFound'));
const ManagerDashboards = lazy(() => import('containers/ManagerDashboards'));
const CompositionsRead = lazy(() => import('containers/CompositionsRead'));
const ContractsUpdate = lazy(() => import('containers/ContractsUpdate'));
const ContractsRead = lazy(() => import('containers/ContractsRead'));
const UsersRead = lazy(() => import('containers/UsersRead'));
const SchoolsRead = lazy(() => import('containers/SchoolsRead'));
const SchoolDetails = lazy(() => import('containers/SchoolsUpdate'));
const SchoolGroupsRead = lazy(() => import('containers/SchoolGroupsRead'));
const TestsUpdate = lazy(() => import('containers/TestsUpdate'));
const TestsCreate = lazy(() => import('containers/TestsCreate'));
const TestsRead = lazy(() => import('containers/TestsRead'));
const LearningPathDelete = lazy(() => import('containers/LearningPathDelete'));
const LearningPathCreate = lazy(() => import('containers/LearningPathCreate'));
const LearningPathUpdate = lazy(() => import('containers/LearningPathUpdate'));
const NotificationsSend = lazy(() => import('containers/NotificationsSend'));
const NotificationsGroupCreate = lazy(
  () => import('containers/NotificationGroupCreate'),
);
const MassiveRegistration = lazy(
  () => import('containers/MassiveRegistration'),
);

export enum AuthRoutes {
  home = '/',
  accessControl = '/controle-acessos/:userRole',
  massiveRegistration = '/cadastro-massivo/:registrationType',

  usersRead = '/usuarios/listar',
  schoolGroupsRead = '/turmas/listar',
  compositionsRead = '/redacoes/listar',

  contractsRead = '/contratos/listar',
  contractsUpdate = '/contratos/:contractId/editar',

  schoolsRead = '/escolas/listar',
  schoolDetails = '/escolas/:schoolId/editar',

  testsRead = '/atividades/listar',
  testsUpdate = '/atividades/editar',
  testsCreate = '/atividades/cadastrar',

  learningPathUpdate = '/trilhas/editar',
  learningPathDelete = '/trilhas/excluir',
  learningPathCreate = '/trilhas/cadastrar',

  notificationsSend = '/notificacoes/:userRole/enviar',
  notificationGroupCreate = '/notificacoes/:userRole/criar-grupo',
}

export enum PublicRoutes {
  login = '/login',
}

function Router(): JSX.Element {
  useEffect(() => {
    library.add(...fontawesomeIcons);
    setupInterceptors(store);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <AuthRoute exact component={Home} path={AuthRoutes.home} />

        <AuthRoute
          exact
          component={MassiveRegistration}
          path={AuthRoutes.massiveRegistration}
        />

        <AuthRoute
          exact
          component={TestsCreate}
          path={AuthRoutes.testsCreate}
        />

        <AuthRoute
          exact
          component={TestsUpdate}
          path={AuthRoutes.testsUpdate}
        />

        <AuthRoute
          exact
          component={SchoolsRead}
          path={AuthRoutes.schoolsRead}
        />

        <AuthRoute component={TestsRead} path={AuthRoutes.testsRead} />

        <AuthRoute
          component={CompositionsRead}
          path={AuthRoutes.compositionsRead}
        />

        <AuthRoute
          component={SchoolGroupsRead}
          path={AuthRoutes.schoolGroupsRead}
        />

        <AuthRoute exact component={UsersRead} path={AuthRoutes.usersRead} />

        <AuthRoute
          exact
          component={ContractsUpdate}
          path={AuthRoutes.contractsUpdate}
        />

        <AuthRoute
          exact
          component={ContractsRead}
          path={AuthRoutes.contractsRead}
        />

        <AuthRoute
          exact
          component={SchoolDetails}
          path={AuthRoutes.schoolDetails}
        />
        <AuthRoute
          exact
          component={NotificationsGroupCreate}
          path={AuthRoutes.notificationGroupCreate}
        />

        <AuthRoute
          exact
          component={NotificationsSend}
          path={AuthRoutes.notificationsSend}
        />

        <AuthRoute
          exact
          component={ManagerDashboards}
          path={AuthRoutes.accessControl}
        />

        <AuthRoute
          exact
          component={LearningPathDelete}
          path={AuthRoutes.learningPathDelete}
        />

        <AuthRoute
          exact
          component={LearningPathUpdate}
          path={AuthRoutes.learningPathUpdate}
        />

        <AuthRoute
          exact
          component={LearningPathCreate}
          path={AuthRoutes.learningPathCreate}
        />

        <Route path={PublicRoutes.login} component={Login} />

        <AuthRoute component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
