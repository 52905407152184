import {Map} from 'immutable';
import {FormReducer} from 'redux-form';
import {reducer as formReducer} from 'redux-form/immutable';
import {combineReducers} from 'redux-immutable';
import publicApiReducer, {PublicApiState} from 'store/reducers/publicApi';
import questionnairesReducer, {
  QuestionnairesState,
} from 'store/reducers/questionnaires';
import authenticationReducer, {
  AuthenticationState,
} from './reducers/authentication';
import clausesReducer, {ClausesState} from './reducers/clauses';
import compositionGenresReducer, {
  CompositionGenresState,
} from './reducers/compositionGenres';
import compositionInstructionsReducer, {
  CompositionInstructionsState,
} from './reducers/compositionInstructions';
import compositionReviewsReducer, {
  CompositionReviewsState,
} from './reducers/compositionReviews';
import compositionsReducer from './reducers/compositions';
import contractContactsReducer, {
  ContractContactsState,
} from './reducers/contractContacts';
import contractInstallmentsReducer, {
  ContractInstallmentsState,
} from './reducers/contractInstallments';
import contractsReducer, {ContractsState} from './reducers/contracts';
import featureFlagsReducer, {FeatureFlagsState} from './reducers/featureFlags';
import learningPathInstanceReducer, {
  LearningPathInstanceState,
} from './reducers/learningPathInstances';
import learningPathsReducer, {
  LearningPathsState,
} from './reducers/learningPaths';
import managerAccessReducer, {
  ManagerAccessState,
} from './reducers/managerAccess';
import testsEditReducer, {TestsEditState} from './reducers/MassiveEditTests';
import massiveLearningPathsReducer, {
  MassiveLearningPathsState,
} from './reducers/MassiveLearningPaths';
import massiveRegistrationReducer, {
  MassiveRegistrationState,
} from './reducers/massiveRegistration';
import massiveTestsReducer, {
  MassiveTestsState,
} from './reducers/massiveTests/index';
import massiveUploadSelectedFilesReducer, {
  MassiveUploadSelectedFilesState,
} from './reducers/massiveUploadSelectedFiles';
import notificationsReducer, {
  NotificationsState,
} from './reducers/Notifications';
import schoolGradesReducer, {SchoolGradesState} from './reducers/schoolGrades';
import schoolGroupsReducer, {SchoolGroupsState} from './reducers/schoolGroups';
import schoolNetworksReducer, {
  SchoolNetworksState,
} from './reducers/schoolNetworks';
import schoolsReducer, {SchoolsState} from './reducers/schools';
import testsReducer, {TestsState} from './reducers/tests';
import testTemplatesReducer, {
  TestTemplatesState,
} from './reducers/testTemplates';
import userRolesReducer from './reducers/userRoles';
import usersReducer, {UsersState} from './reducers/users';

export interface ApplicationState extends Map<any, any> {
  readonly tests: TestsState;
  readonly testsEdit: TestsEditState;
  readonly authentication: AuthenticationState;
  readonly compositionReviews: CompositionReviewsState;
  readonly form: FormReducer;
  readonly learningPaths: LearningPathsState;
  readonly schoolGrades: SchoolGradesState;
  readonly schoolGroups: SchoolGroupsState;
  readonly schools: SchoolsState;
  readonly testTemplates: TestTemplatesState;
  readonly massiveTests: MassiveTestsState;
  readonly massiveLearningPaths: MassiveLearningPathsState;
  readonly massiveSelectedFiles: MassiveUploadSelectedFilesState;
  readonly managerAccess: ManagerAccessState;
  readonly compositionInstructions: CompositionInstructionsState;
  readonly users: UsersState;
  readonly questionnaires: QuestionnairesState;
  readonly userRoles: UsersState;
  readonly schoolNetworks: SchoolNetworksState;
  readonly contracts: ContractsState;
  readonly clauses: ClausesState;
  readonly contractInstallments: ContractInstallmentsState;
  readonly compositionGenres: CompositionGenresState;
  readonly contractContacts: ContractContactsState;
  readonly publicApi: PublicApiState;
  readonly massiveRegistration: MassiveRegistrationState;
  readonly featureFlags: FeatureFlagsState;
  readonly notifications: NotificationsState;
  readonly learningPathInstance: LearningPathInstanceState;
}

const rootReducer = () =>
  combineReducers({
    tests: testsReducer,
    testsEdit: testsEditReducer,
    authentication: authenticationReducer,
    compositionReviews: compositionReviewsReducer,
    compositions: compositionsReducer,
    form: formReducer,
    learningPaths: learningPathsReducer,
    schoolGrades: schoolGradesReducer,
    schoolGroups: schoolGroupsReducer,
    schools: schoolsReducer,
    testTemplates: testTemplatesReducer,
    massiveTests: massiveTestsReducer,
    massiveLearningPaths: massiveLearningPathsReducer,
    managerAccess: managerAccessReducer,
    compositionInstructions: compositionInstructionsReducer,
    users: usersReducer,
    questionnaires: questionnairesReducer,
    userRoles: userRolesReducer,
    schoolNetworks: schoolNetworksReducer,
    contracts: contractsReducer,
    clauses: clausesReducer,
    contractInstallments: contractInstallmentsReducer,
    compositionGenres: compositionGenresReducer,
    contractContacts: contractContactsReducer,
    publicApi: publicApiReducer,
    massiveRegistration: massiveRegistrationReducer,
    massiveUploadSelectedFiles: massiveUploadSelectedFilesReducer,
    featureFlags: featureFlagsReducer,
    notifications: notificationsReducer,
    learningPathInstance: learningPathInstanceReducer,
  });

export default rootReducer;
