import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  associateManagersToDashboardService,
  fetchAllDashboardsService,
  fetchDashboardsAssociatedWithManagerService,
  fetchManagersAssociatedWithDashboardService,
} from 'store/services/managerAccess';
import {action} from 'typesafe-actions';

// Actions types
export enum ManagerAccessTypes {
  FETCH_ALL_DASHBOARDS_REQUEST = '@managerAccess/FETCH_ALL_DASHBOARDS_REQUEST',
  FETCH_ALL_DASHBOARDS_SUCCESS = '@managerAccess/FETCH_ALL_DASHBOARDS_SUCCESS',
  FETCH_ALL_DASHBOARDS_FAILURE = '@managerAccess/FETCH_ALL_DASHBOARDS_FAILURE',

  FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_REQUEST = '@managerAccess/FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_REQUEST',
  FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_SUCCESS = '@managerAccess/FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_SUCCESS',
  FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_FAILURE = '@managerAccess/FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_FAILURE',

  FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_REQUEST = '@managerAccess/FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_REQUEST',
  FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_SUCCESS = '@managerAccess/FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_SUCCESS',
  FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_FAILURE = '@managerAccess/FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_FAILURE',

  ASSOCIATE_MANAGERS_TO_DASHBOARD_REQUEST = '@managerAccess/ASSOCIATE_MANAGERS_TO_DASHBOARD_REQUEST',
  ASSOCIATE_MANAGERS_TO_DASHBOARD_SUCCESS = '@managerAccess/ASSOCIATE_MANAGERS_TO_DASHBOARD_SUCCESS',
  ASSOCIATE_MANAGERS_TO_DASHBOARD_FAILURE = '@managerAccess/ASSOCIATE_MANAGERS_TO_DASHBOARD_FAILURE',
}

// State type
export interface ManagerAccessState extends Map<string, any> {
  readonly managersList: Array<number>;
  readonly dashboardsAssociatedWithManagerList: Array<DashboardsData>;
  readonly allDashboardsList: Array<AllDashboardsData>;
  readonly loading: boolean;
  readonly isLoadingAssociateManagersToDashboard: boolean;
  readonly error: boolean;
}

export interface DashboardsData {
  id: number;
  metabase_id: number;
  name: string;
  display_name: string;
}

export interface AllDashboardsData extends DashboardsData {
  users: number[];
}

// Fetch actions
export const fetchAllDashboardsRequest = () => {
  return action(ManagerAccessTypes.FETCH_ALL_DASHBOARDS_REQUEST);
};

export const fetchAllDashboardsSuccess = (data: any) =>
  action(ManagerAccessTypes.FETCH_ALL_DASHBOARDS_SUCCESS, {
    data,
  });

export const fetchAllDashboardsFailure = () =>
  action(ManagerAccessTypes.FETCH_ALL_DASHBOARDS_FAILURE);

export const fetchDashboardsAssociatedWithManagerRequest = (params: any) => {
  return action(
    ManagerAccessTypes.FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_REQUEST,
    params,
  );
};

export const fetchDashboardsAssociatedWithManagerSuccess = (data: any) =>
  action(ManagerAccessTypes.FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_SUCCESS, {
    data,
  });

export const fetchDashboardsAssociatedWithManagerFailure = () =>
  action(ManagerAccessTypes.FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_FAILURE);

export const associateManagersToDashboardRequest = (params: any) => {
  return action(
    ManagerAccessTypes.ASSOCIATE_MANAGERS_TO_DASHBOARD_REQUEST,
    params,
  );
};

export const associateManagersToDashboardSuccess = (data: any) =>
  action(ManagerAccessTypes.ASSOCIATE_MANAGERS_TO_DASHBOARD_SUCCESS, {
    data,
  });

export const associateManagersToDashboardFailure = () =>
  action(ManagerAccessTypes.ASSOCIATE_MANAGERS_TO_DASHBOARD_FAILURE);

export const fetchManagersAssociatedWithDashboardRequest = (params: any) => {
  return action(
    ManagerAccessTypes.FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_REQUEST,
    params,
  );
};

export const fetchManagersAssociatedWithDashboardSuccess = (data: any) => {
  return action(
    ManagerAccessTypes.FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_SUCCESS,
    {
      data,
    },
  );
};

export const fetchManagersAssociatedWithDashboardFailure = () =>
  action(ManagerAccessTypes.FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_FAILURE);

// Sagas
export function* fetchAllDashboards(): any {
  try {
    const response = yield call(fetchAllDashboardsService);
    yield put(fetchAllDashboardsSuccess(response.data));
  } catch (err) {
    yield put(fetchAllDashboardsFailure());
  }
}

export function* fetchDashboardsAssociatedWithManager(action: AnyAction): any {
  try {
    const response = yield call(
      fetchDashboardsAssociatedWithManagerService,
      action.payload,
    );
    yield put(fetchDashboardsAssociatedWithManagerSuccess(response.data));
  } catch (err) {
    yield put(fetchDashboardsAssociatedWithManagerFailure());
  }
}

export function* associateManagersToDashboard(action: AnyAction): any {
  try {
    const response = yield call(
      associateManagersToDashboardService,
      action.payload,
    );
    yield put(associateManagersToDashboardSuccess(response.data));
  } catch (err) {
    yield put(associateManagersToDashboardFailure());
  }
}

export function* fetchManagersAssociatedWithDashboard(action: AnyAction): any {
  try {
    const response = yield call(
      fetchManagersAssociatedWithDashboardService,
      action.payload,
    );
    yield put(fetchManagersAssociatedWithDashboardSuccess(response.data));
  } catch (err) {
    yield put(fetchManagersAssociatedWithDashboardFailure());
  }
}

// Selectors
export function getDashboards(state: ApplicationState) {
  return state.get('managerAccess');
}

export function allDashboardsListSelector(state: ApplicationState) {
  return state.getIn(['managerAccess', 'allDashboardsList']);
}

export function dashboardsAssociatedWithManagerListSelector(
  state: ApplicationState,
) {
  return state.getIn(['managerAccess', 'dashboardsAssociatedWithManagerList']);
}

export function managerListSelector(state: ApplicationState) {
  return state.getIn(['managerAccess', 'managersList']);
}

export const getAllDashboards = createSelector(
  allDashboardsListSelector,
  (allDashboardsList) => allDashboardsList || [],
);

export const getDashboardsAssociatedWithManager = createSelector(
  dashboardsAssociatedWithManagerListSelector,
  (dashboardsAssociatedWithManagerList) =>
    dashboardsAssociatedWithManagerList || [],
);

export const getManagersAssociatedWithDashboard = createSelector(
  managerListSelector,
  (managersList) => managersList || [],
);

export const getIsLoadingDashboardsList = createSelector(
  getDashboards,
  (dashboards) => dashboards.get('loading'),
);

export const getIsLoadingAssociateManagersToDashboard = createSelector(
  getDashboards,
  (dashboards) => dashboards.get('isLoadingAssociateManagersToDashboard'),
);

// Initial reducer state
export const INITIAL_STATE: ManagerAccessState = fromJS({
  allDashboardsList: [],
  dashboardsAssociatedWithManagerList: [],
  managersList: [],
  error: false,
  loading: false,
});

// Reducer
const reducer: Reducer<ManagerAccessState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ManagerAccessTypes.FETCH_ALL_DASHBOARDS_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ManagerAccessTypes.FETCH_ALL_DASHBOARDS_SUCCESS:
      return state.withMutations((prevState) => {
        return prevState
          .set('loading', false)
          .set('error', false)
          .set('allDashboardsList', action.payload.data.results);
      });

    case ManagerAccessTypes.FETCH_ALL_DASHBOARDS_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ManagerAccessTypes.FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ManagerAccessTypes.FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_SUCCESS:
      return state.withMutations((prevState) => {
        return prevState
          .set('loading', false)
          .set('error', false)
          .set(
            'dashboardsAssociatedWithManagerList',
            action.payload.data.results,
          );
      });

    case ManagerAccessTypes.FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ManagerAccessTypes.ASSOCIATE_MANAGERS_TO_DASHBOARD_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingAssociateManagersToDashboard', true),
      );

    case ManagerAccessTypes.ASSOCIATE_MANAGERS_TO_DASHBOARD_SUCCESS:
      return state.withMutations((prevState) => {
        return prevState
          .set('isLoadingAssociateManagersToDashboard', false)
          .set('error', false)
          .set('managersList', action.payload.data.users);
      });

    case ManagerAccessTypes.ASSOCIATE_MANAGERS_TO_DASHBOARD_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAssociateManagersToDashboard', false)
          .set('error', true),
      );

    case ManagerAccessTypes.FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ManagerAccessTypes.FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_SUCCESS:
      return state.withMutations((prevState) => {
        return prevState
          .set('loading', false)
          .set('error', false)
          .set('managersList', action.payload.data.users);
      });

    case ManagerAccessTypes.FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
