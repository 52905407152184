import {
  PublicApiRequest,
  PublicMonitoringRequest,
} from 'store/services/publicApiInterfaces';
import {MassiveRegistrationTypes} from 'utils/types/massiveRegistration';
import api, {PUBLIC_API_TOKEN, PUBLIC_API_URL} from './api';

const publicApiParams = {
  api_token: PUBLIC_API_TOKEN,
  baseURL: PUBLIC_API_URL,
  withCredentials: false,
};

const urlDictionary: {[Key in MassiveRegistrationTypes]: string} = {
  alunos: '/students/',
  clausulas: '/contract_clauses/',
  completo: '/school_groups/',
  contratos: '/contracts/',
  escolas: '/schools/',
  professores: '/teachers/',
  turmas: '/school_groups/',
  coordenadores: '/coordinators/',
};

export const createPublicService = (data: PublicApiRequest) =>
  api(urlDictionary[data.registrationType], {
    method: 'post',
    data,
    ...publicApiParams,
  });

export const createPublicMonitoringService = (data: PublicMonitoringRequest) =>
  api(`/monitoring/`, {
    method: 'post',
    data,
    ...publicApiParams,
  });

export const loginPublicService = (param: string) =>
  api(`/redirect/?username=${param}`, {
    method: 'get',
    ...publicApiParams,
  });
