import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchSchoolNetworksService} from 'store/services/schoolNetworksService';
import {action} from 'typesafe-actions';

// Actions types
export enum SchoolNetworksTypes {
  FETCH_REQUEST = '@schoolNetworks/FETCH_REQUEST',
  FETCH_SUCCESS = '@schoolNetworks/FETCH_SUCCESS',
  FETCH_FAILURE = '@schoolNetworks/FETCH_FAILURE',
}

// Data types
export interface SchoolNetwork {
  id: string | number;
  name: string;
}

// State type
export interface SchoolNetworksState extends Map<string, any> {
  readonly data: List<ImmutableMap<SchoolNetwork>>;
  readonly dataCount: number;
  readonly loading: boolean;
  readonly error: boolean;
}

// Data types
export interface SchoolNetwork {
  id: string | number;
  name: string;
}

export interface FetchSchoolNetworksParams extends Utils.GetParams {
  name?: string;
  active?: number;
}
// Fetch actions
export const fetchSchoolNetworksRequest = (
  params?: FetchSchoolNetworksParams,
) => action(SchoolNetworksTypes.FETCH_REQUEST, params);

export const fetchSchoolNetworksSuccess = (
  data: List<ImmutableMap<SchoolNetwork>>,
) => action(SchoolNetworksTypes.FETCH_SUCCESS, {data});

export const fetchSchoolNetworksFailure = () =>
  action(SchoolNetworksTypes.FETCH_FAILURE);

// Sagas
export function* fetchSchoolNetworks(action: AnyAction): any {
  try {
    const response = yield call(fetchSchoolNetworksService, action.payload);
    yield put(fetchSchoolNetworksSuccess(response.data));
  } catch (err) {
    yield put(fetchSchoolNetworksFailure());
  }
}

// Initial state
export const INITIAL_STATE: SchoolNetworksState = fromJS({
  data: fromJS([]),
  dataCount: 0,
  loading: false,
  error: false,
});

// Selectors
const schoolNetworksSelector = (state: ApplicationState) =>
  state.get('schoolNetworks');

const schoolNetworksDataSelector = (state: ApplicationState) =>
  state.getIn(['schoolNetworks', 'data']);

export const getSchoolNetworksTableData = createSelector(
  schoolNetworksDataSelector,
  (schoolNetworks) => {
    return schoolNetworks
      ? schoolNetworks.map((schoolNetwork: ImmutableMap<SchoolNetwork>) =>
          fromJS({
            id: schoolNetwork.get('id'),
            name: schoolNetwork.get('name'),
          }),
        )
      : fromJS([]);
  },
);

export const getSchoolsFromNetwork = createSelector(
  schoolNetworksDataSelector,
  (schoolNetworks) => {
    return schoolNetworks;
  },
);

export const getSchoolNetworksCount = createSelector(
  schoolNetworksSelector,
  (schoolNetworks) => schoolNetworks.get('dataCount'),
);

export const isSchoolNetworksLoading = createSelector(
  schoolNetworksSelector,
  (schoolNetworks) => schoolNetworks.get('loading'),
);

export const getSchoolNetworkOptions = createSelector(
  schoolNetworksDataSelector,
  (schoolNetworks) => {
    return schoolNetworks
      .map((group: any) => ({
        label: group.get('name'),
        value: group.get('id'),
      }))
      .toJS();
  },
);

// Reducer
export const reducer: Reducer<SchoolNetworksState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case SchoolNetworksTypes.FETCH_REQUEST:
      return state.withMutations((prevState: SchoolNetworksState) =>
        prevState.set('error', false).set('loading', true),
      );

    case SchoolNetworksTypes.FETCH_SUCCESS:
      return state.withMutations((prevState: SchoolNetworksState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case SchoolNetworksTypes.FETCH_FAILURE:
      return state.withMutations((prevState: SchoolNetworksState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    default:
      return state;
  }
};

export default reducer;
