import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import React from 'react';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  fetchSchoolGroupsService,
  updateSchoolGroupsService,
} from 'store/services/schoolGroupsService';
import {action} from 'typesafe-actions';
import {generateSchoolGroupOptions} from 'utils/functions/generateSchoolGroupOptions';
import {StoreStatus} from 'utils/types/store';

// Actions types
export enum SchoolsGroupsTypes {
  FETCH_REQUEST = '@schoolGroups/FETCH_REQUEST',
  FETCH_SUCCESS = '@schoolGroups/FETCH_SUCCESS',
  FETCH_FAILURE = '@schoolGroups/FETCH_FAILURE',

  UPDATE_REQUEST = '@schoolGroups/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@schoolGroups/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@schoolGroups/UPDATE_FAILURE',
}

// State type
export interface SchoolGroupsState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.SchoolGroupsAdmin>>;
  readonly loading: boolean;
  readonly error: boolean;
  readonly updateSchoolGroupsRequestStatus: StoreStatus;
}

interface FetchGroupsRequestParamsProps extends Utils.GetParams {
  network_ids?: (number | string)[];
  school_grade_ids?: (number | string)[];
  school_ids?: (number | string)[];
  active?: number;
}

// Fetch actions
export const fetchGroupsRequest = (params: FetchGroupsRequestParamsProps) =>
  action(SchoolsGroupsTypes.FETCH_REQUEST, params);

export const fetchGroupsSuccess = (
  data: List<ImmutableMap<AdminApi.SchoolGroupsAdmin>>,
) => action(SchoolsGroupsTypes.FETCH_SUCCESS, {data});

export const fetchGroupsFailure = () =>
  action(SchoolsGroupsTypes.FETCH_FAILURE);

// Update actions
export const updateGroupsRequest: any = (data: any) =>
  action(SchoolsGroupsTypes.UPDATE_REQUEST, data);

export const updateGroupsSuccess = (
  data: List<ImmutableMap<AdminApi.SchoolGroupsAdmin>>,
) => action(SchoolsGroupsTypes.UPDATE_SUCCESS, {data});

export const updateGroupsFailure = () =>
  action(SchoolsGroupsTypes.UPDATE_FAILURE);

// Sagas
export function* fetchGroups(action: AnyAction): Generator {
  try {
    const response: any = yield call(fetchSchoolGroupsService, action.payload);
    yield put(fetchGroupsSuccess(response.data));
  } catch (err) {
    yield put(fetchGroupsFailure());
  }
}

export function* updateGroups(action: AnyAction): Generator {
  try {
    const response: any = yield call(updateSchoolGroupsService, action.payload);
    yield put(updateGroupsSuccess(response.data));
  } catch (err) {
    yield put(updateGroupsFailure());
  }
}

// Initial state
export const INITIAL_STATE: SchoolGroupsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  updateSchoolGroupsRequestStatus: {
    fulfilled: false,
    error: false,
    loading: false,
    posting: false,
  },
});

// Selectors
export function schoolsGroupsSelector(state: ApplicationState) {
  return state.getIn(['schoolGroups', 'data']);
}

export const getIsLoadingSchoolGroupList = (state: ApplicationState) =>
  state.getIn(['schoolGroups', 'loading']);

export function selectedGradesSelector(state: ApplicationState) {
  const grades = state.getIn(['form', 'LearningPath', 'values', 'grades']);

  return grades?.map((grade: any) => grade.get('value'));
}
const schoolGroupsSelector = (state: ApplicationState) =>
  state.get('schoolGroups');

export const schoolGroupsDataSelector = (state: ApplicationState) => {
  return state.getIn(['schoolGroups', 'data']) || fromJS([]);
};

export const getUpdateSchoolGroupRequestStatus = (state: ApplicationState) =>
  state.getIn(['schoolGroups', 'updateSchoolGroupsRequestStatus']).toJS();

export const getSchoolGroupsGradesList = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) =>
    schoolGroups.map((group: ImmutableMap<AdminApi.SchoolGroupsAdmin>) =>
      fromJS({
        label: group.get('school_grade_name'),
        value: group.get('school_grade'),
      }),
    ),
);

export const getSchoolGroupsOptions = createSelector(
  schoolGroupsDataSelector,
  (groups) => generateSchoolGroupOptions(groups),
);

export const getSchoolGroupsOptionsWithoutPre = createSelector(
  schoolGroupsDataSelector,
  (groups) => {
    return generateSchoolGroupOptions(
      groups?.filter((group: any) => !group.get('is_pre')),
    );
  },
);

export const getSchoolGroupsData = createSelector(
  schoolGroupsDataSelector,
  (groups) => groups,
);

export const getSchoolGroupsList = createSelector(
  schoolGroupsDataSelector,
  (state) => state,
);
export const getSchoolGroupsListCount = createSelector(
  schoolGroupsSelector,
  (schoolGroups) => schoolGroups.get('dataCount'),
);
export const schoolsDataSelector = (state: ApplicationState) =>
  state.getIn(['schools', 'data']);

export const schoolGradesSelector = (state: ApplicationState) =>
  state.getIn(['schoolGrades', 'data']);
export const getIsLoadingSchoolGroups = createSelector(
  schoolGroupsSelector,
  (schoolGroups) => schoolGroups.get('loading'),
);

export const getSchoolFromGroup = (
  schools: List<ImmutableMap<AdminApi.Schools>>,
  group: ImmutableMap<AdminApi.SchoolGroupsAdmin>,
): ImmutableMap<AdminApi.Schools> =>
  schools.find((school: any) => school.get('id') === group.get('school')) ||
  fromJS({});

export const getGradeFromGroup = (
  grades: List<ImmutableMap<AdminApi.SchoolGrades>>,
  group: ImmutableMap<AdminApi.SchoolGroupsAdmin>,
): ImmutableMap<AdminApi.SchoolGrades> =>
  grades.find((grade: any) => grade.get('id') === group.get('school_grade')) ||
  fromJS({});

export const getSchoolGroupsTableData = createSelector(
  schoolGroupsDataSelector,
  schoolsDataSelector,
  schoolGradesSelector,
  (schoolGroups, schools: List<ImmutableMap<AdminApi.Schools>>, grades) => {
    if (!schools) return fromJS([]);

    const schoolGroupsList = schoolGroups
      ? schoolGroups.map(
          (schoolGroup: ImmutableMap<AdminApi.SchoolGroupsAdmin>) => {
            const schoolGroupId = schoolGroup.get('id');
            return fromJS({
              id:
                process.env.NODE_ENV === 'test' ? (
                  schoolGroupId
                ) : (
                  <a
                    href={`${process.env.REACT_APP_ADMIN_API_URL}/compositions/schoolgroup/${schoolGroupId}/change`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {schoolGroupId}
                  </a>
                ),
              long_name: schoolGroup.get('long_name'),
              school_year: schoolGroup.get('school_year'),
              access_code: schoolGroup.get('access_code') ?? '-',
              school:
                getSchoolFromGroup(schools, schoolGroup).get('long_name') ??
                '-',
              grade: getGradeFromGroup(grades, schoolGroup).get('name') ?? '-',
              has_reviewer: schoolGroup.get('has_reviewer') ? 'SIM' : 'NÃO',
              is_pre: schoolGroup.get('is_pre') ? 'SIM' : 'NÃO',
            });
          },
        )
      : fromJS([]);

    return schoolGroupsList;
  },
);
/**
 * Reducer
 */

// Reducer
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SchoolsGroupsTypes.FETCH_REQUEST:
      return state.withMutations((prevState: SchoolGroupsState) =>
        prevState.set('loading', true).set('error', false),
      );
    case SchoolsGroupsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState: SchoolGroupsState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );
    case SchoolsGroupsTypes.FETCH_FAILURE:
      return state.withMutations((prevState: SchoolGroupsState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([])),
      );

    case SchoolsGroupsTypes.UPDATE_REQUEST:
      return state.withMutations((prevState: SchoolGroupsState) =>
        prevState
          .set('loading', true)
          .set('error', false)
          .set(
            'updateSchoolGroupsRequestStatus',
            fromJS({
              fulfilled: false,
              error: false,
              loading: false,
              posting: true,
            }),
          ),
      );
    case SchoolsGroupsTypes.UPDATE_SUCCESS:
      return state.withMutations((prevState: SchoolGroupsState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set(
            'updateSchoolGroupsRequestStatus',
            fromJS({
              fulfilled: true,
              error: false,
              loading: false,
              posting: false,
            }),
          ),
      );
    case SchoolsGroupsTypes.UPDATE_FAILURE:
      return state.withMutations((prevState: SchoolGroupsState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set(
            'updateSchoolGroupsRequestStatus',
            fromJS({
              fulfilled: false,
              error: true,
              loading: false,
              posting: false,
            }),
          ),
      );
    default:
      return state;
  }
};

export default reducer;
