import {Utils} from '@letrustech/letrus-api-interfaces';
import api from './api';

export const fetchContractInstallmentsService = (params?: Utils.GetParams) =>
  api('/admin_api/v1/contract_installments/', {params});

export const updateContractInstallmentService = (installment: any) =>
  api(`/admin_api/v1/contract_installments/${installment.id}/`, {
    method: 'patch',
    data: {...installment},
  });

export const deleteContractInstallmentService = (installmentId: number) =>
  api(`/admin_api/v1/contract_installments/${installmentId}/`, {
    method: 'delete',
  });

export const createContractInstallmentService = (data: any) =>
  api(`/admin_api/v1/contract_installments/`, {method: 'post', data});
