import {Utils} from '@letrustech/letrus-api-interfaces';
import api from './api';

export interface SchoolGroupsParams extends Utils.GetParams {
  active?: 1;
  ids?: number[] | string[];
  networks?: number[];
  basic?: 1;
}

export const fetchSchoolsService = (params: SchoolGroupsParams) =>
  api(`/admin_api/v1/schools/`, {params});

export const createSchoolsService = (data: any) =>
  api(`/admin_api/v1/schools/`, {method: 'post', data});

export const updateSchoolsService = (data: any) =>
  api(`/admin_api/v1/schools/${data.id}/`, {method: 'patch', data});
