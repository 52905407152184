import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createContractInstallmentService,
  deleteContractInstallmentService,
  fetchContractInstallmentsService,
  updateContractInstallmentService,
} from 'store/services/contractInstallmentsService';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';

// Actions types
export enum ContractInstallmentsTypes {
  FETCH_REQUEST = '@contractsInstallments/FETCH_REQUEST',
  FETCH_SUCCESS = '@contractsInstallments/FETCH_SUCCESS',
  FETCH_FAILURE = '@contractsInstallments/FETCH_FAILURE',

  UPDATE_REQUEST = '@contractsInstallments/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@contractsInstallments/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@contractsInstallments/UPDATE_FAILURE',

  DELETE_REQUEST = '@contractsInstallments/DELETE_REQUEST',
  DELETE_SUCCESS = '@contractsInstallments/DELETE_SUCCESS',
  DELETE_FAILURE = '@contractsInstallments/DELETE_FAILURE',

  CREATE_REQUEST = '@contractsInstallments/CREATE_REQUEST',
  CREATE_SUCCESS = '@contractsInstallments/CREATE_SUCCESS',
  CREATE_FAILURE = '@contractsInstallments/CREATE_FAILURE',
}

export interface ContractInstallmentsProps {
  readonly id: number;
  order: number;
  value: number;
  expire_date: string;
  contract: number;
}

export interface ContractInstallmentRequestData {
  order: number;
  value: number;
  expire_date?: string;
  contract: number;
}

// State type
export interface ContractInstallmentsState extends Map<string, any> {
  readonly data: List<ImmutableMap<ContractInstallmentsProps>>;
  readonly loading: boolean;
  readonly error: boolean;
  readonly installmentRequestStatus: StoreStatus;
}

// Fetch actions
export const fetchContractInstallmentsRequest = () =>
  action(ContractInstallmentsTypes.FETCH_REQUEST);

export const fetchContractInstallmentsSuccess = (
  data: List<ImmutableMap<any>>,
) => action(ContractInstallmentsTypes.FETCH_SUCCESS, {data});

export const fetchContractInstallmentsFailure = () =>
  action(ContractInstallmentsTypes.FETCH_FAILURE);

export const updateContractInstallmentRequest = (
  data: ContractInstallmentRequestData,
) => action(ContractInstallmentsTypes.UPDATE_REQUEST, {data});

export const updateContractInstallmentSuccess = (
  data: List<ImmutableMap<ContractInstallmentsProps>>,
) => action(ContractInstallmentsTypes.UPDATE_SUCCESS, {data});

export const updateContractInstallmentFailure = () =>
  action(ContractInstallmentsTypes.UPDATE_FAILURE);

export const deleteContractInstallmentRequest = (contactId: number) =>
  action(ContractInstallmentsTypes.DELETE_REQUEST, contactId);

export const deleteContractInstallmentSuccess = (
  data: List<ImmutableMap<ContractInstallmentsProps>>,
) => action(ContractInstallmentsTypes.DELETE_SUCCESS, {data});

export const deleteContractInstallmentFailure = () =>
  action(ContractInstallmentsTypes.DELETE_FAILURE);

export const createContractInstallmentRequest = (
  data: ContractInstallmentRequestData,
) => action(ContractInstallmentsTypes.CREATE_REQUEST, {data});

export const createContractInstallmentSuccess = (
  data: List<ImmutableMap<ContractInstallmentsProps>>,
) => action(ContractInstallmentsTypes.CREATE_SUCCESS, {data});

export const createContractInstallmentFailure = () =>
  action(ContractInstallmentsTypes.CREATE_FAILURE);

// Sagas
export function* fetchContractInstallments(): any {
  try {
    const response = yield call(fetchContractInstallmentsService);
    yield put(fetchContractInstallmentsSuccess(response.data));
  } catch (err) {
    yield put(fetchContractInstallmentsFailure());
  }
}

export function* updateContractInstallment(action: AnyAction): any {
  try {
    const response = yield call(
      updateContractInstallmentService,
      action.payload.data,
    );
    yield put(updateContractInstallmentSuccess(response.data));
  } catch (err) {
    yield put(updateContractInstallmentFailure());
  }
}

export function* deleteContractInstallment(action: AnyAction): any {
  try {
    const response = yield call(
      deleteContractInstallmentService,
      action.payload,
    );
    yield put(deleteContractInstallmentSuccess(response.data));
  } catch (err) {
    yield put(deleteContractInstallmentFailure());
  }
}

export function* createContractInstallment(action: AnyAction): any {
  try {
    const response = yield call(
      createContractInstallmentService,
      action.payload.data,
    );
    yield put(createContractInstallmentSuccess(response.data));
  } catch (err) {
    yield put(createContractInstallmentFailure());
  }
}

// Selectors
export const contractInstallmentsDataSelector = (state: ApplicationState) =>
  state.getIn(['contractInstallments', 'data']);

export const contractInstallmentsSelector = (state: ApplicationState) =>
  state.get('contractInstallments');

export const getContractInstallments = createSelector(
  contractInstallmentsDataSelector,
  (contractInstallments) => contractInstallments,
);

export const isLoadingInstallments = createSelector(
  contractInstallmentsSelector,
  (contractInstallments) => contractInstallments.get('loading'),
);

export const getInstallmentRequestStatus = createSelector(
  contractInstallmentsSelector,
  (contractInstallments) =>
    contractInstallments.get('installmentRequestStatus'),
);

// Initial reducer state
export const INITIAL_STATE: ContractInstallmentsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  installmentRequestStatus: {
    loading: false,
    fulfilled: false,
    error: false,
    posting: false,
  },
});

// Reducer
const reducer: Reducer<ContractInstallmentsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ContractInstallmentsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ContractInstallmentsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case ContractInstallmentsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );
    case ContractInstallmentsTypes.DELETE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ContractInstallmentsTypes.DELETE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case ContractInstallmentsTypes.DELETE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ContractInstallmentsTypes.CREATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('installmentRequestStatus', {
          loading: false,
          fulfilled: false,
          error: false,
          posting: true,
        }),
      );

    case ContractInstallmentsTypes.CREATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('installmentRequestStatus', {
            loading: false,
            fulfilled: true,
            error: false,
            posting: false,
          }),
      );

    case ContractInstallmentsTypes.CREATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('installmentRequestStatus', {
            loading: false,
            fulfilled: false,
            error: true,
            posting: false,
          }),
      );

    case ContractInstallmentsTypes.UPDATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('installmentRequestStatus', {
          loading: false,
          fulfilled: false,
          error: false,
          posting: true,
        }),
      );

    case ContractInstallmentsTypes.UPDATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('installmentRequestStatus', {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        }),
      );

    case ContractInstallmentsTypes.UPDATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('installmentRequestStatus', {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        }),
      );
    default:
      return state;
  }
};

export default reducer;
