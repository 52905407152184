import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put, select} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  fetchMassiveRegistrationProgressService,
  fetchMassiveRegistrationQueueProgressService,
  massiveRegistrationService,
  MassiveResgistrationServices,
} from 'store/services/massiveRegistrationApi';
import {MassiveRegistrationInterface} from 'store/services/massiveRegistrationInterface';
import {action} from 'typesafe-actions';
import {setCookie} from 'utils/cookies';

// Actions type
export enum MassiveRegistrationTypes {
  MASSIVE_REGISTRATION_REQUEST = '@publicApi/MASSIVE_REGISTRATION_REQUEST',
  MASSIVE_REGISTRATION_SUCCESS = '@publicApi/MASSIVE_REGISTRATION_SUCCESS',
  MASSIVE_REGISTRATION_FAILURE = '@publicApi/MASSIVE_REGISTRATION_FAILURE',

  FETCH_MASSIVE_REGISTRATION_PROGRESS_REQUEST = '@publicApi/FETCH_MASSIVE_REGISTRATION_PROGRESS_REQUEST',
  FETCH_MASSIVE_REGISTRATION_PROGRESS_SUCCESS = '@publicApi/FETCH_MASSIVE_REGISTRATION_PROGRESS_SUCCESS',
  FETCH_MASSIVE_REGISTRATION_PROGRESS_FAILURE = '@publicApi/FETCH_MASSIVE_REGISTRATION_PROGRESS_FAILURE',

  FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_REQUEST = '@publicApi/FETCH_MASSIVE_REGISTRATION_QUEUE__PROGRESS_REQUEST',
  FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_SUCCESS = '@publicApi/FETCH_MASSIVE_REGISTRATION_QUEUE__PROGRESS_SUCCESS',
  FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_FAILURE = '@publicApi/FETCH_MASSIVE_REGISTRATION_QUEUE__PROGRESS_FAILURE',

  RESET_MASSIVE_REGISTRATION_REQUEST = '@publicApi/RESET_MASSIVE_REGISTRATION_REQUEST',
}

// State type
export interface MassiveRegistrationState extends Map<string, any> {
  readonly latestRequestId: string;
  readonly requestsIds: string[];
  readonly progressData: MassiveRegisterProgressResponsePayload | undefined;
  readonly queueProgressData:
    | MassiveRegisterProgressResponsePayload[]
    | undefined;
  readonly isLoadingMassiveRegistration: boolean;
  readonly isFetchingMassiveRegistrationProgressData: boolean;
  readonly massiveRegistrationProgressDataError: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}

export interface MassiveRegisterProgressResponsePayload {
  service: string;
  output_data: {
    presigned_url?: string;
    errors?: string;
    missing_columns?: string[];
  };
  input_data: {
    file_link: string;
    item_count: number;
    processed_count: number;
    file_name: string;
  };
  line: number;
  request_id: string;
  status: string;
  ttl: number;
}

// Data types

/**
 * Actions
 */

export const massiveRegistrationRequest = (
  data: MassiveRegistrationInterface,
) => action(MassiveRegistrationTypes.MASSIVE_REGISTRATION_REQUEST, data);

export const massiveRegistrationSuccess = (data: {
  message: string;
  request_id: string;
}) => action(MassiveRegistrationTypes.MASSIVE_REGISTRATION_SUCCESS, {data});

export const massiveRegistrationFailure = () =>
  action(MassiveRegistrationTypes.MASSIVE_REGISTRATION_FAILURE);

export const fetchMassiveRegistrationProgressRequest = (request_id: string) =>
  action(
    MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_PROGRESS_REQUEST,
    request_id,
  );

export const fetchMassiveRegistrationProgressSuccess = (
  data: MassiveRegisterProgressResponsePayload,
) =>
  action(
    MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_PROGRESS_SUCCESS,
    data,
  );

export const fetchMassiveRegistrationProgressFailure = () =>
  action(MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_PROGRESS_FAILURE);

export const resetMassiveRegistrationRequest = () =>
  action(MassiveRegistrationTypes.RESET_MASSIVE_REGISTRATION_REQUEST);

export const fetchMassiveRegistrationQueueProgressSuccess = (data: any) =>
  action(
    MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_SUCCESS,
    data,
  );

export const fetchMassiveRegistrationQueueProgressFailure = () =>
  action(
    MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_FAILURE,
  );

export const fetchMassiveRegistrationQueueProgressRequest = (
  service?: MassiveResgistrationServices,
  email?: string,
) =>
  action(
    MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_REQUEST,
    {service, email},
  );

// Sagas
export function* massiveRegistration(action: AnyAction): any {
  try {
    const response = yield call(massiveRegistrationService, action.payload);

    const requestsIdsPreviousState: string[] = yield select(
      getMassiveRegistrationRequestsIds,
    );

    yield call(setCookie, {
      name: `${action.payload.registrationType}-massiveRegistrationRequestId`,
      value: `${response.data.request_id},${requestsIdsPreviousState.join(
        ', ',
      )}`,
      expires: 0.5,
    });
    yield put(massiveRegistrationSuccess(response.data));
  } catch (err) {
    yield put(massiveRegistrationFailure());
  }
}

export function* fetchMassiveRegistrationProgress(action: AnyAction): any {
  try {
    const response = yield call(
      fetchMassiveRegistrationProgressService,
      action.payload,
    );

    yield put(fetchMassiveRegistrationProgressSuccess(response.data));
  } catch (err) {
    yield put(fetchMassiveRegistrationProgressFailure());
  }
}

export function* fetchMassiveRegistrationQueueProgress(action: AnyAction): any {
  try {
    const response = yield call(
      fetchMassiveRegistrationQueueProgressService,
      action.payload.service,
      action.payload.email,
    );

    yield put(fetchMassiveRegistrationQueueProgressSuccess(response.data));
  } catch (err) {
    yield put(fetchMassiveRegistrationQueueProgressFailure());
  }
}

// Initial state
export const INITIAL_STATE: MassiveRegistrationState = fromJS({
  latestRequestId: null,
  requestsIds: [],
  error: false,
  loading: false,
  isLoadingMassiveRegistration: false,
  isFetchingMassiveRegistrationProgressData: false,
  progressData: undefined,
  massiveRegistrationProgressDataError: false,
  queueProgressData: undefined,
});

/**
 * Selectors
 */
const massiveRegistrationSelector = (state: ApplicationState) =>
  state.get('massiveRegistration');

export const getMassiveRegistrationRequestId = createSelector(
  massiveRegistrationSelector,
  (massiveRegistration) => massiveRegistration.get('latestRequestId'),
);

export const getMassiveRegistrationRequestsIds = createSelector(
  massiveRegistrationSelector,
  (massiveRegistration) => massiveRegistration.get('requestsIds'),
);

export const getMassiveRegistrationQueueProgressData = createSelector(
  massiveRegistrationSelector,
  (massiveRegistration) => massiveRegistration.get('queueProgressData'),
);

export const getMassiveRegistrationProgressData = createSelector(
  massiveRegistrationSelector,
  (massiveRegistration) => massiveRegistration.get('progressData'),
);

export const getIsLoadingMassiveRegistration = createSelector(
  massiveRegistrationSelector,
  (massiveRegistration) =>
    massiveRegistration.get('isLoadingMassiveRegistration'),
);

export const isFetchingMassiveRegistrationProgressData = createSelector(
  massiveRegistrationSelector,
  (massiveRegistration) =>
    massiveRegistration.get('isFetchingMassiveRegistrationProgressData'),
);

export const getMassiveRegistrationError = createSelector(
  massiveRegistrationSelector,
  (massiveRegistration) => massiveRegistration.get('error'),
);

export const getMassiveRegistrationProgressDataError = createSelector(
  massiveRegistrationSelector,
  (massiveRegistration) =>
    massiveRegistration.get('massiveRegistrationProgressDataError'),
);

// Reducer
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MassiveRegistrationTypes.MASSIVE_REGISTRATION_REQUEST:
      return state.withMutations((prevState: MassiveRegistrationState) =>
        prevState.set('isLoadingMassiveRegistration', true).set('error', false),
      );

    case MassiveRegistrationTypes.MASSIVE_REGISTRATION_SUCCESS:
      return state.withMutations((prevState: MassiveRegistrationState) =>
        prevState
          .set('isLoadingMassiveRegistration', false)
          .set('error', false)
          .set('latestRequestId', action.payload.data.request_id),
      );

    case MassiveRegistrationTypes.MASSIVE_REGISTRATION_FAILURE:
      return state.withMutations((prevState: MassiveRegistrationState) =>
        prevState.set('isLoadingMassiveRegistration', false).set('error', true),
      );

    case MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_PROGRESS_REQUEST:
      return state.withMutations((prevState: MassiveRegistrationState) =>
        prevState
          .set('isFetchingMassiveRegistrationProgressData', true)
          .set('error', false),
      );

    case MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_PROGRESS_SUCCESS:
      return state.withMutations((prevState: MassiveRegistrationState) =>
        prevState
          .set('isFetchingMassiveRegistrationProgressData', false)
          .set('error', false)
          .set('progressData', action.payload),
      );

    case MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_PROGRESS_FAILURE:
      return state.withMutations((prevState: MassiveRegistrationState) =>
        prevState
          .set('isFetchingMassiveRegistrationProgressData', false)
          .set('massiveRegistrationProgressDataError', true),
      );

    case MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_REQUEST:
      return state.withMutations((prevState: MassiveRegistrationState) =>
        prevState
          .set('isFetchingMassiveRegistrationProgressData', true)
          .set('error', false),
      );

    case MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_SUCCESS:
      return state.withMutations((prevState: MassiveRegistrationState) =>
        prevState
          .set('isFetchingMassiveRegistrationProgressData', false)
          .set('error', false)
          .set('queueProgressData', [...action.payload]),
      );

    case MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_FAILURE:
      return state.withMutations((prevState: MassiveRegistrationState) =>
        prevState
          .set('isFetchingMassiveRegistrationProgressData', false)
          .set('massiveRegistrationProgressDataError', true),
      );

    case MassiveRegistrationTypes.RESET_MASSIVE_REGISTRATION_REQUEST:
      return (state = INITIAL_STATE);

    default:
      return state;
  }
};

export default reducer;
