import * as Sentry from '@sentry/react';
import TagManager, {TagManagerArgs} from 'react-gtm-module';

export function setupTrackers(): void {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: 'GTM-M3LKWBW',
  };
  TagManager.initialize(tagManagerArgs);

  Sentry.init({
    dsn: 'https://3deeee0ecce347f3b6d4b88def46fb45@o222076.ingest.sentry.io/1544732',
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}
