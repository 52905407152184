import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createContractClausesService,
  deleteContractClausesService,
  fetchContractClausesService,
  updateContractClausesService,
} from 'store/services/clausesService';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';

// Actions types
export enum ClausesTypes {
  FETCH_REQUEST = '@contractsClauses/FETCH_REQUEST',
  FETCH_SUCCESS = '@contractsClauses/FETCH_SUCCESS',
  FETCH_FAILURE = '@contractsClauses/FETCH_FAILURE',

  UPDATE_REQUEST = '@contractsClauses/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@contractsClauses/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@contractsClauses/UPDATE_FAILURE',

  DELETE_REQUEST = '@contractsClauses/DELETE_REQUEST',
  DELETE_SUCCESS = '@contractsClauses/DELETE_SUCCESS',
  DELETE_FAILURE = '@contractsClauses/DELETE_FAILURE',

  CREATE_REQUEST = '@contractsClauses/CREATE_REQUEST',
  CREATE_SUCCESS = '@contractsClauses/CREATE_SUCCESS',
  CREATE_FAILURE = '@contractsClauses/CREATE_FAILURE',
}

export interface ContractClausesProps {
  compositions_number: number;
  contract: number;
  id: number;
  school_grade: number;
  student_number: number;
}

export interface ContractClausesRequestData {
  compositions_number: number;
  contract: number;
  school_grade: number;
  student_number: number;
}

// State type
export interface ClausesState extends Map<string, any> {
  readonly data: List<ImmutableMap<ContractClausesProps>>;
  readonly loading: boolean;
  readonly error: boolean;
  readonly clauseRequestStatus: StoreStatus;
}

// Fetch actions
export type ContractClausesRequestType = (
  data: ContractClausesRequestData,
) => AnyAction;

export const fetchContractClausesRequest = () =>
  action(ClausesTypes.FETCH_REQUEST);

export const fetchContractClausesSuccess = (data: List<ImmutableMap<any>>) =>
  action(ClausesTypes.FETCH_SUCCESS, {data});

export const fetchContractClausesFailure = () =>
  action(ClausesTypes.FETCH_FAILURE);

export const updateContractClausesRequest: ContractClausesRequestType = (
  data,
) => action(ClausesTypes.UPDATE_REQUEST, {data});

export const updateContractClausesSuccess = (
  data: List<ImmutableMap<ContractClausesProps>>,
) => action(ClausesTypes.UPDATE_SUCCESS, {data});

export const updateContractClausesFailure = () =>
  action(ClausesTypes.UPDATE_FAILURE);

export const deleteContractClausesRequest: ContractClausesRequestType = (
  data,
) => action(ClausesTypes.DELETE_REQUEST, {data});

export const deleteContractClausesSuccess = (
  data: List<ImmutableMap<ContractClausesProps>>,
) => action(ClausesTypes.DELETE_SUCCESS, {data});

export const deleteContractClausesFailure = () =>
  action(ClausesTypes.DELETE_FAILURE);

export const createContractClausesRequest: ContractClausesRequestType = (
  data,
) => action(ClausesTypes.CREATE_REQUEST, {data});

export const createContractClausesSuccess = (
  data: List<ImmutableMap<ContractClausesProps>>,
) => action(ClausesTypes.CREATE_SUCCESS, {data});

export const createContractClausesFailure = () =>
  action(ClausesTypes.CREATE_FAILURE);

// Sagas
export function* fetchContractClauses(): any {
  try {
    const response = yield call(fetchContractClausesService);
    yield put(fetchContractClausesSuccess(response.data));
  } catch (err) {
    yield put(fetchContractClausesFailure());
  }
}

export function* updateContractClauses(action: AnyAction): any {
  try {
    const response = yield call(
      updateContractClausesService,
      action.payload.data,
    );
    yield put(updateContractClausesSuccess(response.data));
  } catch (err) {
    yield put(updateContractClausesFailure());
  }
}

export function* deleteContractClauses(action: AnyAction): any {
  try {
    const response = yield call(
      deleteContractClausesService,
      action.payload.data,
    );
    yield put(deleteContractClausesSuccess(response.data));
  } catch (err) {
    yield put(deleteContractClausesFailure());
  }
}

export function* createContractClauses(action: AnyAction): any {
  try {
    const response = yield call(
      createContractClausesService,
      action.payload.data,
    );
    yield put(createContractClausesSuccess(response.data));
  } catch (err) {
    yield put(createContractClausesFailure());
  }
}

// Selectors
export const clausesDataSelector = (state: ApplicationState) =>
  state.getIn(['clauses', 'data']);
export const clausesSelector = (state: ApplicationState) =>
  state.get('clauses');

export const getClauses = createSelector(
  clausesDataSelector,
  (clauses) => clauses,
);

export const isLoadingClauses = createSelector(clausesSelector, (clauses) =>
  clauses.get('loading'),
);

export const getClauseRequestStatus = createSelector(
  clausesSelector,
  (clauses) => clauses.get('clauseRequestStatus'),
);

// Initial reducer state
export const INITIAL_STATE: ClausesState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  clauseRequestStatus: {
    loading: false,
    error: false,
    posting: false,
    fulfilled: false,
  },
});

// Reducer
const reducer: Reducer<ClausesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClausesTypes.FETCH_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ClausesTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case ClausesTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );
    case ClausesTypes.DELETE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ClausesTypes.DELETE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case ClausesTypes.DELETE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ClausesTypes.CREATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('clauseRequestStatus', {
          loading: false,
          error: false,
          posting: true,
          fulfilled: false,
        }),
      );

    case ClausesTypes.CREATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('clauseRequestStatus', {
            loading: false,
            error: false,
            posting: false,
            fulfilled: true,
          }),
      );

    case ClausesTypes.CREATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('clauseRequestStatus', {
            loading: false,
            error: true,
            posting: false,
            fulfilled: false,
          }),
      );

    case ClausesTypes.UPDATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('clauseRequestStatus', {
          loading: false,
          error: false,
          posting: true,
          fulfilled: false,
        }),
      );

    case ClausesTypes.UPDATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('clauseRequestStatus', {
          loading: false,
          error: false,
          posting: false,
          fulfilled: true,
        }),
      );

    case ClausesTypes.UPDATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('clauseRequestStatus', {
          loading: false,
          error: true,
          posting: false,
          fulfilled: false,
        }),
      );
    default:
      return state;
  }
};

export default reducer;
