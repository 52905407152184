import {AxiosResponse} from 'axios';
import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  deleteLearningPathInstanceService,
  fetchLearningPathInstanceListService,
  LearningPathInstanceFilters,
} from 'store/services/learningPaths';
import {action} from 'typesafe-actions';
import {LearningPathInstance} from 'utils/types/learningPath';
import {MassiveResponse} from 'utils/types/massiveTypes';
import {PaginatedRequest} from 'utils/types/paginatedRequest';
import {StoreStatus} from 'utils/types/store';

// Action types
export enum LearningPathInstancesTypes {
  FETCH_INSTANCE_LIST_REQUEST = '@learningPathInstances/FETCH_INSTANCE_LIST_REQUEST',
  FETCH_INSTANCE_LIST_SUCCESS = '@learningPathInstances/FETCH_INSTANCE_LIST_SUCESS',
  FETCH_INSTANCE_LIST_FAILURE = '@learningPathInstances/FETCH_INSTANCE_LIST_FAILURE',

  DELETE_INSTANCE_REQUEST = '@learningPathInstances/DELETE_INSTANCE_REQUEST',
  DELETE_INSTANCE_SUCCESS = '@learningPathInstances/DELETE_INSTANCE_SUCESS',
  DELETE_INSTANCE_FAILURE = '@learningPathInstances/DELETE_INSTANCE_FAILURE',
}

// State type
export interface LearningPathInstanceState {
  readonly requestId: string;
  readonly instanceListLenght: number;
  readonly instanceList: LearningPathInstance[];
  readonly instanceListRequestStatus: StoreStatus;
  readonly deleteInstanceRequestStatus: StoreStatus;
}

// Actions
export const fetchLearningPathInstanceListRequest = (
  filters?: Partial<LearningPathInstanceFilters>,
): AnyAction =>
  action(LearningPathInstancesTypes.FETCH_INSTANCE_LIST_REQUEST, {filters});

export const fetchLearningPathInstanceListSuccess = (
  data: PaginatedRequest<LearningPathInstance[]>,
): AnyAction =>
  action(LearningPathInstancesTypes.FETCH_INSTANCE_LIST_SUCCESS, {
    data,
  });

export const fetchLearningPathInstanceListFailure = (): AnyAction =>
  action(LearningPathInstancesTypes.FETCH_INSTANCE_LIST_FAILURE);

export const deleteLearningPathInstanceRequest = (
  instanceList: number[],
): AnyAction =>
  action(LearningPathInstancesTypes.DELETE_INSTANCE_REQUEST, {instanceList});

export const deleteLearningPathInstanceSuccess = (
  requestId: string,
): AnyAction =>
  action(LearningPathInstancesTypes.DELETE_INSTANCE_SUCCESS, {requestId});

export const deleteLearningPathInstanceFailure = (): AnyAction =>
  action(LearningPathInstancesTypes.DELETE_INSTANCE_FAILURE);

// Sagas
export function* fetchLearningPathInstanceList(
  action: AnyAction,
): Generator<
  StrictEffect,
  void,
  AxiosResponse<PaginatedRequest<LearningPathInstance[]>>
> {
  try {
    const response = yield call(
      fetchLearningPathInstanceListService,
      action.payload.filters,
    );
    yield put(fetchLearningPathInstanceListSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathInstanceListFailure());
  }
}

export function* deleteLearningPathInstance(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<MassiveResponse>> {
  try {
    const response = yield call(
      deleteLearningPathInstanceService,
      action.payload.instanceList,
    );
    yield put(deleteLearningPathInstanceSuccess(response.data.request_id));
  } catch (err) {
    yield put(deleteLearningPathInstanceFailure());
  }
}

// Selectors
const learningPathInstanceSelector = (state: ApplicationState) =>
  state.get('learningPathInstance') as LearningPathInstanceState;

export const getInstanceList = createSelector(
  learningPathInstanceSelector,
  (learningPathInstanceStore) => learningPathInstanceStore.instanceList,
);

export const getInstanceListLength = createSelector(
  learningPathInstanceSelector,
  (learningPathInstanceStore) => learningPathInstanceStore.instanceListLenght,
);

export const getInstanceListRequestStatus = createSelector(
  learningPathInstanceSelector,
  (learningPathInstanceStore) =>
    learningPathInstanceStore.instanceListRequestStatus,
);

export const getDeleteInstanceRequestStatus = createSelector(
  learningPathInstanceSelector,
  (learningPathInstanceStore) =>
    learningPathInstanceStore.deleteInstanceRequestStatus,
);

export const getDeleteInstanceRequestId = createSelector(
  learningPathInstanceSelector,
  (learningPathInstanceStore) => learningPathInstanceStore.requestId,
);

// Initial state
export const INITIAL_STATE: LearningPathInstanceState = {
  instanceList: [],
  instanceListLenght: 0,
  instanceListRequestStatus: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
  },
  requestId: '',
  deleteInstanceRequestStatus: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
  },
};

// Reducer
export const reducer: Reducer<LearningPathInstanceState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case LearningPathInstancesTypes.FETCH_INSTANCE_LIST_REQUEST:
      return {
        ...state,
        instanceListRequestStatus: {
          loading: true,
          fulfilled: false,
          error: false,
          posting: false,
        },
      };

    case LearningPathInstancesTypes.FETCH_INSTANCE_LIST_SUCCESS:
      return {
        ...state,
        instanceList: action.payload.data.results,
        instanceListLenght: action.payload.data.count,
        instanceListRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case LearningPathInstancesTypes.FETCH_INSTANCE_LIST_FAILURE:
      return {
        ...state,
        instanceListRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        },
      };

    case LearningPathInstancesTypes.DELETE_INSTANCE_REQUEST:
      return {
        ...state,
        deleteInstanceRequestStatus: {
          loading: false,
          fulfilled: false,
          error: false,
          posting: true,
        },
      };

    case LearningPathInstancesTypes.DELETE_INSTANCE_SUCCESS:
      return {
        ...state,
        requestId: action.payload.requestId,
        deleteInstanceRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case LearningPathInstancesTypes.DELETE_INSTANCE_FAILURE:
      return {
        ...state,
        deleteInstanceRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;
