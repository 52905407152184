import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createMassiveTestsService,
  fetchTestTemplatesService,
  patchMassiveTestsService,
} from 'store/services/massiveTestsService';
import {fetchMonitoringService} from 'store/services/monitoring';
import {action} from 'typesafe-actions';
import {setCookie} from 'utils/cookies';
import formatToLocalDate from 'utils/date/toLocalDate';
import {StoreStatus} from 'utils/types/store';
import {MassiveInterfaceData} from '../../services/massiveTestsInterface';

// Actions types
export enum MassiveTestsTypes {
  CREATE_MASSIVE_TESTS_REQUEST = '@testTemplates/CREATE_MASSIVE_TESTS_REQUEST',
  CREATE_MASSIVE_TESTS_SUCCESS = '@testTemplates/CREATE_MASSIVE_TESTS_SUCCESS',
  CREATE_MASSIVE_TESTS_FAILURE = '@testTemplates/CREATE_MASSIVE_TESTS_FAILURE',

  FETCH_MASSIVE_TESTS_CREATE_STATUS_REQUEST = '@testTemplates/FETCH_MASSIVE_REGISTRATION_STATUS_REQUEST',
  FETCH_MASSIVE_TESTS_CREATE_STATUS_SUCCESS = '@testTemplates/FETCH_MASSIVE_REGISTRATION_STATUS_SUCCESS',
  FETCH_MASSIVE_TESTS_CREATE_STATUS_FAILURE = '@testTemplates/FETCH_MASSIVE_REGISTRATION_STATUS_FAILURE',

  FETCH_TEST_TEMPLATES_REQUEST = '@testTemplates/FETCH_TEST_TEMPLATES_REQUEST',
  FETCH_TEST_TEMPLATES_SUCCESS = '@testTemplates/FETCH_TEST_TEMPLATES_SUCCESS',
  FETCH_TEST_TEMPLATES_FAILURE = '@testTemplates/FETCH_TEST_TEMPLATES_FAILURE',

  UPDATE_TEST_REQUEST = '@testsTemplates/UPDATE_REQUEST',
  UPDATE_TEST_SUCCESS = '@testsTemplates/UPDATE_SUCCESS',
  UPDATE_TEST_FAILURE = '@testsTemplates/UPDATE_FAILURE',

  FETCH_MASSIVE_TESTS_UPDATE_STATUS_REQUEST = '@testTemplates/FETCH_MASSIVE_UPDATE_STATUS_REQUEST',
  FETCH_MASSIVE_TESTS_UPDATE_STATUS_SUCCESS = '@testTemplates/FETCH_MASSIVE_UPDATE_STATUS_SUCCESS',
  FETCH_MASSIVE_TESTS_UPDATE_STATUS_FAILURE = '@testTemplates/FETCH_MASSIVE_UPDATE_STATUS_FAILURE',
}

// State
export interface RegistrationStatusRequestSuccess {
  service: string;
  output_data: {
    presigned_url: string;
    count: number;
    ids: number[];
    errors: string[];
    status: string;
  };
  input_data: MassiveInterfaceData | UpdateMassiveTestRequestInterface;
  line: number;
  request_id: string;
  status: string;
  ttl: number;
}

export interface MassiveTestsState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.TestTemplatesGetRequest>>;
  readonly request_id_create: string;
  readonly request_id_update: string;
  readonly statusCreateRequest: RegistrationStatusRequestSuccess;
  readonly statusUpdateRequest: RegistrationStatusRequestSuccess;
  readonly dataCount: number;
  readonly loading: boolean;
  readonly error: boolean;
  readonly createTestRequestStatus: StoreStatus;
}

export type FetchTestTemplatesRequestType = (
  params?: Utils.GetParams,
) => AnyAction;

export interface UpdateMassiveTestRequestInterface {
  test_ids: number[];
  name?: string;
  template_id?: number;
  theme_id?: number;
  test_type?: string;
  is_required?: boolean;
  tags?: string;
  start_datetime?: string;
  end_datetime?: string;
  uses_write_preparation?: boolean;
}

// Actions
export const createMassiveTestRequest = (data: MassiveInterfaceData) => {
  return action(MassiveTestsTypes.CREATE_MASSIVE_TESTS_REQUEST, data);
};

export const createMassiveTestSuccess = (data: {
  message: string;
  request_id: string;
}) => action(MassiveTestsTypes.CREATE_MASSIVE_TESTS_SUCCESS, {data});

// [TODO]: define error message
export const createMassiveTestsFailure = ({payload: data}: any) =>
  action(MassiveTestsTypes.CREATE_MASSIVE_TESTS_FAILURE, {data});

export const fetchMassiveTestsCreateStatusRequest = (request_id: string) =>
  action(
    MassiveTestsTypes.FETCH_MASSIVE_TESTS_CREATE_STATUS_REQUEST,
    request_id,
  );

export const fetchMassiveTestsCreateStatusSuccess = (
  data: RegistrationStatusRequestSuccess,
) =>
  action(MassiveTestsTypes.FETCH_MASSIVE_TESTS_CREATE_STATUS_SUCCESS, {
    data,
  });

export const fetchMassiveTestsCreateStatusFailure = () =>
  action(MassiveTestsTypes.FETCH_MASSIVE_TESTS_CREATE_STATUS_FAILURE);

export const fetchMassiveTestsUpdateStatusRequest = (request_id: string) =>
  action(
    MassiveTestsTypes.FETCH_MASSIVE_TESTS_UPDATE_STATUS_REQUEST,
    request_id,
  );

export const fetchMassiveTestsUpdateStatusSuccess = (
  data: RegistrationStatusRequestSuccess,
) =>
  action(MassiveTestsTypes.FETCH_MASSIVE_TESTS_UPDATE_STATUS_SUCCESS, {
    data,
  });

export const fetchMassiveTestsUpdateStatusFailure = () =>
  action(MassiveTestsTypes.FETCH_MASSIVE_TESTS_UPDATE_STATUS_FAILURE);

export const fetchTestTemplatesRequest: FetchTestTemplatesRequestType = (
  params,
) => action(MassiveTestsTypes.FETCH_TEST_TEMPLATES_REQUEST, {params});

export const fetchTestTemplatesSuccess = (
  data: List<ImmutableMap<AdminApi.TestTemplatesGetResponse>>,
) => action(MassiveTestsTypes.FETCH_TEST_TEMPLATES_SUCCESS, {data});

export const fetchTestTemplatesFailure = () =>
  action(MassiveTestsTypes.FETCH_TEST_TEMPLATES_FAILURE);

export const updateMassiveTestsRequest = (
  data: UpdateMassiveTestRequestInterface,
): any => action(MassiveTestsTypes.UPDATE_TEST_REQUEST, data);

export const updateMassiveTestsSuccess = (data: {
  message: string;
  request_id: string;
}) => action(MassiveTestsTypes.UPDATE_TEST_SUCCESS, {data});

export const updateMassiveTestsFailure = () =>
  action(MassiveTestsTypes.UPDATE_TEST_FAILURE);

// Sagas
export function* createMassiveTests(action: AnyAction): any {
  try {
    const response = yield call(createMassiveTestsService, action.payload);
    yield call(setCookie, {
      name: `massiveTestsCreationRequestId`,
      value: response.data.request_id,
      expires: 0.5,
    });
    yield put(createMassiveTestSuccess(response.data));
  } catch (err) {
    yield put(createMassiveTestsFailure(action));
  }
}

export function* fetchMassiveTestsCreateStatus(action: AnyAction): any {
  try {
    const response = yield call(fetchMonitoringService, action.payload);
    yield put(fetchMassiveTestsCreateStatusSuccess(response.data));
  } catch (err) {
    yield put(fetchMassiveTestsCreateStatusFailure());
  }
}

export function* fetchTestTemplates(action: AnyAction): any {
  try {
    const response = yield call(fetchTestTemplatesService, action.payload);
    yield put(fetchTestTemplatesSuccess(response.data));
  } catch (err) {
    yield put(fetchTestTemplatesFailure());
  }
}

export function* updateMassiveTests(action: AnyAction): any {
  try {
    const response = yield call(patchMassiveTestsService, action.payload);
    yield call(setCookie, {
      name: `massiveTestsEditionRequestId`,
      value: response.data.request_id,
      expires: 0.5,
    });
    yield put(updateMassiveTestsSuccess(response.data));
  } catch (err) {
    yield put(updateMassiveTestsFailure());
  }
}

export function* fetchMassiveTestsUpdateStatus(action: AnyAction): any {
  try {
    const response = yield call(fetchMonitoringService, action.payload);
    yield put(fetchMassiveTestsUpdateStatusSuccess(response.data));
  } catch (err) {
    yield put(fetchMassiveTestsUpdateStatusFailure());
  }
}

// Initial state
export const INITIAL_STATE: MassiveTestsState = fromJS({
  data: fromJS([]),
  request_id_create: null,
  request_id_update: null,
  statusCreateRequest: fromJS({}),
  statusUpdateRequest: fromJS({}),
  isLoadingMassiveTestsRegistration: false,
  isLoadingMassiveTestsRegistrationStatus: false,
  massiveTestsRegistrationStatusError: false,
  error: fromJS([]),
  loading: false,
  createTestRequestStatus: {
    error: false,
    fulfilled: false,
    posting: false,
    loading: false,
  },
});

// Selectors
const massiveTestsSelector = (state: ApplicationState) => {
  return state.get('massiveTests');
};

const testTemplatesSelector = (state: ApplicationState) => {
  return state.get('testTemplates');
};

const testTemplatesDataSelector = (state: ApplicationState) => {
  return state.getIn(['testTemplates', 'data']);
};

export const getTestTemplatesList = createSelector(
  testTemplatesSelector,
  (massiveTests) => {
    return massiveTests.get('data');
  },
);

export const getTestTemplatesTableData = createSelector(
  testTemplatesDataSelector,
  (testTemplates) => {
    const testTemplatesList = testTemplates.map(
      (testTemplate: ImmutableMap<AdminApi.TestTemplates>) =>
        fromJS({
          id: testTemplate.get('id') ?? '-',
          name: testTemplate.get('name') ?? '-',
          theme_id: testTemplate.getIn(['theme', 'id']) ?? '-',
          theme_title: testTemplate.getIn(['theme', 'title']) ?? '-',
          creation_date: testTemplate.get('created')
            ? formatToLocalDate(testTemplate.get('created'))
            : '-',
          modification_date: testTemplate.get('modified')
            ? formatToLocalDate(testTemplate.get('modified'))
            : '-',
          manual_review: testTemplate.get('manual_review') ? 'Sim' : 'Não',
          timer: testTemplate.get('timer') ?? '-',
          type:
            testTemplate.get('rewriting_type') === 0 ? 'Reescrita' : 'Revisão',
          before_writing: testTemplate.get('before_writing') ?? '-',
        }),
    );

    return testTemplatesList;
  },
);

export const getTestTemplatesError = createSelector(
  testTemplatesSelector,
  (testTemplate) => {
    return testTemplate.get('error');
  },
);

export const getTestTemplatesCount = createSelector(
  testTemplatesSelector,
  (testTemplates) => testTemplates.get('dataCount'),
);

export const getIsTestTemplatesLoading = createSelector(
  testTemplatesSelector,
  (testTemplates) => {
    return testTemplates.get('loading');
  },
);

export const getTestTemplatesOptions = createSelector(
  testTemplatesDataSelector,
  (testTemplates) =>
    testTemplates.map((testTemplate: ImmutableMap<AdminApi.TestTemplates>) => ({
      name: testTemplate.get('name'),
      id: testTemplate.get('id'),
      is_rewriting: !!testTemplate.get('rewriting_type'),
    })),
);

export const getMassiveTestsCreateRequestId = createSelector(
  massiveTestsSelector,
  (state: MassiveTestsState) => state.get('request_id_create'),
);

export const getMassiveTestsUpdateRequestId = createSelector(
  massiveTestsSelector,
  (state: MassiveTestsState) => state.get('request_id_update'),
);

export const getMassiveTestsCreateStatus = createSelector(
  massiveTestsSelector,
  (state: MassiveTestsState) => state.get('statusCreateRequest'),
);

export const getMassiveTestsUpdateStatus = createSelector(
  massiveTestsSelector,
  (state: MassiveTestsState) => state.get('statusUpdateRequest'),
);

export const getCreateTestRequestStatus = createSelector(
  massiveTestsSelector,
  (state: MassiveTestsState) => state.get('createTestRequestStatus'),
);

// Reducer
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MassiveTestsTypes.CREATE_MASSIVE_TESTS_REQUEST:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState
          .set('isLoadingMassiveTestsRegistration', true)
          .set('error', fromJS([]))
          .set('createTestRequestStatus', {
            error: false,
            fulfilled: false,
            posting: true,
            loading: false,
          }),
      );
    case MassiveTestsTypes.CREATE_MASSIVE_TESTS_SUCCESS:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState
          .set('isLoadingMassiveTestsRegistration', false)
          .set('request_id_create', action.payload.data.request_id)
          .set('createTestRequestStatus', {
            error: false,
            fulfilled: true,
            posting: false,
            loading: false,
          }),
      );
    case MassiveTestsTypes.CREATE_MASSIVE_TESTS_FAILURE:
      return state.withMutations((prevState: MassiveTestsState) => {
        return prevState
          .set('isLoadingMassiveTestsRegistration', false)
          .set('error', prevState.get('error').merge(action.payload.schoolId))
          .set('createTestRequestStatus', {
            error: true,
            fulfilled: false,
            posting: false,
            loading: false,
          });
      });
    case MassiveTestsTypes.FETCH_MASSIVE_TESTS_CREATE_STATUS_REQUEST:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState
          .set('isLoadingMassiveTestsRegistrationStatus', true)
          .set('massiveTestsRegistrationStatusError', false),
      );
    case MassiveTestsTypes.FETCH_MASSIVE_TESTS_CREATE_STATUS_SUCCESS:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState
          .set('isLoadingMassiveTestsRegistrationStatus', false)
          .set('massiveTestsRegistrationStatusError', false)
          .set('statusCreateRequest', action.payload.data),
      );
    case MassiveTestsTypes.FETCH_MASSIVE_TESTS_CREATE_STATUS_FAILURE:
      return state.withMutations((prevState: MassiveTestsState) => {
        return prevState
          .set('isLoadingMassiveTestsRegistrationStatus', false)
          .set('massiveTestsRegistrationStatusError', true);
      });
    case MassiveTestsTypes.FETCH_TEST_TEMPLATES_REQUEST:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState.set('loading', true).set('error', fromJS([])),
      );
    case MassiveTestsTypes.FETCH_TEST_TEMPLATES_SUCCESS:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState
          .set('loading', false)
          .set('error', fromJS([]))
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );
    case MassiveTestsTypes.FETCH_TEST_TEMPLATES_FAILURE:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState
          .set('loading', false)
          .set('error', fromJS([]))
          .set('dataCount', 0)
          .set('data', []),
      );
    case MassiveTestsTypes.UPDATE_TEST_REQUEST:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState.set('loading', true).set('error', fromJS([])),
      );
    case MassiveTestsTypes.UPDATE_TEST_SUCCESS:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState
          .set('loading', false)
          .set('request_id_update', action.payload.data.request_id),
      );
    case MassiveTestsTypes.UPDATE_TEST_FAILURE:
      return state.withMutations((prevState: MassiveTestsState) => {
        return prevState
          .set('loading', false)
          .set('error', prevState.get('error'));
      });
    case MassiveTestsTypes.FETCH_MASSIVE_TESTS_UPDATE_STATUS_REQUEST:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState
          .set('isLoadingMassiveTestsUpdateStatus', true)
          .set('massiveTestsUpdateStatusError', false),
      );
    case MassiveTestsTypes.FETCH_MASSIVE_TESTS_UPDATE_STATUS_SUCCESS:
      return state.withMutations((prevState: MassiveTestsState) =>
        prevState
          .set('isLoadingMassiveTestsUpdateStatus', false)
          .set('massiveTestsUpdateStatusError', false)
          .set('statusUpdateRequest', action.payload.data),
      );
    case MassiveTestsTypes.FETCH_MASSIVE_TESTS_UPDATE_STATUS_FAILURE:
      return state.withMutations((prevState: MassiveTestsState) => {
        return prevState
          .set('isLoadingMassiveTestsUpdateStatus', false)
          .set('massiveTestsUpdateStatusError', true);
      });
    default:
      return state;
  }
};

export default reducer;
