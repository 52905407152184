import api from './api';

export const associateManagersToDashboardService = ({
  dashboardId,
  data,
}: {
  dashboardId: number;
  data: any;
}) =>
  api(`/admin_api/v1/metabase_dashboards/${dashboardId}/`, {
    method: 'patch',
    data,
  });

export const fetchManagersAssociatedWithDashboardService = ({
  dashboardId,
}: {
  dashboardId: number;
}) => api(`/admin_api/v1/metabase_dashboards/${dashboardId}/`);

export const fetchDashboardsAssociatedWithManagerService = ({
  managerId,
}: {
  managerId: number;
}) =>
  api(
    `/admin_api/v1/metabase_dashboards/get_user_dashboards/?user_id=${managerId}`,
  );

export const fetchAllDashboardsService = () =>
  api(`/admin_api/v1/metabase_dashboards/`);
