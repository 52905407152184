import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchCompositionGenresService} from 'store/services/compositionGenresService';
import {action} from 'typesafe-actions';

// Actions types
export enum CompositionGenresTypes {
  FETCH_REQUEST = '@compositionGenres/FETCH_REQUEST',
  FETCH_SUCCESS = '@compositionGenres/FETCH_SUCCESS',
  FETCH_FAILURE = '@compositionGenres/FETCH_FAILURE',
}

// State
export interface CompositionGenresState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.CompositionGenreNestedAssociation>>;
  readonly loading: boolean;
  readonly error: boolean;
}

// Actions
export const fetchCompositionGenresRequest = (params?: Utils.GetParams) =>
  action(CompositionGenresTypes.FETCH_REQUEST, params);

export const fetchCompositionGenresSuccess = (
  data: List<ImmutableMap<AdminApi.CompositionGenreNestedAssociation>>,
) =>
  action(CompositionGenresTypes.FETCH_SUCCESS, {
    data,
  });

export const fetchCompositionGenresFailure = () =>
  action(CompositionGenresTypes.FETCH_FAILURE);

// Sagas
export function* fetchCompositionGenres(action: AnyAction): any {
  try {
    const response = yield call(fetchCompositionGenresService, action.payload);
    yield put(fetchCompositionGenresSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionGenresFailure());
  }
}

// Initial state
export const INITIAL_STATE: CompositionGenresState = fromJS({
  data: fromJS([]),
  loading: false,
  error: false,
});

// Selectors
const compositionGenresDataSelector = (state: ApplicationState) =>
  state.getIn(['compositionGenres', 'data']);

export const getCompositionGenresOptions = createSelector(
  compositionGenresDataSelector,
  (compositionGenres) =>
    compositionGenres.map((compositionGenre: ImmutableMap<any>) => ({
      value: compositionGenre.get('id'),
      label: compositionGenre.get('name'),
    })),
);

// Reducer
const reducer: Reducer<CompositionGenresState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case CompositionGenresTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('error', false).set('loading', true),
      );

    case CompositionGenresTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data.results)),
      );

    case CompositionGenresTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([])),
      );

    default:
      return state;
  }
};

export default reducer;
