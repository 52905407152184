import {FetchTestsRequestInterface} from 'store/reducers/MassiveEditTests';
import api from './api';

export const fetchMassiveTestTemplatesService = (
  data: FetchTestsRequestInterface,
) =>
  api('/api/v1/tests/tests_grouped_by_school/', {
    method: 'post',
    data,
  });
