import api, {PUBLIC_API_TOKEN, PUBLIC_API_URL} from './api';

const publicApiParams = {
  api_token: PUBLIC_API_TOKEN,
  baseURL: PUBLIC_API_URL,
  withCredentials: false,
};

export const fetchMonitoringService = (requestId: string) =>
  api(`/monitoring/${requestId}`, {
    method: 'get',
    ...publicApiParams,
  });
