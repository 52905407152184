export const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

interface EncryptAndStoreOptions {
  name: string;
  expiresIn?: number;
  data: Record<string, unknown>;
}

function encryptAndStore({
  name,
  data,
  expiresIn = ONE_DAY_IN_MILLISECONDS,
}: EncryptAndStoreOptions): void {
  const expirationDate = Date.now() + expiresIn;
  const encrypted = window.btoa(
    JSON.stringify({...data, expiresAt: expirationDate}),
  );
  localStorage.setItem(name, encrypted);
}

function decryptAndRetrieve<RetrieveType>(
  name: string,
): RetrieveType | undefined {
  const encryptedData = localStorage.getItem(name);

  if (!encryptedData) {
    return undefined;
  }
  const decrypted = window.atob(encryptedData);
  const {expiresAt, ...data} = JSON.parse(decrypted);

  if (expiresAt < Date.now()) {
    localStorage.removeItem(name);
    return undefined;
  }

  return data as RetrieveType;
}

const encryptStorage = {
  encryptAndStore,
  decryptAndRetrieve,
  clear: (): void => localStorage.clear(),
};

export default encryptStorage;
