import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createContractContactService,
  deleteContractContactService,
  fetchContractContactsService,
  updateContractContactService,
} from 'store/services/contractContactsService';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';
import {ContractContact} from '../contracts';

// Actions types
export enum ContractContactsTypes {
  FETCH_REQUEST = '@contractsContacts/FETCH_REQUEST',
  FETCH_SUCCESS = '@contractsContacts/FETCH_SUCCESS',
  FETCH_FAILURE = '@contractsContacts/FETCH_FAILURE',

  UPDATE_REQUEST = '@contractsContacts/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@contractsContacts/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@contractsContacts/UPDATE_FAILURE',

  DELETE_REQUEST = '@contractsContacts/DELETE_REQUEST',
  DELETE_SUCCESS = '@contractsContacts/DELETE_SUCCESS',
  DELETE_FAILURE = '@contractsContacts/DELETE_FAILURE',

  CREATE_REQUEST = '@contractsContacts/CREATE_REQUEST',
  CREATE_SUCCESS = '@contractsContacts/CREATE_SUCCESS',
  CREATE_FAILURE = '@contractsContacts/CREATE_FAILURE',
}

export interface ContractContactsProps {
  readonly id: number;
  is_responsible: boolean;
  name: string;
  phone_number: string;
  email: string;
  clicksign_key: string;
  signed: string;
  contract: number;
}

export interface ContractContactRequestData {
  is_responsible: boolean;
  name: string;
  phone_number: string;
  email: string;
  contract: number;
}

// State type
export interface ContractContactsState extends Map<string, any> {
  readonly data: List<ImmutableMap<ContractContactsProps>>;
  readonly loading: boolean;
  readonly error: boolean;
  readonly dataCount: number;
  readonly contactRequestStatus: StoreStatus;
}

// Fetch actions
export const fetchContractContactsRequest = (params?: Utils.GetParams) =>
  action(ContractContactsTypes.FETCH_REQUEST);

export const fetchContractContactsSuccess = (data: List<ImmutableMap<any>>) =>
  action(ContractContactsTypes.FETCH_SUCCESS, {data});

export const fetchContractContactsFailure = () =>
  action(ContractContactsTypes.FETCH_FAILURE);

export const updateContractContactRequest = (data: ContractContact) =>
  action(ContractContactsTypes.UPDATE_REQUEST, {data});

export const updateContractContactSuccess = (
  data: List<ImmutableMap<ContractContactsProps>>,
) => action(ContractContactsTypes.UPDATE_SUCCESS, {data});

export const updateContractContactFailure = () =>
  action(ContractContactsTypes.UPDATE_FAILURE);

export const deleteContractContactRequest = (contactId: number) =>
  action(ContractContactsTypes.DELETE_REQUEST, contactId);

export const deleteContractContactSuccess = (
  data: List<ImmutableMap<ContractContactsProps>>,
) => action(ContractContactsTypes.DELETE_SUCCESS, {data});

export const deleteContractContactFailure = () =>
  action(ContractContactsTypes.DELETE_FAILURE);

export const createContractContactRequest = (data: ContractContact) =>
  action(ContractContactsTypes.CREATE_REQUEST, {data});

export const createContractContactSuccess = (
  data: List<ImmutableMap<ContractContactsProps>>,
) => action(ContractContactsTypes.CREATE_SUCCESS, {data});

export const createContractContactFailure = () =>
  action(ContractContactsTypes.CREATE_FAILURE);

// Sagas
export function* fetchContractContacts(): any {
  try {
    const response = yield call(fetchContractContactsService);
    yield put(fetchContractContactsSuccess(response.data));
  } catch (err) {
    yield put(fetchContractContactsFailure());
  }
}

export function* updateContractContact(action: AnyAction): any {
  try {
    const response = yield call(
      updateContractContactService,
      action.payload.data,
    );
    yield put(updateContractContactSuccess(response.data));
  } catch (err) {
    yield put(updateContractContactFailure());
  }
}

export function* deleteContractContact(action: AnyAction): any {
  try {
    const response = yield call(deleteContractContactService, action.payload);
    yield put(deleteContractContactSuccess(response.data));
  } catch (err) {
    yield put(deleteContractContactFailure());
  }
}

export function* createContractContact(action: AnyAction): any {
  try {
    const response = yield call(
      createContractContactService,
      action.payload.data,
    );
    yield put(createContractContactSuccess(response.data));
  } catch (err) {
    yield put(createContractContactFailure());
  }
}

// Selectors
export const contractContactsSelector = (state: ApplicationState) =>
  state.get('contractContacts');

export const contractContactsDataSelector = (state: ApplicationState) =>
  state.getIn(['contractContacts', 'data']);

export const getContractContacts = createSelector(
  contractContactsDataSelector,
  (contractContacts) => contractContacts,
);

export const isLoadingContacts = createSelector(
  contractContactsSelector,
  (contacts) => contacts.get('loading'),
);

export const getContactRequestStatus = createSelector(
  contractContactsSelector,
  (contacts) => contacts.get('contactRequestStatus'),
);

// Initial reducer state
export const INITIAL_STATE: ContractContactsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
  contactRequestStatus: {
    loading: false,
    fulfilled: false,
    error: false,
    posting: false,
  },
});

// Reducer
const reducer: Reducer<ContractContactsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ContractContactsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ContractContactsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case ContractContactsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    case ContractContactsTypes.DELETE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ContractContactsTypes.DELETE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case ContractContactsTypes.DELETE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ContractContactsTypes.CREATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('contactRequestStatus', {
          loading: false,
          fulfilled: false,
          error: false,
          posting: true,
        }),
      );

    case ContractContactsTypes.CREATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('contactRequestStatus', {
            loading: false,
            fulfilled: true,
            error: false,
            posting: false,
          }),
      );

    case ContractContactsTypes.CREATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('contactRequestStatus', {
            loading: false,
            fulfilled: false,
            error: true,
            posting: false,
          }),
      );

    case ContractContactsTypes.UPDATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('contactRequestStatus', {
          loading: false,
          fulfilled: false,
          error: false,
          posting: true,
        }),
      );

    case ContractContactsTypes.UPDATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('contactRequestStatus', {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        }),
      );

    case ContractContactsTypes.UPDATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('contactRequestStatus', {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        }),
      );

    default:
      return state;
  }
};

export default reducer;
