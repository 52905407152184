import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  fetchLearningPathsService,
  learningPathsCreationService,
} from 'store/services/learningPaths';
import {action} from 'typesafe-actions';

// Actions types
export enum LearningPathsTypes {
  FETCH_REQUEST = '@learningPaths/FETCH_REQUEST',
  FETCH_SUCCESS = '@learningPaths/FETCH_SUCCESS',
  FETCH_FAILURE = '@learningPaths/FETCH_FAILURE',

  CREATE_REQUEST = '@learningPaths/CREATE_REQUEST',
  CREATE_SUCCESS = '@learningPaths/CREATE_SUCCESS',
  CREATE_FAILURE = '@learningPaths/CREATE_FAILURE',
}

// State type
export interface LearningPathsState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.LearningPathTemplate>>;
  readonly dataCount: number;
  readonly loading: boolean;
  readonly error: boolean;
}

// Data types
export interface LearningPathDataObject {
  id: number;
  name: string;
}

export type FetchLearningPathsType = (params?: Utils.GetParams) => AnyAction;

// Fetch actions
export const fetchLearningPathsRequest: FetchLearningPathsType = (
  params?: Utils.GetParams,
) => action(LearningPathsTypes.FETCH_REQUEST, params);

export const fetchLearningPathsSuccess = (
  data: List<ImmutableMap<AdminApi.LearningPathTemplate>>,
) => action(LearningPathsTypes.FETCH_SUCCESS, {data});

export const fetchLearningPathsFailure = () =>
  action(LearningPathsTypes.FETCH_FAILURE);

// Create actions
export const createLearningPathRequest: any = (
  data: any,
  learningPathId: any,
) =>
  action(LearningPathsTypes.CREATE_REQUEST, {
    data,
    learningPathId,
  });

export const createLearningPathSuccess = (data: List<ImmutableMap<any>>) =>
  action(LearningPathsTypes.CREATE_SUCCESS, {data});

export const learningPathsCreationFailure = () =>
  action(LearningPathsTypes.CREATE_FAILURE);

// Sagas
export function* fetchLearningPaths(action: AnyAction): any {
  try {
    const response = yield call(fetchLearningPathsService, action.payload);
    yield put(fetchLearningPathsSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathsFailure());
  }
}

export function* learningPathsCreation(action: AnyAction): any {
  let response;
  try {
    const data: any = {
      data: {method: 'post', ...action.payload.data},
      learningPathId: action.payload.learningPathId,
    };

    response = yield call(learningPathsCreationService, data);

    yield put(createLearningPathSuccess(response.data));
  } catch (err) {
    yield put(learningPathsCreationFailure());
  }
}

// Selectors
const learningPathsDataSelector = (state: ApplicationState) =>
  state.getIn(['learningPaths', 'data']);

const learningPathsSelector = (state: ApplicationState) =>
  state.get('learningPaths');

export const getLearningPathsOptions = createSelector(
  learningPathsDataSelector,
  (learningPaths) => {
    if (!learningPaths) return fromJS([]);
    return learningPaths.map((learningPath: any) => ({
      value: learningPath.get('id'),
      label: `${learningPath.get('id')} - ${learningPath.get('internal_name')}`,
    }));
  },
);

export const getLearningPathsList = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths,
);

export const getLearningPathsListCount = createSelector(
  learningPathsSelector,
  (learningPaths) => learningPaths.get('dataCount'),
);

export const getIsLoadingLearningPaths = createSelector(
  learningPathsSelector,
  (learningPaths) => learningPaths.get('loading'),
);

export const getLearningPathsHasError = createSelector(
  learningPathsSelector,
  (learningPaths) => learningPaths.get('error'),
);

export const getLearningPathsTableData = createSelector(
  learningPathsDataSelector,
  (learningPaths) => {
    const learningPathsList = learningPaths.map(
      (learningPath: ImmutableMap<LearningPathDataObject>) =>
        fromJS({
          id: learningPath.get('id'),
          name: learningPath.get('name'),
        }),
    );

    return learningPathsList;
  },
);

// Initial state
export const INITIAL_STATE: LearningPathsState = fromJS({
  data: fromJS([]),
  dataCount: 0,
  loading: false,
  error: false,
});

// Reducer
const reducer: Reducer<LearningPathsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case LearningPathsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('error', false).set('loading', true),
      );

    case LearningPathsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case LearningPathsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    case LearningPathsTypes.CREATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('error', false).set('loading', true),
      );

    case LearningPathsTypes.CREATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );
    case LearningPathsTypes.CREATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
