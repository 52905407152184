import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import React from 'react';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchUsersService} from 'store/services/usersService';
import {action} from 'typesafe-actions';

// Actions types
export enum UsersTypes {
  FETCH_REQUEST = '@users/FETCH_REQUEST',
  FETCH_SUCCESS = '@users/FETCH_SUCCESS',
  FETCH_FAILURE = '@users/FETCH_FAILURE',
}

// State type
export interface UsersState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.UsersAdmin>>;
  readonly loading: boolean;
  readonly error: boolean;
  readonly dataCount: number;
}

export interface UsersData {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
}

export type FetchUsersType = (params?: Utils.GetParams) => AnyAction;

// Fetch actions
export const fetchUsersRequest = (params?: Utils.GetParams) =>
  action(UsersTypes.FETCH_REQUEST, params);

export const fetchUsersSuccess = (
  data: List<ImmutableMap<AdminApi.UsersAdmin>>,
) => action(UsersTypes.FETCH_SUCCESS, {data});

export const fetchUsersFailure = () => action(UsersTypes.FETCH_FAILURE);

// Sagas
export function* fetchUsers(action: AnyAction): any {
  try {
    const response = yield call(fetchUsersService, action.payload);
    yield put(fetchUsersSuccess(response.data));
  } catch (err) {
    yield put(fetchUsersFailure());
  }
}

/**
 * Selectors
 */

export const getUsers = (state: ApplicationState) => state.get('users');

export const getUsersData = createSelector(getUsers, (users) =>
  users.get('data'),
);

export const getUsersListDataTableSelector = createSelector(
  getUsersData,
  (users) =>
    users.size
      ? users.map((user: ImmutableMap<UsersData>) => {
          const userId = user.get('id');
          return {
            id:
              process.env.NODE_ENV === 'test' ? (
                userId
              ) : (
                <a
                  href={`${process.env.REACT_APP_ADMIN_API_URL}/compositions/user/${userId}/change`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userId}
                </a>
              ),
            username: user.get('username'),
            first_name: user.get('first_name'),
            last_name: user.get('last_name'),
            email: user.get('email'),
          };
        })
      : fromJS([]),
);

export const getUsersListDashboardTableSelector = createSelector(
  getUsersData,
  (users) =>
    users.size
      ? users.map((user: ImmutableMap<UsersData>) => {
          return {
            id: user.get('id'),
            username: user.get('username'),
            first_name: user.get('first_name'),
            last_name: user.get('last_name'),
            email: user.get('email'),
          };
        })
      : fromJS([]),
);

export const getUsersListCount = createSelector(getUsers, (users) =>
  users.get('dataCount'),
);
export const getIsLoadingUsersList = createSelector(getUsers, (users) =>
  users.get('loading'),
);

// Initial reducer
export const INITIAL_STATE: UsersState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
});

// Reducer
const reducer: Reducer<UsersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UsersTypes.FETCH_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case UsersTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case UsersTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    default:
      return state;
  }
};

export default reducer;
