import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {UserRole} from '../../../constants';
import styles from './SideBar.module.scss';

export interface Menu {
  label: string;
  icon: FontAwesomeIconProps;
  onClick?: () => void;
  active?: boolean;
}

export type Theme = 'dark' | 'light';

export type SideBarProps = {
  menu: Menu[];
  closed: boolean;
  theme?: Theme;
  userRole?: UserRole;
};

const SideBar: React.FC<SideBarProps> = ({
  menu,
  closed,
  theme = 'dark',
  userRole = 'anonymous',
}) => (
  <div className={`${styles.wrapper} ${styles[theme]} ${styles[userRole]} `}>
    <div
      data-testid="sidebarContainer"
      className={`${styles.container} ${closed ? styles.closedContainer : ''}`}
    >
      {menu?.map(({icon, label, onClick, active}, i: number) => (
        <div
          key={`menu-item-${label}`}
          onClick={onClick}
          onKeyPress={onClick}
          className={styles.item}
          tabIndex={i}
          role="link"
        >
          <div className={`${styles.icon} ${active ? styles.active : ''}`}>
            <FontAwesomeIcon
              {...{
                size: 'lg',
                ...icon,
              }}
            />
          </div>
          {!closed ? (
            <span className={`${styles.text} ${active ? styles.active : ''}`}>
              {label}
            </span>
          ) : null}
        </div>
      ))}
    </div>
  </div>
);

export default SideBar;
