import React from 'react';
import {Button, Row} from 'components';
import {Orientation} from 'utils/types';

import styles from './BasicModal.module.scss';

export type Size = 'tiny' | 'small' | 'medium' | 'large';

export interface BasicModalProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  closeButton?: JSX.Element;
  footerButtons?: JSX.Element;
  footerButtonsOrientation?: Orientation;
  clickOutsideToClose?: boolean;
  children?: React.ReactNode;
  size?: Size;
}

function BasicModal({
  open,
  onClose,
  title,
  closeButton,
  footerButtons,
  footerButtonsOrientation,
  clickOutsideToClose,
  children,
  size = 'small',
}: BasicModalProps): JSX.Element {
  return (
    <div
      data-testid="container"
      className={`${styles.container} ${open ? styles.open : ''}`}
    >
      {open && (
        <div className={`${styles.modal} ${styles[size]}`} role="dialog">
          <Row orientation="space-between">
            {title && (
              <h4 className={`${styles.title} ${styles[size]}`}>{title}</h4>
            )}

            {closeButton ? (
              <>{closeButton}</>
            ) : (
              <Button
                variant="tertiary"
                leftIcon={{icon: 'times', size: 'lg', color: '#FF4410'}}
                onClick={onClose}
              />
            )}
          </Row>
          <Row border="border-2">
            <div className={styles.children} data-testid="modal-children">
              {children}
            </div>
          </Row>

          {footerButtons ? (
            <Row orientation={footerButtonsOrientation}>{footerButtons}</Row>
          ) : (
            <Row orientation={footerButtonsOrientation || 'flex-end'}>
              <Button variant="secondary" onClick={onClose} text="Voltar" />
            </Row>
          )}
        </div>
      )}
      <div
        data-testid="background"
        className={styles.background}
        onMouseDown={clickOutsideToClose ? onClose : undefined}
        role="presentation"
      />
    </div>
  );
}

export default BasicModal;
