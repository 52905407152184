import api from 'store/services/api';

export const fetchCompositionsService = (params: any) =>
  api('/admin_api/v1/compositions', {params});

export const createCompositionService = (data: any) =>
  api('/admin_api/v1/compositions', {method: 'post', data});

export const updateCompositionsService = (data: any) =>
  api(`/admin_api/v1/compositions/${data.id}/`, {method: 'put', data});
