import {all, takeEvery, takeLatest} from 'redux-saga/effects';
import {
  createPublic,
  loginPublic,
  PublicApiTypes,
} from 'store/reducers/publicApi';
import {
  fetchQuestionnaires,
  QuestionnairesTypes,
} from 'store/reducers/questionnaires';
import {
  AuthenticationTypes,
  loginUser,
  logoutUser,
} from './reducers/authentication';
import {
  ClausesTypes,
  createContractClauses,
  deleteContractClauses,
  fetchContractClauses,
  updateContractClauses,
} from './reducers/clauses';
import {
  CompositionGenresTypes,
  fetchCompositionGenres,
} from './reducers/compositionGenres';
import {
  CompositionInstructionsTypes,
  fetchCompositionInstructions,
} from './reducers/compositionInstructions';
import {
  CompositionReviewsTypes,
  deleteCompositionReviews,
  fetchCompositionReviews,
} from './reducers/compositionReviews';
import {
  CompositionsTypes,
  createComposition,
  fetchCompositions,
  updateCompositions,
} from './reducers/compositions';
import {
  ContractContactsTypes,
  createContractContact,
  deleteContractContact,
  fetchContractContacts,
  updateContractContact,
} from './reducers/contractContacts';
import {
  ContractInstallmentsTypes,
  createContractInstallment,
  deleteContractInstallment,
  fetchContractInstallments,
  updateContractInstallment,
} from './reducers/contractInstallments';
import {
  ContractsTypes,
  createContract,
  fetchContractById,
  fetchContracts,
  generateDocument,
  updateContract,
} from './reducers/contracts';
import {FeatureFlagsTypes, fetchFeatureFlag} from './reducers/featureFlags';
import {
  deleteLearningPathInstance,
  fetchLearningPathInstanceList,
  LearningPathInstancesTypes,
} from './reducers/learningPathInstances';
import {
  fetchLearningPaths,
  learningPathsCreation,
  LearningPathsTypes,
} from './reducers/learningPaths';
import {
  associateManagersToDashboard,
  fetchAllDashboards,
  fetchDashboardsAssociatedWithManager,
  fetchManagersAssociatedWithDashboard,
  ManagerAccessTypes,
} from './reducers/managerAccess';
import {fetchMassiveTests, TestsEditTypes} from './reducers/MassiveEditTests';
import {
  createMassiveLearningPaths,
  fetchLearningPathsByFilter,
  fetchMassiveLearningPathsStatus,
  MassiveLearningPathsTypes,
  updateLearningPaths,
} from './reducers/MassiveLearningPaths';
import {
  fetchMassiveRegistrationProgress,
  fetchMassiveRegistrationQueueProgress,
  massiveRegistration,
  MassiveRegistrationTypes,
} from './reducers/massiveRegistration';
import {
  createMassiveTests,
  MassiveTestsTypes,
  updateMassiveTests,
} from './reducers/massiveTests';
import {
  fetchMassiveTestsCreateStatus,
  fetchMassiveTestsUpdateStatus,
} from './reducers/massiveTests/index';
import {
  createNotificationsGroups,
  fetchNotificationsGroups,
  NotificationsTypes,
  sendNotifications,
} from './reducers/Notifications';
import {fetchGrades, SchoolsGradesTypes} from './reducers/schoolGrades';
import {
  fetchGroups,
  SchoolsGroupsTypes,
  updateGroups,
} from './reducers/schoolGroups';
import {
  fetchSchoolNetworks,
  SchoolNetworksTypes,
} from './reducers/schoolNetworks';
import {
  createSchools,
  fetchSchools,
  SchoolsTypes,
  updateSchools,
} from './reducers/schools';
import {fetchTests, TestsTypes, updateTests} from './reducers/tests';
import {
  createTestTemplate,
  fetchTestTemplates,
  TestTemplatesTypes,
} from './reducers/testTemplates';
import {fetchUserRoles, UserRolesTypes} from './reducers/userRoles';
import {fetchUsers, UsersTypes} from './reducers/users';

export default function* rootSaga() {
  yield all([
    takeEvery(TestsTypes.UPDATE_REQUEST, updateTests),
    takeLatest(TestsTypes.FETCH_REQUEST, fetchTests),
    takeLatest(AuthenticationTypes.LOGIN_REQUEST, loginUser),
    takeLatest(AuthenticationTypes.LOGOUT_REQUEST, logoutUser),
    takeLatest(CompositionsTypes.UPDATE_REQUEST, updateCompositions),
    takeLatest(CompositionsTypes.CREATE_REQUEST, createComposition),
    takeLatest(CompositionsTypes.FETCH_REQUEST, fetchCompositions),
    takeLatest(CompositionReviewsTypes.FETCH_REQUEST, fetchCompositionReviews),
    takeLatest(
      CompositionReviewsTypes.DELETE_REQUEST,
      deleteCompositionReviews,
    ),
    takeLatest(
      ManagerAccessTypes.FETCH_ALL_DASHBOARDS_REQUEST,
      fetchAllDashboards,
    ),
    takeLatest(
      ManagerAccessTypes.FETCH_DASHBOARDS_ASSOCIATED_WITH_MANAGER_REQUEST,
      fetchDashboardsAssociatedWithManager,
    ),
    takeLatest(
      ManagerAccessTypes.ASSOCIATE_MANAGERS_TO_DASHBOARD_REQUEST,
      associateManagersToDashboard,
    ),
    takeLatest(
      ManagerAccessTypes.FETCH_MANAGERS_ASSOCIATED_WITH_DASHBOARD_REQUEST,
      fetchManagersAssociatedWithDashboard,
    ),
    takeLatest(LearningPathsTypes.FETCH_REQUEST, fetchLearningPaths),
    takeLatest(LearningPathsTypes.CREATE_REQUEST, learningPathsCreation),
    takeLatest(SchoolsGradesTypes.FETCH_REQUEST, fetchGrades),
    takeLatest(SchoolsGroupsTypes.FETCH_REQUEST, fetchGroups),
    takeLatest(SchoolsGroupsTypes.UPDATE_REQUEST, updateGroups),
    takeLatest(SchoolsTypes.FETCH_REQUEST, fetchSchools),
    takeLatest(SchoolsTypes.CREATE_REQUEST, createSchools),
    takeLatest(SchoolsTypes.UPDATE_REQUEST, updateSchools),
    takeLatest(TestTemplatesTypes.FETCH_REQUEST, fetchTestTemplates),
    takeEvery(TestTemplatesTypes.CREATE_REQUEST, createTestTemplate),
    takeLatest(
      MassiveTestsTypes.FETCH_TEST_TEMPLATES_REQUEST,
      fetchTestTemplates,
    ),
    takeEvery(
      MassiveTestsTypes.CREATE_MASSIVE_TESTS_REQUEST,
      createMassiveTests,
    ),
    takeEvery(
      MassiveTestsTypes.FETCH_MASSIVE_TESTS_CREATE_STATUS_REQUEST,
      fetchMassiveTestsCreateStatus,
    ),
    takeEvery(
      MassiveTestsTypes.FETCH_MASSIVE_TESTS_UPDATE_STATUS_REQUEST,
      fetchMassiveTestsUpdateStatus,
    ),
    takeEvery(
      MassiveLearningPathsTypes.FETCH_MASSIVE_LEARNING_PATHS_STATUS_REQUEST,
      fetchMassiveLearningPathsStatus,
    ),
    takeLatest(MassiveTestsTypes.UPDATE_TEST_REQUEST, updateMassiveTests),
    takeLatest(
      MassiveLearningPathsTypes.CREATE_LEARNING_PATH_REQUEST,
      createMassiveLearningPaths,
    ),
    takeLatest(
      MassiveLearningPathsTypes.FETCH_BY_FILTER_REQUEST,
      fetchLearningPathsByFilter,
    ),
    takeLatest(
      MassiveLearningPathsTypes.UPDATE_LEARNING_PATHS_REQUEST,
      updateLearningPaths,
    ),
    takeLatest(
      CompositionInstructionsTypes.FETCH_REQUEST,
      fetchCompositionInstructions,
    ),
    takeLatest(
      CompositionInstructionsTypes.FETCH_REQUEST,
      fetchCompositionInstructions,
    ),
    takeLatest(UsersTypes.FETCH_REQUEST, fetchUsers),
    takeLatest(ContractsTypes.FETCH_BY_ID_REQUEST as any, fetchContractById),
    takeLatest(UserRolesTypes.FETCH_REQUEST, fetchUserRoles),
    takeLatest(QuestionnairesTypes.FETCH_REQUEST, fetchQuestionnaires),
    takeLatest(ContractsTypes.FETCH_REQUEST, fetchContracts),
    takeLatest(ContractsTypes.UPDATE_REQUEST, updateContract),
    takeLatest(ClausesTypes.FETCH_REQUEST, fetchContractClauses),
    takeLatest(ClausesTypes.UPDATE_REQUEST, updateContractClauses),
    takeLatest(ClausesTypes.DELETE_REQUEST, deleteContractClauses),
    takeLatest(ClausesTypes.CREATE_REQUEST, createContractClauses),
    takeLatest(
      ContractInstallmentsTypes.FETCH_REQUEST,
      fetchContractInstallments,
    ),
    takeLatest(
      ContractInstallmentsTypes.UPDATE_REQUEST,
      updateContractInstallment,
    ),
    takeLatest(
      ContractInstallmentsTypes.DELETE_REQUEST,
      deleteContractInstallment,
    ),
    takeLatest(
      ContractInstallmentsTypes.CREATE_REQUEST,
      createContractInstallment,
    ),
    takeLatest(CompositionGenresTypes.FETCH_REQUEST, fetchCompositionGenres),
    takeLatest(ContractContactsTypes.FETCH_REQUEST, fetchContractContacts),
    takeLatest(ContractContactsTypes.UPDATE_REQUEST, updateContractContact),
    takeLatest(ContractContactsTypes.DELETE_REQUEST, deleteContractContact),
    takeLatest(ContractContactsTypes.CREATE_REQUEST, createContractContact),
    takeLatest(SchoolNetworksTypes.FETCH_REQUEST, fetchSchoolNetworks),
    takeLatest(ContractsTypes.CREATE_REQUEST, createContract),
    takeLatest(ContractsTypes.GENERATE_DOCUMENT_REQUEST, generateDocument),
    takeLatest(ClausesTypes.FETCH_REQUEST, fetchContractClauses),
    takeLatest(PublicApiTypes.CREATE_PUBLIC_REQUEST, createPublic),
    takeLatest(PublicApiTypes.LOGIN_PUBLIC_REQUEST, loginPublic),
    takeLatest(
      MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_PROGRESS_REQUEST,
      fetchMassiveRegistrationProgress,
    ),
    takeLatest(
      MassiveRegistrationTypes.FETCH_MASSIVE_REGISTRATION_QUEUE_PROGRESS_REQUEST,
      fetchMassiveRegistrationQueueProgress,
    ),
    takeLatest(
      MassiveRegistrationTypes.MASSIVE_REGISTRATION_REQUEST,
      massiveRegistration,
    ),
    takeLatest(
      MassiveRegistrationTypes.RESET_MASSIVE_REGISTRATION_REQUEST,
      massiveRegistration,
    ),
    takeLatest(FeatureFlagsTypes.FETCH_REQUEST, fetchFeatureFlag),
    takeLatest(TestsEditTypes.FETCH_TEST_REQUEST, fetchMassiveTests),
    takeLatest(
      NotificationsTypes.FETCH_NOTIFICATIONS_GROUP_REQUEST,
      fetchNotificationsGroups,
    ),
    takeLatest(
      NotificationsTypes.CREATE_NOTIFICATIONS_GROUP_REQUEST,
      createNotificationsGroups,
    ),
    takeLatest(
      NotificationsTypes.SEND_NOTIFICATIONS_REQUEST,
      sendNotifications,
    ),
    takeLatest(
      LearningPathInstancesTypes.FETCH_INSTANCE_LIST_REQUEST,
      fetchLearningPathInstanceList,
    ),
    takeLatest(
      LearningPathInstancesTypes.DELETE_INSTANCE_REQUEST,
      deleteLearningPathInstance,
    ),
  ]);
}
