import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './ScrollController.module.scss';

interface ScrollParams {
  position: number;
  translateY: number;
  hasOverflow: boolean;
  viewportWidth: number;
  totalOfPositions: number;
}

interface ScrollControllerProps {
  className?: string;
  children: JSX.Element;
}

/**
 * Component that controls the horizontal scroll of its children.
 * If the children does not overflow horizontally, the buttons are
 * automatically hidden.
 *
 * @param {React.ReactNode} props.children - The children of the ScrollController component.
 * @param {string} [props.className] - The class name for the ScrollController component.
 * @return {JSX.Element} The rendered ScrollController component.
 */
function ScrollController({
  children,
  className,
}: ScrollControllerProps): JSX.Element {
  const childRef = useRef<any>(null);
  const [scrollParams, setScrollParams] = useState<ScrollParams>({
    position: 0,
    translateY: 0,
    viewportWidth: 0,
    hasOverflow: false,
    totalOfPositions: 0,
  });

  const childRefMemo = useMemo(() => childRef, []);
  const childrenMemo = useMemo(() => children, []);

  useEffect(() => {
    const hasMountedRefElement = !!childRefMemo.current;

    /* istanbul ignore next */
    if (hasMountedRefElement) {
      const refElement = childRefMemo.current.firstChild;
      const {scrollWidth, offsetWidth, clientHeight} = refElement;

      refElement.scrollTo(0, 0);
      refElement.style.overflow = 'hidden';

      setScrollParams(prevState => ({
        viewportWidth: offsetWidth,
        translateY: -clientHeight / 2,
        position: prevState?.position,
        hasOverflow: scrollWidth > offsetWidth,
        totalOfPositions: Math.round(scrollWidth / offsetWidth) - 1,
      }));
    }
  }, [childRefMemo, childrenMemo]);

  function goToPosition(nextPosition: number) {
    const isWithinPositionRange =
      nextPosition >= 0 && nextPosition <= scrollParams.totalOfPositions;

    /* istanbul ignore next */
    if (isWithinPositionRange) {
      childRefMemo.current?.firstChild?.scrollTo({
        left: nextPosition * scrollParams.viewportWidth,
        behavior: 'smooth',
      });
    }

    setScrollParams(prevState => ({...prevState, position: nextPosition}));
  }

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <span ref={childRefMemo} className={styles.childWrapper}>
        {childrenMemo}
      </span>

      {scrollParams.hasOverflow && (
        <div
          className={styles.buttonsWrapper}
          style={{transform: `translateY(${scrollParams.translateY}px)`}}
        >
          <button
            type="button"
            className={styles.navigateButton}
            disabled={scrollParams.position === 0}
            onClick={() => goToPosition(scrollParams.position - 1)}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </button>

          <button
            type="button"
            className={styles.navigateButton}
            onClick={() => goToPosition(scrollParams.position + 1)}
            disabled={scrollParams.position === scrollParams.totalOfPositions}
          >
            <FontAwesomeIcon icon="chevron-right" />
          </button>
        </div>
      )}
    </div>
  );
}

export default ScrollController;
