import * as Sentry from '@sentry/react';
import letrusAdminAPI from 'APIs/letrusAdminAPI';
import PageLayout from 'components/PageLayout';
import React from 'react';
import {Route} from 'react-router';
import {Redirect, RouteProps} from 'react-router-dom';
import {PublicRoutes} from 'routes';
import {getCookie} from 'utils/cookies';
import encryptStorage from 'utils/functions/encryptStorage';
import forceLogout from 'utils/functions/forceLogout';
import {User} from 'utils/types/authentication';

function AuthRoute(routeProps: RouteProps): JSX.Element {
  const authedUser = encryptStorage.decryptAndRetrieve<User>('BKO-user');

  if (authedUser?.is_staff || authedUser?.is_superuser) {
    letrusAdminAPI.injectHeader({'X-CSRFToken': getCookie('csrftoken')});

    Sentry.setUser({
      id: authedUser.id,
      username: authedUser.username,
    });

    return (
      <PageLayout>
        <Route {...routeProps} />
      </PageLayout>
    );
  }

  forceLogout();
  return <Redirect to={PublicRoutes.login} />;
}

export default AuthRoute;
