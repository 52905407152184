import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  fetchLearningPathsByFilterService,
  massiveLearningPathsService,
  updateLearningPathsService,
} from 'store/services/massiveLearningPathsService';
import {fetchMonitoringService} from 'store/services/monitoring';
import {action} from 'typesafe-actions';
import {setCookie} from 'utils/cookies';
import {StoreStatus} from 'utils/types/store';

// Actions types
export enum MassiveLearningPathsTypes {
  CREATE_LEARNING_PATH_REQUEST = '@massiveLearningPaths/CREATE_LEARNING_PATH_REQUEST',
  CREATE_LEARNING_PATH_SUCCESS = '@massiveLearningPaths/CREATE_LEARNING_PATH_SUCCESS',
  CREATE_LEARNING_PATH_FAILURE = '@massiveLearningPaths/CREATE_LEARNING_PATH_FAILURE',

  FETCH_MASSIVE_LEARNING_PATHS_STATUS_REQUEST = '@massiveLearningPaths/FETCH_MASSIVE_REGISTRATION_STATUS_REQUEST',
  FETCH_MASSIVE_LEARNING_PATHS_STATUS_SUCCESS = '@massiveLearningPaths/FETCH_MASSIVE_REGISTRATION_STATUS_SUCCESS',
  FETCH_MASSIVE_LEARNING_PATHS_STATUS_FAILURE = '@massiveLearningPaths/FETCH_MASSIVE_REGISTRATION_STATUS_FAILURE',

  FETCH_BY_FILTER_REQUEST = '@massiveLearningPaths/FETCH_BY_FILTER_REQUEST',
  FETCH_BY_FILTER_SUCCESS = '@massiveLearningPaths/FETCH_BY_FILTER_SUCCESS',
  FETCH_BY_FILTER_FAILURE = '@massiveLearningPaths/FETCH_BY_FILTER_FAILURE',

  UPDATE_LEARNING_PATHS_REQUEST = '@massiveLearningPaths/UPDATE_LEARNING_PATHS_REQUEST',
  UPDATE_LEARNING_PATHS_SUCCESS = '@massiveLearningPaths/UPDATE_LEARNING_PATHS_SUCCESS',
  UPDATE_LEARNING_PATHS_FAILURE = '@massiveLearningPaths/UPDATE_LEARNING_PATHS_FAILURE',
}

// State
export interface MassiveLearningPathsState extends Map<string, any> {
  readonly data: MassiveLearningPathsInterface;
  readonly request_id: string;
  readonly statusRequest: RegistrationLearningPathsStatusRequestSuccess;
  readonly loading: boolean;
  readonly error: boolean;
  readonly createLearningPathRequestStatus: StoreStatus;
}

export interface UpdateLearningPathsRequestPayload {
  learning_path_instances: number[];
  test_type?: string;
  steps: {
    step_id: number;
    name?: string;
    theme_id?: number;
    start_datetime?: string;
    end_datetime?: string;
  }[];
}

// Actions
export interface LearningPathSteps {
  step_id: number;
  name: string;
  theme_id: number;
  start_datetime?: string;
  end_datetime?: string;
  teacher_revision_deadline?: string;
}

export interface MassiveLearningPathsInterface {
  school_group_ids?: (string | number)[];
  school_grade_ids?: string[];
  school_ids?: string[];
  network_ids?: string[];
  get_opinion: boolean;
  template_id: number;
  test_type?: string;
  tags?: string;
  is_required: boolean;
  uses_write_preparation: boolean;
  steps: LearningPathSteps[];
  start_datetime?: string;
  end_datetime?: string;
  teacher_revision_deadline?: string;
}

export interface RegistrationLearningPathsStatusRequestSuccess {
  service: string;
  output_data: {
    errors: string[];
  };
  input_data: MassiveLearningPathsInterface;
  line: number;
  request_id: string;
  status: string;
  ttl: number;
}

export interface ResponseMassiveLearningPathsInterface {
  message: string;
  request_id: string;
}

export const createMassiveLearningPathsRequest = (
  data: MassiveLearningPathsInterface,
) => action(MassiveLearningPathsTypes.CREATE_LEARNING_PATH_REQUEST, data);

export const createMassiveLearningPathsSuccess = (data: {
  message: string;
  request_id: string;
}) => action(MassiveLearningPathsTypes.CREATE_LEARNING_PATH_SUCCESS, {data});

export const createMassiveLearningPathsFailure = () =>
  action(MassiveLearningPathsTypes.CREATE_LEARNING_PATH_FAILURE);

export const fetchMassiveLearningPathsStatusRequest = (request_id: string) =>
  action(
    MassiveLearningPathsTypes.FETCH_MASSIVE_LEARNING_PATHS_STATUS_REQUEST,
    request_id,
  );

export const fetchMassiveLearningPathsStatusSuccess = (
  data: RegistrationLearningPathsStatusRequestSuccess,
) =>
  action(
    MassiveLearningPathsTypes.FETCH_MASSIVE_LEARNING_PATHS_STATUS_SUCCESS,
    {
      data,
    },
  );

export const fetchMassiveLearningPathsStatusFailure = () =>
  action(MassiveLearningPathsTypes.FETCH_MASSIVE_LEARNING_PATHS_STATUS_FAILURE);

export const fetchLearningPathsByFilterRequest = (params: {
  template_id: number;
  school_group_id__in: number[];
}) => action(MassiveLearningPathsTypes.FETCH_BY_FILTER_REQUEST, params);

export const fetchLearningPathsByFilterSuccess = (data: any) =>
  action(MassiveLearningPathsTypes.FETCH_BY_FILTER_SUCCESS, {data});

export const fetchLearningPathsByFilterFailure = () =>
  action(MassiveLearningPathsTypes.FETCH_BY_FILTER_FAILURE);

export const updateLearningPathsRequest = (
  data: UpdateLearningPathsRequestPayload,
) => action(MassiveLearningPathsTypes.UPDATE_LEARNING_PATHS_REQUEST, data);

export const updateLearningPathsSuccess = (data: any) =>
  action(MassiveLearningPathsTypes.UPDATE_LEARNING_PATHS_SUCCESS, data);

export const updateLearningPathsFailure = () =>
  action(MassiveLearningPathsTypes.UPDATE_LEARNING_PATHS_FAILURE);

// Sagas
export function* createMassiveLearningPaths(action: AnyAction): any {
  try {
    const response = yield call(massiveLearningPathsService, action.payload);
    yield call(setCookie, {
      name: 'massiveLearningPathsCreationRequestId',
      value: response.data.request_id,
      expires: 0.5,
    });
    yield put(createMassiveLearningPathsSuccess(response.data));
  } catch (err) {
    yield put(createMassiveLearningPathsFailure());
  }
}

export function* fetchMassiveLearningPathsStatus(action: AnyAction): any {
  try {
    const response = yield call(fetchMonitoringService, action.payload);
    yield put(fetchMassiveLearningPathsStatusSuccess(response.data));
  } catch (err) {
    yield put(fetchMassiveLearningPathsStatusFailure());
  }
}

export function* fetchLearningPathsByFilter(action: AnyAction): any {
  try {
    const response = yield call(
      fetchLearningPathsByFilterService,
      action.payload,
    );
    yield put(fetchLearningPathsByFilterSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathsByFilterFailure());
  }
}

export function* updateLearningPaths(action: AnyAction): any {
  try {
    const response = yield call(updateLearningPathsService, action.payload);
    yield put(updateLearningPathsSuccess(response.data));
  } catch (err) {
    yield put(updateLearningPathsFailure());
  }
}

// Initial state
export const INITIAL_STATE: MassiveLearningPathsState = fromJS({
  data: {},
  request_id: null,
  statusRequest: fromJS({}),
  isLoadingMassiveTestsRegistrationStatus: false,
  massiveTestsRegistrationStatusError: false,
  error: fromJS([]),
  loading: false,
  createLearningPathRequestStatus: {
    loading: false,
    error: false,
    posting: false,
    fulfilled: false,
  },
});

// Selectors
const massiveLearningPathsSelector = (state: ApplicationState) =>
  state.get('massiveLearningPaths');

export const getIsMassiveLearningPathsLoading = createSelector(
  massiveLearningPathsSelector,
  (massiveLearningPaths) => massiveLearningPaths.get('loading'),
);

export const getCreateLearningPathRequestStatus = createSelector(
  massiveLearningPathsSelector,
  (massiveLearningPaths) =>
    massiveLearningPaths.get('createLearningPathRequestStatus'),
);

export const getMassiveLearningPathsError = createSelector(
  massiveLearningPathsSelector,
  (massiveLearningPaths) => massiveLearningPaths.get('error'),
);

export const getMassiveLearningPathsData = createSelector(
  massiveLearningPathsSelector,
  (massiveLearningPaths) =>
    massiveLearningPaths.get('data')
      ? massiveLearningPaths.get('data').toJS()
      : null,
);

export const getMassiveLearningPathsRequestId = createSelector(
  massiveLearningPathsSelector,
  (state: MassiveLearningPathsState) => state.get('request_id'),
);

export const getMassiveLearningPathsStatus = createSelector(
  massiveLearningPathsSelector,
  (state: MassiveLearningPathsState) => state.get('statusRequest'),
);

// Reducer
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MassiveLearningPathsTypes.CREATE_LEARNING_PATH_REQUEST:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState
          .set('loading', true)
          .set('error', fromJS([]))
          .set('createLearningPathRequestStatus', {
            loading: false,
            error: false,
            posting: true,
            fulfilled: false,
          }),
      );

    case MassiveLearningPathsTypes.CREATE_LEARNING_PATH_SUCCESS:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState
          .set('loading', false)
          .set('error', fromJS([]))
          .set('request_id', action.payload.data.request_id)
          .set('createLearningPathRequestStatus', {
            loading: false,
            error: false,
            posting: false,
            fulfilled: true,
          }),
      );

    case MassiveLearningPathsTypes.CREATE_LEARNING_PATH_FAILURE:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState
          .set('loading', false)
          .set('error', fromJS([]))
          .set('data', [])
          .set('createLearningPathRequestStatus', {
            loading: false,
            error: true,
            posting: false,
            fulfilled: false,
          }),
      );
    case MassiveLearningPathsTypes.FETCH_MASSIVE_LEARNING_PATHS_STATUS_REQUEST:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState
          .set('isLoadingMassiveTestsRegistrationStatus', true)
          .set('massiveTestsRegistrationStatusError', false),
      );
    case MassiveLearningPathsTypes.FETCH_MASSIVE_LEARNING_PATHS_STATUS_SUCCESS:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState
          .set('isLoadingMassiveTestsRegistrationStatus', false)
          .set('massiveTestsRegistrationStatusError', false)
          .set('statusRequest', action.payload.data),
      );
    case MassiveLearningPathsTypes.FETCH_MASSIVE_LEARNING_PATHS_STATUS_FAILURE:
      return state.withMutations((prevState: MassiveLearningPathsState) => {
        return prevState
          .set('isLoadingMassiveTestsRegistrationStatus', false)
          .set('massiveTestsRegistrationStatusError', true);
      });
    case MassiveLearningPathsTypes.FETCH_BY_FILTER_REQUEST:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState.set('error', false).set('loading', true),
      );

    case MassiveLearningPathsTypes.FETCH_BY_FILTER_SUCCESS:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case MassiveLearningPathsTypes.FETCH_BY_FILTER_FAILURE:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    case MassiveLearningPathsTypes.UPDATE_LEARNING_PATHS_REQUEST:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState.set('loading', true).set('error', fromJS([])),
      );

    case MassiveLearningPathsTypes.UPDATE_LEARNING_PATHS_SUCCESS:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState
          .set('loading', false)
          .set('error', fromJS([]))
          .set('data', fromJS(action.payload.data)),
      );

    case MassiveLearningPathsTypes.UPDATE_LEARNING_PATHS_FAILURE:
      return state.withMutations((prevState: MassiveLearningPathsState) =>
        prevState
          .set('loading', false)
          .set('error', fromJS([]))
          .set('data', []),
      );

    default:
      return state;
  }
};

export default reducer;
