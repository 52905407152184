import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchQuestionnairesService} from 'store/services/questionnairesService';
import {action} from 'typesafe-actions';
import formatToLocalDate from 'utils/date/toLocalDate';

// Actions types
export enum QuestionnairesTypes {
  FETCH_REQUEST = '@questionnaires/FETCH_REQUEST',
  FETCH_SUCCESS = '@questionnaires/FETCH_SUCCESS',
  FETCH_FAILURE = '@questionnaires/FETCH_FAILURE',
}

// State type
export interface QuestionnairesState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.Questionnaires>>;
  readonly dataCount: number;
  readonly loading: boolean;
  readonly error: boolean;
}

// Data types
export type FetchQuestionnaires = (params?: Utils.GetParams) => AnyAction;

// Fetch actions
export const fetchQuestionnairesRequest: FetchQuestionnaires = (
  params?: Utils.GetParams,
) => action(QuestionnairesTypes.FETCH_REQUEST, {params});

export const fetchQuestionnairesSuccess = (
  data: List<ImmutableMap<AdminApi.QuestionnairesGetResponse>>,
) => action(QuestionnairesTypes.FETCH_SUCCESS, {data});

export const fetchQuestionnairesFailure = () =>
  action(QuestionnairesTypes.FETCH_FAILURE);

// Sagas
export function* fetchQuestionnaires(action: AnyAction): any {
  try {
    const response = yield call(fetchQuestionnairesService, action.payload);
    yield put(fetchQuestionnairesSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionnairesFailure());
  }
}

// Initial state
export const INITIAL_STATE: QuestionnairesState = fromJS({
  data: fromJS([]),
  dataCount: 0,
  loading: false,
  error: false,
});

// Selectors
const questionnairesSelector = (state: ApplicationState) =>
  state.get('questionnaires');

const questionnairesDataSelector = (state: ApplicationState) =>
  state.getIn(['questionnaires', 'data']);

export const getQuestionnairesTableData = createSelector(
  questionnairesDataSelector,
  (questionnaires) => {
    return questionnaires
      ? questionnaires.map(
          (questionnaire: ImmutableMap<AdminApi.Questionnaires>) =>
            fromJS({
              id: questionnaire.get('id'),
              name: questionnaire.get('name'),
              steps: questionnaire.get('steps'),
              created: formatToLocalDate(questionnaire.get('created')),
              modified: formatToLocalDate(questionnaire.get('modified')),
            }),
        )
      : fromJS([]);
  },
);

export const getQuestionnairesCount = createSelector(
  questionnairesSelector,
  (questionnaires) => questionnaires.get('dataCount'),
);

export const getIsQuestionnairesLoading = createSelector(
  questionnairesSelector,
  (questionnaires) => questionnaires.get('loading'),
);

// Reducer
const reducer: Reducer<QuestionnairesState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case QuestionnairesTypes.FETCH_REQUEST:
      return state.withMutations((prevState: QuestionnairesState) =>
        prevState.set('error', false).set('loading', true),
      );

    case QuestionnairesTypes.FETCH_SUCCESS:
      return state.withMutations((prevState: QuestionnairesState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case QuestionnairesTypes.FETCH_FAILURE:
      return state.withMutations((prevState: QuestionnairesState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    default:
      return state;
  }
};

export default reducer;
