import {Utils} from '@letrustech/letrus-api-interfaces';
import {UpdateMassiveTestRequestInterface} from 'store/reducers/massiveTests';
import api, {PUBLIC_API_TOKEN, PUBLIC_API_URL} from './api';
import {MassiveInterfaceData} from './massiveTestsInterface';

const publicApiParams = {
  api_token: PUBLIC_API_TOKEN,
  baseURL: PUBLIC_API_URL,
  withCredentials: false,
};

export const fetchTestTemplatesService = (params: {params: Utils.GetParams}) =>
  api('/admin_api/v1/test_templates/', params);

export const createMassiveTestsService = (data: MassiveInterfaceData) =>
  api(`/tests/massive_create`, {
    method: 'post',
    data,
    ...publicApiParams,
  });

export const patchMassiveTestsService = (
  data: UpdateMassiveTestRequestInterface,
) =>
  api(`/tests/massive_update`, {
    method: 'post',
    data,
    ...publicApiParams,
  });
