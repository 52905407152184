import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  deleteCompositionReviewsService,
  fetchCompositionReviewsService,
} from 'store/services/compositionReviewsService';
import {action} from 'typesafe-actions';
import toLocalDate from 'utils/date/toLocalDate';

// Actions types
export enum CompositionReviewsTypes {
  FETCH_REQUEST = '@compositionReviews/FETCH_REQUEST',
  FETCH_SUCCESS = '@compositionReviews/FETCH_SUCCESS',
  FETCH_FAILURE = '@compositionReviews/FETCH_FAILURE',

  DELETE_REQUEST = '@compositionReviews/DELETE_REQUEST',
  DELETE_SUCCESS = '@compositionReviews/DELETE_SUCCESS',
  DELETE_FAILURE = '@compositionReviews/DELETE_FAILURE',
}

// State type
export interface CompositionReviewsState extends Map<any, any> {
  readonly data: List<ImmutableMap<AdminApi.CompositionReviewsGetResponse>>;
  readonly dataCount: number;
  readonly loading: boolean;
  readonly error: boolean;
}

interface FetchCompositionReviewsParams extends Utils.GetParams {
  composition?: number;
}

export type FetchCompositionReviewsType = (
  params?: FetchCompositionReviewsParams,
) => any;

// Actions
// Fetch actions
export const fetchCompositionReviewsRequest: FetchCompositionReviewsType = (
  params,
) => action(CompositionReviewsTypes.FETCH_REQUEST, {params});

export const fetchCompositionReviewsSuccess = (
  data: List<AdminApi.CompositionReviewsGetResponse>,
) => action(CompositionReviewsTypes.FETCH_SUCCESS, {data});

export const fetchCompositionReviewsFailure = () =>
  action(CompositionReviewsTypes.FETCH_FAILURE);

// Delete actions
export const deleteCompositionReviewsRequest: FetchCompositionReviewsType = (
  params,
) => action(CompositionReviewsTypes.DELETE_REQUEST, {params});

export const deleteCompositionReviewsSuccess = (
  data: List<AdminApi.CompositionReviewsGetResponse>,
) => action(CompositionReviewsTypes.DELETE_SUCCESS, {data});

export const deleteCompositionReviewsFailure = () =>
  action(CompositionReviewsTypes.DELETE_FAILURE);

// Sagas
export function* fetchCompositionReviews(action: AnyAction): any {
  try {
    const response = yield call(fetchCompositionReviewsService, action.payload);
    yield put(fetchCompositionReviewsSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionReviewsFailure());
  }
}

export function* deleteCompositionReviews(action: AnyAction): any {
  try {
    const response = yield call(
      deleteCompositionReviewsService,
      action.payload,
    );
    yield put(deleteCompositionReviewsSuccess(response.data));
  } catch (err) {
    yield put(deleteCompositionReviewsFailure());
  }
}

// Initial state
export const INITIAL_STATE: CompositionReviewsState = fromJS({
  data: fromJS([]),
  dataCount: 0,
  loading: false,
  error: false,
});

// Selectors
const compositionReviewsSelector = (state: ApplicationState) =>
  state.get('compositionReviews');

const compositionReviewsDataSelector = (state: ApplicationState) =>
  state.getIn(['compositionReviews', 'data']);

export const getCompositionReviewsList = createSelector(
  compositionReviewsDataSelector,
  (compositionReviews) => {
    const compositionReviewsList = compositionReviews.map(
      (compositionReview: ImmutableMap<any>) =>
        fromJS({
          composition_review_id: compositionReview.get('id') ?? '-',
          composition_id: compositionReview.get('composition_id') ?? '-',
          reviewer_name: compositionReview.get('reviewer_user_username') ?? '-',
          student_name:
            compositionReview.get('composition_user_username') ?? '-',
          finished: compositionReview.get('flag_finished') ? 'SIM' : 'NÃO',
          reviewed: compositionReview.get('score') ? 'SIM' : 'NÃO',
          creation_date: compositionReview.get('created')
            ? toLocalDate(compositionReview.get('created'))
            : '-',
          modified: compositionReview.get('modified')
            ? toLocalDate(compositionReview.get('modified'))
            : '-',
          finish_date: compositionReview.get('flag_finished') ?? '-',
          composition_grade: compositionReview.get('score')
            ? Math.round(+compositionReview.get('score'))
            : '-',
        }),
    );

    return compositionReviewsList;
  },
);

export const getCompositionReviewsCount = createSelector(
  compositionReviewsSelector,
  (compositionReviews) => compositionReviews.get('dataCount'),
);
export const getCompositionReviewsLoading = createSelector(
  compositionReviewsSelector,
  (compositionReviews) => compositionReviews.get('loading'),
);

// Reducer
const reducer: Reducer<CompositionReviewsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case CompositionReviewsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('error', false).set('loading', true),
      );

    case CompositionReviewsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case CompositionReviewsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    case CompositionReviewsTypes.DELETE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('error', false).set('loading', true),
      );

    case CompositionReviewsTypes.DELETE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case CompositionReviewsTypes.DELETE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    default:
      return state;
  }
};

export default reducer;
