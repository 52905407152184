import {AdminApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import React from 'react';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  fetchTestsService,
  updateTestsService,
} from 'store/services/testsService';
import {action} from 'typesafe-actions';
import toLocalDate from 'utils/date/toLocalDate';

// Actions types
export enum TestsTypes {
  FETCH_REQUEST = '@tests/FETCH_REQUEST',
  FETCH_SUCCESS = '@tests/FETCH_SUCCESS',
  FETCH_FAILURE = '@tests/FETCH_FAILURE',

  UPDATE_REQUEST = '@tests/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@tests/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@tests/UPDATE_FAILURE',
}

// State
export interface TestsState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.TestsGetRequest>>;
  readonly dataCount: number;
  readonly loading: boolean;
  readonly error: boolean;
}

// Actions
export const fetchTestsRequest = (params?: AdminApi.TestsGetRequest) =>
  action(TestsTypes.FETCH_REQUEST, params);

export const fetchTestsSuccess = (
  data: List<ImmutableMap<AdminApi.TestsGetResponse>>,
) => action(TestsTypes.FETCH_SUCCESS, {data});

export const fetchTestsFailure = () => action(TestsTypes.FETCH_FAILURE);

interface UpdateTestRequestPropsType {
  id: number;
  data: Partial<AdminApi.Tests>;
}

export type UpdateTestRequestType = (
  data: UpdateTestRequestPropsType,
) => AnyAction;
export const updateTestsRequest = (data: UpdateTestRequestType): any =>
  action(TestsTypes.UPDATE_REQUEST, data);

export const updateTestsFailure = () => action(TestsTypes.UPDATE_FAILURE);

export const updateTestsSuccess = (data: List<ImmutableMap<AdminApi.Tests>>) =>
  action(TestsTypes.UPDATE_SUCCESS, {data});

// Sagas
export function* fetchTests(action: AnyAction): any {
  try {
    const response = yield call(fetchTestsService, action.payload);
    yield put(fetchTestsSuccess(response.data));
  } catch (err) {
    yield put(fetchTestsFailure());
  }
}
export function* updateTests(action: AnyAction): any {
  try {
    const response = yield call(updateTestsService, action.payload);
    yield put(updateTestsSuccess(response.data));
  } catch (err) {
    yield put(updateTestsFailure());
  }
}

// Initial state
export const INITIAL_STATE: TestsState = fromJS({
  data: fromJS([]),
  dataCount: 0,
  error: false,
  loading: false,
});

// Selectors
const testsDataSelector = (state: ApplicationState) =>
  state.getIn(['tests', 'data']);

const testsSelector = (state: ApplicationState) => state.get('tests');

export const getTestsList = createSelector(testsDataSelector, (tests) => {
  return tests
    ? tests.map((test: ImmutableMap<AdminApi.Tests>) => {
        const testId = test.get('id');
        return fromJS({
          id:
            process.env.NODE_ENV === 'test' ? (
              testId
            ) : (
              <a
                href={`${process.env.REACT_APP_ADMIN_API_URL}/compositions/test/${testId}/change`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {testId}
              </a>
            ),
          name: test.get('name') ?? '-',
          school_group: test.get('school_group') ?? '-',
          start_date: test.get('start_datetime')
            ? toLocalDate(test.get('start_datetime'), true)
            : '-',
          end_date: test.get('end_datetime')
            ? toLocalDate(test.get('end_datetime'), true)
            : '-',
        });
      })
    : fromJS([]);
});

export const getTests = createSelector(testsSelector, (tests) =>
  tests.get('data'),
);

export const getTestsListCount = createSelector(testsSelector, (tests) =>
  tests.get('dataCount'),
);

export const getIsTestsListLoading = createSelector(testsSelector, (tests) =>
  tests.get('loading'),
);

// Reducer
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TestsTypes.FETCH_REQUEST:
      return state.withMutations((prevState: TestsState) =>
        prevState.set('loading', true),
      );
    case TestsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState: TestsState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );
    case TestsTypes.FETCH_FAILURE:
      return state.withMutations((prevState: TestsState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );
    case TestsTypes.UPDATE_REQUEST:
      return state.withMutations((prevState: TestsState) =>
        prevState.set('loading', true),
      );
    case TestsTypes.UPDATE_SUCCESS:
      return state.withMutations((prevState: TestsState) =>
        prevState.set('loading', false).set('error', false),
      );
    case TestsTypes.UPDATE_FAILURE:
      return state.withMutations((prevState: TestsState) =>
        prevState.set('loading', false).set('error', true),
      );
    default:
      return state;
  }
};

export default reducer;
