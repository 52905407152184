import {AdminApi} from '@letrustech/letrus-api-interfaces';
import api from 'store/services/api';

export const fetchCompositionReviewsService = (params?: {
  params: AdminApi.CompositionReviewsGetRequest;
}) => api('/admin_api/v1/composition_reviews/', params);

export const deleteCompositionReviewsService = (data: any) =>
  api(`/admin_api/v1/composition_reviews/${data.id}/`, {
    method: 'delete',
  });
