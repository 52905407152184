import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {Compositions} from '@letrustech/letrus-api-interfaces/dist/admin_api/compositions';
import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createCompositionService,
  fetchCompositionsService,
  updateCompositionsService,
} from 'store/services/compositionsService';
import {action} from 'typesafe-actions';

// Actions types
export enum CompositionsTypes {
  FETCH_REQUEST = '@compositions/FETCH_REQUEST',
  FETCH_SUCCESS = '@compositions/FETCH_SUCCESS',
  FETCH_FAILURE = '@compositions/FETCH_FAILURE',

  CREATE_REQUEST = '@compositions/CREATE_REQUEST',
  CREATE_SUCCESS = '@compositions/CREATE_SUCCESS',
  CREATE_FAILURE = '@compositions/CREATE_FAILURE',

  UPDATE_REQUEST = '@compositions/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@compositions/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@compositions/UPDATE_FAILURE',
}

// Data types
export interface Composition {
  id: number;
  title: string;
}

// State type
export interface CompositionsState {
  readonly data?: Compositions[];
  readonly dataCount: number;
  readonly loading: boolean;
  readonly error: boolean;
}

export type FetchCompositionReviewsType = (params?: Utils.GetParams) => void;

// Fetch actions
export const fetchCompositionsRequest: FetchCompositionReviewsType = (
  params,
) => {
  return action(CompositionsTypes.FETCH_REQUEST, params);
};

export const fetchCompositionsSuccess = (
  data: AdminApi.CompositionsGetResponse[],
) => action(CompositionsTypes.FETCH_SUCCESS, {data});

export const fetchCompositionsFailure = () =>
  action(CompositionsTypes.FETCH_FAILURE);

// Create actions
export const createCompositionRequest = (
  composition: AdminApi.CompositionsPostRequest,
) => {
  return action(CompositionsTypes.CREATE_REQUEST, composition);
};

export const createCompositionSuccess = () =>
  action(CompositionsTypes.CREATE_SUCCESS, {});

export const createCompositionFailure = () =>
  action(CompositionsTypes.CREATE_FAILURE);

// Update actions
export const updateCompositionsRequest = (
  composition: AdminApi.CompositionsPostRequest,
) => {
  return action(CompositionsTypes.UPDATE_REQUEST, composition);
};

export const updateCompositionsSuccess = (data: Composition) =>
  action(CompositionsTypes.UPDATE_SUCCESS, data);

export const updateCompositionsFailure = () =>
  action(CompositionsTypes.UPDATE_FAILURE);

// Sagas
export function* fetchCompositions(action: AnyAction): any {
  try {
    const response = yield call(fetchCompositionsService, action.payload);
    yield put(fetchCompositionsSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionsFailure());
  }
}

export function* createComposition(action: AnyAction) {
  try {
    yield call(createCompositionService, action.payload);
    yield put(createCompositionSuccess());
  } catch (err) {
    yield put(createCompositionFailure());
  }
}

export function* updateCompositions(action: AnyAction): any {
  try {
    const response = yield call(updateCompositionsService, action.payload);
    yield put(updateCompositionsSuccess(response.data));
  } catch (err) {
    yield put(updateCompositionsFailure());
  }
}

// Selectors
export function schoolGradesSelector(state: ApplicationState) {
  return state.get('compositions') as CompositionsState;
}

export const getCompositionsList = createSelector(
  schoolGradesSelector,
  (composition) => composition.data,
);
export const getCompositionsCount = createSelector(
  schoolGradesSelector,
  (composition) => composition.dataCount,
);
export const getIsCompositionsLoading = createSelector(
  schoolGradesSelector,
  (composition) => composition.loading,
);

// Initial state
export const INITIAL_STATE: CompositionsState = fromJS({
  data: [],
  dataCount: 0,
  error: false,
  loading: false,
});

// Reducer
const reducer: Reducer<CompositionsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CompositionsTypes.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case CompositionsTypes.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        dataCount: action.payload.data.count,
        data: action.payload.data.results,
      };

    case CompositionsTypes.FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case CompositionsTypes.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case CompositionsTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case CompositionsTypes.CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case CompositionsTypes.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case CompositionsTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case CompositionsTypes.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default reducer;
