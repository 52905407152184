import ScreenLoader from 'components/ScreenLoader';
import React, {Suspense} from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import Router from 'routes';
import {IS_PROD_ENVIRONMENT} from 'utils/consts';
import './index.module.scss';
import store from './store';

window.addEventListener('load', () => {
  if (IS_PROD_ENVIRONMENT) {
    const {setupTrackers} = require('./setupTrackers');

    setupTrackers();
  }
});

render(
  <Provider store={store}>
    <Suspense fallback={<ScreenLoader />}>
      <Router />
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);
