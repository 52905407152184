import {Utils} from '@letrustech/letrus-api-interfaces';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createNotificationsGroupsService,
  fetchNotificationsGroupsService,
  NotificationsGroup,
  SendNotificationsParams,
  sendNotificationsService,
} from 'store/services/notificationsService';
import {action} from 'typesafe-actions';

// Actions types
export enum NotificationsTypes {
  CREATE_NOTIFICATIONS_GROUP_REQUEST = '@notifications/CREATE_NOTIFICATIONS_GROUP_REQUEST',
  CREATE_NOTIFICATIONS_GROUP_SUCCESS = '@notifications/CREATE_NOTIFICATIONS_GROUP_SUCCESS',
  CREATE_NOTIFICATIONS_GROUP_FAILURE = '@notifications/CREATE_NOTIFICATIONS_GROUP_FAILURE',

  FETCH_NOTIFICATIONS_GROUP_REQUEST = '@notifications/FETCH_NOTIFICATIONS_GROUP_REQUEST',
  FETCH_NOTIFICATIONS_GROUP_SUCCESS = '@notifications/FETCH_NOTIFICATIONS_GROUP_SUCCESS',
  FETCH_NOTIFICATIONS_GROUP_FAILURE = '@notifications/FETCH_NOTIFICATIONS_GROUP_FAILURE',

  SEND_NOTIFICATIONS_REQUEST = '@notifications/SEND_NOTIFICATIONS_REQUEST',
  SEND_NOTIFICATIONS_SUCCESS = '@notifications/SEND_NOTIFICATIONS_SUCCESS',
  SEND_NOTIFICATIONS_FAILURE = '@notifications/SEND_NOTIFICATIONS_FAILURE',
}

// State
type RequestStatus = 'success' | 'error' | 'loading' | 'idle';

export interface StoredNotification<T> {
  data: T;
  status: RequestStatus;
  errorMessage?: string;
}

export interface NotificationsState {
  readonly notificationsGroups: StoredNotification<NotificationsGroup[]>;
  readonly createNotificationsGroupRequest: StoredNotification<null>;
  readonly sendNotificationsGroupRequest: StoredNotification<null>;
}

// Actions
export const fetchNotificationsGroupsRequest = (params?: Utils.GetParams) => {
  return action(NotificationsTypes.FETCH_NOTIFICATIONS_GROUP_REQUEST, params);
};

export const fetchNotificationsGroupsSuccess = (data: any) => {
  return action(NotificationsTypes.FETCH_NOTIFICATIONS_GROUP_SUCCESS, data);
};

export const fetchNotificationsGroupsFailure = (data: any) => {
  return action(NotificationsTypes.FETCH_NOTIFICATIONS_GROUP_FAILURE, data);
};

export const createNotificationsGroupsRequest = (
  data: NotificationsGroup,
  params?: Utils.GetParams,
) => {
  return action(NotificationsTypes.CREATE_NOTIFICATIONS_GROUP_REQUEST, {
    data,
    params,
  });
};

export const createNotificationsGroupsSuccess = () => {
  return action(NotificationsTypes.CREATE_NOTIFICATIONS_GROUP_SUCCESS);
};

export const createNotificationsGroupsFailure = (data: any) => {
  return action(NotificationsTypes.CREATE_NOTIFICATIONS_GROUP_FAILURE, data);
};

export const sendNotificationsRequest = (
  data: SendNotificationsParams,
  params?: Utils.GetParams,
) => {
  return action(NotificationsTypes.SEND_NOTIFICATIONS_REQUEST, {data, params});
};

export const sendNotificationsSuccess = () => {
  return action(NotificationsTypes.SEND_NOTIFICATIONS_SUCCESS);
};

export const sendNotificationsFailure = (data: any) => {
  return action(NotificationsTypes.SEND_NOTIFICATIONS_FAILURE, data);
};

// Sagas
export function* fetchNotificationsGroups(action: AnyAction): any {
  try {
    const response = yield call(
      fetchNotificationsGroupsService,
      action.payload,
    );
    yield put(fetchNotificationsGroupsSuccess(response.data));
  } catch (err) {
    yield put(fetchNotificationsGroupsFailure(err));
  }
}

export function* createNotificationsGroups(action: AnyAction) {
  try {
    yield call(
      createNotificationsGroupsService,
      action.payload.data,
      action.payload.params,
    );
    yield put(createNotificationsGroupsSuccess());
  } catch (err) {
    yield put(createNotificationsGroupsFailure(err));
  }
}

export function* sendNotifications(action: AnyAction) {
  try {
    yield call(
      sendNotificationsService,
      action.payload.data,
      action.payload.params,
    );
    yield put(sendNotificationsSuccess());
  } catch (err) {
    yield put(sendNotificationsFailure(err));
  }
}

// Initial state
export const INITIAL_STATE: NotificationsState = {
  notificationsGroups: {
    data: [],
    status: 'idle',
    errorMessage: undefined,
  },
  createNotificationsGroupRequest: {
    data: null,
    status: 'idle',
    errorMessage: undefined,
  },
  sendNotificationsGroupRequest: {
    data: null,
    status: 'idle',
    errorMessage: undefined,
  },
};

// Selectors
const notificatonsSelector = (state: ApplicationState) =>
  state.get('notifications');

export const getNotificationsGroups = createSelector(
  notificatonsSelector,
  (notifications) => notifications.notificationsGroups,
);

export const getCreateNotificationsGroupsInfo = createSelector(
  notificatonsSelector,
  (notifications) => notifications.createNotificationsGroupRequest,
);

export const getSendNotificationsInfo = createSelector(
  notificatonsSelector,
  (notifications) => notifications.sendNotificationsGroupRequest,
);

// Reducer

const reducer: Reducer<NotificationsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case NotificationsTypes.FETCH_NOTIFICATIONS_GROUP_REQUEST:
      return {
        ...state,
        notificationsGroups: {
          ...state.notificationsGroups,
          status: 'loading',
        },
      };

    case NotificationsTypes.FETCH_NOTIFICATIONS_GROUP_SUCCESS:
      return {
        ...state,
        notificationsGroups: {
          ...state.notificationsGroups,
          data: action.payload.results,
          status: 'success',
        },
      };

    case NotificationsTypes.FETCH_NOTIFICATIONS_GROUP_FAILURE:
      return {
        ...state,
        notificationsGroups: {
          ...state.notificationsGroups,
          status: 'error',
          errorMessage: action.payload.message,
        },
      };

    case NotificationsTypes.CREATE_NOTIFICATIONS_GROUP_REQUEST:
      return {
        ...state,
        createNotificationsGroupRequest: {
          ...state.createNotificationsGroupRequest,
          status: 'loading',
        },
      };

    case NotificationsTypes.CREATE_NOTIFICATIONS_GROUP_SUCCESS:
      return {
        ...state,
        createNotificationsGroupRequest: {
          ...state.createNotificationsGroupRequest,
          status: 'success',
        },
      };

    case NotificationsTypes.CREATE_NOTIFICATIONS_GROUP_FAILURE:
      return {
        ...state,
        createNotificationsGroupRequest: {
          ...state.createNotificationsGroupRequest,
          status: 'error',
          errorMessage: action.payload.message,
        },
      };

    case NotificationsTypes.SEND_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        sendNotificationsGroupRequest: {
          ...state.sendNotificationsGroupRequest,
          status: 'loading',
        },
      };

    case NotificationsTypes.SEND_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        sendNotificationsGroupRequest: {
          ...state.sendNotificationsGroupRequest,
          status: 'success',
        },
      };

    case NotificationsTypes.SEND_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        sendNotificationsGroupRequest: {
          ...state.sendNotificationsGroupRequest,
          status: 'error',
          errorMessage: action.payload.message,
        },
      };
    default:
      return state;
  }
};

export default reducer;
