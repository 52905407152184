import {Utils} from '@letrustech/letrus-api-interfaces';
import api from './api';

export interface FetchContractsParams extends Utils.GetParams {
  school?: string;
}

export const fetchContractsService = (params?: FetchContractsParams) =>
  api('/admin_api/v1/contracts/', {params});

export const fetchContractByIdService = (id: any) =>
  api(`/admin_api/v1/contracts/${id}/`);

export const updateContractService = (data: any) =>
  api(`/admin_api/v1/contracts/${data.id}/`, {data, method: 'patch'});

export const createContractService = (data: any) =>
  api(`/admin_api/v1/contracts/`, {data, method: 'post'});

export const generateDocumentService = (id: number) =>
  api(`/admin_api/v1/contracts/${id}/generate_clicksign_document/`, {
    method: 'post',
  });
