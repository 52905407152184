import {fromJS, List} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchMassiveTestTemplatesService} from 'store/services/massiveTestsEditService';
import {action} from 'typesafe-actions';
import {DropdownTestsInterface} from 'utils/types/dropdownTestsInterface';

// Actions types
export enum TestsEditTypes {
  FETCH_TEST_REQUEST = '@testsEdit/FETCH_REQUEST',
  FETCH_TEST_SUCCESS = '@testsEdit/FETCH_SUCCESS',
  FETCH_TEST_FAILURE = '@testsEdit/FETCH_FAILURE',

  RESET_TESTS = '@testsEdit/RESET_TESTS',

  ADD_TESTS_IDS = '@testsEdit/ADD_TESTS_IDS',
  REMOVE_TESTS_IDS = '@testsEdit/REMOVE_TESTS_IDS',
  CLEAR_TESTS_IDS = '@testsEdit/CLEAR_TESTS_IDS',
  ADD_TESTS = '@testEdit/ADD_TESTS',
  REMOVE_TESTS = '@testsEdit/REMOVE_TESTS',
  CLEAR_TESTS = '@testsEdit/CLEAR_TESTS',
}

// State
export interface TestsEditData {
  test_name: string;
  end_datetime: string;
  start_datetime: string;
  schools_groups: number[];
  tests: List<ImmutableMap<DropdownTestsInterface>>;
}

export interface TestsToShowInterface {
  name: string;
  startDate: Date;
  endDate: Date;
}

export interface TestsEditState extends Map<string, any> {
  readonly data: ImmutableMap<TestsEditData>;
  readonly testIds: List<number>;
  readonly loading: boolean;
  readonly error: boolean;
  readonly selectedTests: TestsToShowInterface[];
}

// Actions
export interface FetchTestsRequestInterface {
  school_groups: number[];
  start_datetime: string | null;
  end_datetime: string | null;
  test_name?: string;
}

export const fetchMassiveTestsRequest = (data: FetchTestsRequestInterface) =>
  action(TestsEditTypes.FETCH_TEST_REQUEST, data);

export const fetchTestsSuccess = (data: TestsEditData) =>
  action(TestsEditTypes.FETCH_TEST_SUCCESS, {data});

export const fetchTestsFailure = () =>
  action(TestsEditTypes.FETCH_TEST_FAILURE);

export const resetTests = () => action(TestsEditTypes.RESET_TESTS);

export const addTestsIds = (testIds: number[]) =>
  action(TestsEditTypes.ADD_TESTS_IDS, {testIds});

export const removeTestsIds = (testIds: number[]) =>
  action(TestsEditTypes.REMOVE_TESTS_IDS, {testIds});

export const clearTestsIds = () => action(TestsEditTypes.CLEAR_TESTS_IDS);

export const addTest = (test: TestsToShowInterface) =>
  action(TestsEditTypes.ADD_TESTS, {test});

export const removeTest = (test: TestsToShowInterface) =>
  action(TestsEditTypes.REMOVE_TESTS, {test});

export const clearTest = () => action(TestsEditTypes.CLEAR_TESTS);

// Sagas
export function* fetchMassiveTests(action: AnyAction): any {
  try {
    const response = yield call(
      fetchMassiveTestTemplatesService,
      action.payload,
    );
    yield put(fetchTestsSuccess(response.data));
  } catch (err) {
    yield put(fetchTestsFailure());
  }
}

// Initial state
export const INITIAL_STATE: TestsEditState = fromJS({
  data: fromJS({}),
  testIds: fromJS([]),
  error: false,
  loading: false,
  selectedTests: fromJS([]),
});

// Selectors
const testsEditSelector = (state: ApplicationState) => state.get('testsEdit');

export const getIsTestsEditLoading = createSelector(
  testsEditSelector,
  (testsEdit) => testsEdit.get('loading'),
);

export const getTestsEditError = createSelector(testsEditSelector, (schools) =>
  schools.get('error'),
);

export const getTestsEditList = createSelector(
  testsEditSelector,
  (testsEdit) =>
    testsEdit.getIn(['data', 'tests'])?.toJS() as DropdownTestsInterface[],
);

export const getTestsEditIds = createSelector(testsEditSelector, (testsEdit) =>
  testsEdit.get('testIds'),
);

export const getSelectedTests = createSelector(testsEditSelector, (testsEdit) =>
  testsEdit.get('selectedTests'),
);

// Reducer
const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TestsEditTypes.FETCH_TEST_REQUEST:
      return state.withMutations((prevState: TestsEditState) =>
        prevState.set('loading', true).set('error', false),
      );
    case TestsEditTypes.FETCH_TEST_SUCCESS:
      return state.withMutations((prevState: TestsEditState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data)),
      );
    case TestsEditTypes.FETCH_TEST_FAILURE:
      return state.withMutations((prevState: TestsEditState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS({})),
      );
    case TestsEditTypes.RESET_TESTS:
      return state.withMutations((prevState: TestsEditState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS({})),
      );
    case TestsEditTypes.ADD_TESTS_IDS:
      return state.withMutations((prevState: TestsEditState) => {
        const prevTestIds = prevState.get('testIds');
        const newTestIds = [...prevTestIds, ...action.payload.testIds];
        return prevState.set('testIds', fromJS(newTestIds));
      });

    case TestsEditTypes.ADD_TESTS:
      return state.withMutations((prevState: TestsEditState) => {
        const prevTest = prevState.get('selectedTests');

        const isExistingTest = prevTest.find(
          (test: ImmutableMap<TestsToShowInterface>) => {
            return test.get('name') === action.payload.test.name;
          },
        );

        const newTests = isExistingTest
          ? prevTest
          : [...prevTest, action.payload.test];

        return prevState.set('selectedTests', fromJS(newTests));
      });

    case TestsEditTypes.REMOVE_TESTS_IDS:
      return state.withMutations((prevState: TestsEditState) => {
        const prevTestIds = prevState.get('testIds');
        const newTestIds = prevTestIds.filter(
          (testId: number) => !action.payload.testIds.includes(testId),
        );
        return prevState.set('testIds', fromJS(newTestIds));
      });

    case TestsEditTypes.REMOVE_TESTS:
      return state.withMutations((prevState: TestsEditState) => {
        const prevTest = prevState.get('selectedTests');
        const newTests = prevTest.filter(
          ({name}: TestsToShowInterface) => action.payload.test.name !== name,
        );
        return prevState.set('selectedTests', fromJS(newTests));
      });
    case TestsEditTypes.CLEAR_TESTS_IDS:
      return state.withMutations((prevState: TestsEditState) =>
        prevState.set('testIds', fromJS([])),
      );
    case TestsEditTypes.CLEAR_TESTS:
      return state.withMutations((prevState: TestsEditState) =>
        prevState.set('selectedTests', fromJS([])),
      );
    default:
      return state;
  }
};

export default reducer;
