import axios, {AxiosInstance} from 'axios';
import Cookies from 'js-cookie';
import Qs from 'qs';
import {Store} from 'redux';
import {logoutUserRequest} from 'store/reducers/authentication';
import encryptStorage from 'utils/functions/encryptStorage';
import {User} from 'utils/types/authentication';

export type Methods =
  | 'get'
  | 'put'
  | 'post'
  | 'head'
  | 'delete'
  | 'patch'
  | 'options';

interface FetchOptions {
  method?: Methods;
  baseURL?: string;
  data?: any;
  headers?: Record<string, string>;
  withCredentials?: boolean;
  params?: Record<string, any>;
  api_token?: string;
}

const UNAUTHORIZED_STATUS = [401, 403];
const API_URL = process.env.REACT_APP_API_URL;
export const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
export const PUBLIC_API_TOKEN = process.env.REACT_APP_PUBLIC_API_TOKEN;

interface CookiesOptions {
  name: string;
  value?: string;
  expires?: number;
}

export const getCookie = (name: string) => Cookies.get(name);
export const setCookie = ({name, value = '', expires}: CookiesOptions) =>
  Cookies.set(name, value, {expires});
export const deleteCookie = ({name}: CookiesOptions) => Cookies.remove(name);

const letrusFetch: AxiosInstance = axios.create({
  timeout: 360 * 1000,
  paramsSerializer: (params: any) =>
    Qs.stringify(params, {arrayFormat: 'repeat'}),
});

export const setupInterceptors = (store: Store): void => {
  const {dispatch} = store;

  letrusFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error?.response?.status ?? 0;

      if (UNAUTHORIZED_STATUS.includes(status)) {
        dispatch(logoutUserRequest());
        window.location.href = '/login';
      }
      return Promise.reject(error);
    },
  );
};

const defaultOptions: FetchOptions = {
  method: 'get',
  baseURL: API_URL,
  withCredentials: true,
};

const api = (
  url: string,
  {
    method = 'get',
    data,
    params,
    headers,
    baseURL = API_URL,
    withCredentials = true,
    api_token,
  } = defaultOptions,
) => {
  let payload = data;
  // TODO: this is temporary untill the public api token is fixed
  if (baseURL === PUBLIC_API_URL) {
    const authedUser = encryptStorage.decryptAndRetrieve<User>('BKO-user');

    payload = {
      ...data,
      user_id: authedUser?.id,
    };
  }

  return letrusFetch({
    withCredentials,
    baseURL,
    method,
    data: payload,
    url,
    params,
    headers: headers || {
      'Content-Type': 'application/json',
      ...(api_token
        ? {'X-Api-Key': api_token}
        : {'x-csrftoken': getCookie('csrftoken')}),
    },
  }).catch((err: Error) => {
    throw err;
  });
};

export default api;
