import {AdminApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchUserRolesService} from 'store/services/userRolesService';
import {action} from 'typesafe-actions';
import toLocalDate from 'utils/date/toLocalDate';

// Actions types
export enum UserRolesTypes {
  FETCH_REQUEST = '@userRoles/FETCH_REQUEST',
  FETCH_SUCCESS = '@userRoles/FETCH_SUCCESS',
  FETCH_FAILURE = '@userRoles/FETCH_FAILURE',
}

// State
export interface UserRolesState extends Map<string, any> {
  readonly data: List<ImmutableMap<AdminApi.UserRoleAdmin>>;
  readonly loading: boolean;
  readonly error: boolean;
  readonly dataCount: number;
}

export type FetchUserRolesType = (params?: Utils.GetParams) => AnyAction;

// Actions
export const fetchUserRolesRequest: FetchUserRolesType = (params) =>
  action(UserRolesTypes.FETCH_REQUEST, params);

export const fetchUserRolesSuccess = (
  data: List<ImmutableMap<AdminApi.UserRoleAdmin>>,
) =>
  action(UserRolesTypes.FETCH_SUCCESS, {
    data,
  });

export const fetchUserRolesFailure = () => action(UserRolesTypes.FETCH_FAILURE);

// Sagas
export function* fetchUserRoles(action: AnyAction): any {
  try {
    const response = yield call(fetchUserRolesService, action.payload);
    yield put(fetchUserRolesSuccess(response.data));
  } catch (err) {
    yield put(fetchUserRolesFailure());
  }
}

// Initial state
export const INITIAL_STATE: UserRolesState = fromJS({
  data: fromJS([]),
  loading: false,
  dataCount: 0,
  error: false,
});

// Selectors
const userRolesDataSelector = (state: ApplicationState) =>
  state.getIn(['userRoles', 'data']);
const userRolesSelector = (state: ApplicationState) => state.get('userRoles');

export const getUserRolesDataTable = createSelector(
  userRolesDataSelector,
  (userRoles) => {
    const userRolesList = userRoles
      ?.map((userRole: ImmutableMap<AdminApi.UserRoleAdmin>) =>
        fromJS({
          user: userRole.get('user'),
          plan_type: userRole.get('plan_type') ?? '-',
          role: userRole.get('role'),
          school: userRole.get('school') ?? '-',
          created: toLocalDate(userRole.get('created')),
        }),
      )
      .toJS();

    return userRolesList;
  },
);

export const getUserRolesCount = createSelector(userRolesSelector, (userRole) =>
  userRole.get('dataCount'),
);
export const getIsLoadingUserRoles = createSelector(
  userRolesSelector,
  (userRole) => userRole.get('loading'),
);

// Reducer
export const reducer: Reducer<UserRolesState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case UserRolesTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('error', false).set('loading', true),
      );

    case UserRolesTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data.results)),
      );

    case UserRolesTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    default:
      return state;
  }
};

export default reducer;
