/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {AdminApi} from '@letrustech/letrus-api-interfaces';
import {List} from 'immutable';

// TODO: this file will be romoved after the LearningPathUpdate refactor
export function generateSchoolGroupOptions(
  groups: List<ImmutableMap<AdminApi.SchoolGroupsAdmin>>,
) {
  return groups
    ?.map((group) => ({
      grade: group.get('school_grade'),
      label: `${group.get('long_name')} - ID ${group.get('id')}`,
      value: group.get('id'),
    }))
    .toJS();
}
