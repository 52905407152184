import {Utils} from '@letrustech/letrus-api-interfaces';
import {AxiosResponse} from 'axios';
import api, {PUBLIC_API_TOKEN, PUBLIC_API_URL} from 'store/services/api';
import {LearningPathInstance} from 'utils/types/learningPath';
import {MassiveResponse} from 'utils/types/massiveTypes';
import {PaginatedRequest} from 'utils/types/paginatedRequest';

const publicApiParams = {
  api_token: PUBLIC_API_TOKEN,
  baseURL: PUBLIC_API_URL,
  withCredentials: false,
};

interface LearningPathParams extends Utils.GetParams {
  search?: string;
}

export interface LearningPathInstanceFilters {
  id__in: string;
  school: string;
  school_group: string;
  offset: number;
  limit: number;
}

export const fetchLearningPathsService = (params?: LearningPathParams) =>
  api('/admin_api/v1/learning_path_templates/?limit=10000', {
    method: 'get',
    params,
  });

export const learningPathsCreationService = ({data, learningPathId}: any) =>
  api(
    `/admin_api/v1/learning_path_templates/${learningPathId}/create_instance/`,
    data,
  );

export const fetchLearningPathInstanceListService = (
  filters: LearningPathInstanceFilters,
): Promise<AxiosResponse<PaginatedRequest<LearningPathInstance[]>>> =>
  api(`/admin_api/v1/learning_path_instances/`, {
    params: filters,
  });

export const deleteLearningPathInstanceService = (
  ids: number[],
): Promise<AxiosResponse<MassiveResponse>> =>
  api(`/learning_paths/massive_delete`, {
    method: 'post',
    data: {ids},
    ...publicApiParams,
  });
