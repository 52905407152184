import {Utils} from '@letrustech/letrus-api-interfaces';
import api from './api';

export const fetchContractContactsService = (params?: Utils.GetParams) =>
  api('/admin_api/v1/contract_contacts/', {params});

export const updateContractContactService = (data: any) =>
  api(`/admin_api/v1/contract_contacts/${data.id}/`, {method: 'patch', data});

export const deleteContractContactService = (contactId: number) =>
  api(`/admin_api/v1/contract_contacts/${contactId}/`, {
    method: 'delete',
    data: {id: contactId},
  });

export const createContractContactService = (data: any) =>
  api(`/admin_api/v1/contract_contacts/`, {method: 'post', data});
