import {Utils} from '@letrustech/letrus-api-interfaces';
import api from './api';

export const fetchContractClausesService = (params?: Utils.GetParams) =>
  api('/admin_api/v1/contract_clauses/', {params});

export const updateContractClausesService = (data: any) =>
  api(`/admin_api/v1/contract_clauses/${data.id}/`, {method: 'patch', data});

export const deleteContractClausesService = (data: any) =>
  api(`/admin_api/v1/contract_clauses/${data.id}/`, {method: 'delete', data});

export const createContractClausesService = (data: any) =>
  api(`/admin_api/v1/contract_clauses/`, {method: 'post', data});
