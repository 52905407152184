import {AdminApi} from '@letrustech/letrus-api-interfaces';
import api from './api';

const currentYear = new Date().getFullYear();

export const fetchSchoolGroupsService = (params?: any) => {
  return api(`/admin_api/v1/school_groups/?school_year=${currentYear}`, {
    params: {
      ...params,
      school_ids: params?.school_ids?.join(','),
      network_ids: params?.network_ids?.join(','),
      school_group_ids: params?.school_group_ids?.join(','),
      school_grade_ids: params?.school_grade_ids?.join(','),
    },
  });
};

export const updateSchoolGroupsService = (data: AdminApi.SchoolGroupsAdmin) =>
  api(`/admin_api/v1/school_groups/${data.id}/`, {method: 'put', data});
