import {Utils} from '@letrustech/letrus-api-interfaces';
import api from './api';

export interface NotificationsGroup {
  id?: number;
  name: string;
  role_types: number[];
  schools: string[];
  networks: string[];
  school_grades: string[];
}

export interface Notification {
  notification_log_template: {
    channel: 'email' | 'web' | 'push' | 'sms';
    error?: string;
    title?: string;
    content: string;
    link?: string | null;
  };
  notification_sending_group_ids: string[];
}

export interface SendNotificationsParams {
  notification: Notification;
}

export const fetchNotificationsGroupsService = (params?: Utils.GetParams) =>
  api('/admin_api/v1/notification_sending_group/', {params});

export const createNotificationsGroupsService = (
  data: NotificationsGroup,
  params?: Utils.GetParams,
) =>
  api('/admin_api/v1/notification_sending_group/', {
    method: 'post',
    data,
    params,
  });

export const sendNotificationsService = (
  {notification}: SendNotificationsParams,
  params?: Utils.GetParams,
) =>
  api(
    `/admin_api/v1/notification_sending_group/massive_notification_logs_create/`,
    {
      method: 'post',
      data: notification,
      params,
    },
  );
