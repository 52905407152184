import {Utils} from '@letrustech/letrus-api-interfaces';
import api from './api';

export interface SchoolGroupsParams extends Utils.GetParams {
  id: number;
  data: any; // we had to add 'any' due to interface api version
}

export const fetchTestTemplatesService = (params: {params: Utils.GetParams}) =>
  api('/admin_api/v1/test_templates/', params);

export const createTestTemplatesService = ({data, id}: SchoolGroupsParams) =>
  api(`/admin_api/v1/test_templates/${id}/create_test/`, {
    method: 'post',
    data,
  });
